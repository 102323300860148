import { Injectable, EventEmitter } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { Observable } from 'rxjs';
import { IAccount } from 'src/app/account/interfaces/account';
import { IStatusChange } from '../interfaces/status-change';
import { IStatusOverview } from '../interfaces/status-overview';
import { OptionalURLParams } from 'src/app/lib/classes/url/optional-urlparams';
import { format } from 'date-fns';

/**
 * @deprecated Not needed anymore
 */
@Injectable({
  providedIn: 'root'
})
export class StatusService {

  public statusChanged$: EventEmitter<boolean>;

  constructor(private backend: BackendService,) {
    this.statusChanged$ = new EventEmitter();
  }

  public publishChange() {
    this.statusChanged$.emit();
  }

  public getStatusOverview(): Observable<IStatusOverview> {
    return this.backend.getAll("/api/v1/status/overview");
  }

  public deleteStatus(accountId: number , statusChangeId: number): Observable<IStatusChange> {
    return this.backend.sendDelete("/api/v1/status/" + statusChangeId + "?tenant=" + accountId);
  }

  public deleteAllUncompletedStatuses(account: IAccount) {
    return this.backend.sendDelete("/api/v1/status/delete/uncompleted?tenant=" + account.id);
  }

  public retryStatus(accountId: number , statusChangeId: number): Observable<IStatusChange> {
    return this.backend.sendPost("/api/v1/status/" + statusChangeId + "/retry?tenant=" + accountId, {});
  }

  public retryAllUncompletedStatuses(account: IAccount) {
    return this.backend.sendPost("/api/v1/status/account/" + account.id + "/retry?tenant=" + account.id, {});
  }

  public toggleCampaignStatus(accountId: number, campaignId: number): Observable<any> {
    return this.backend.sendPost("/api/v1/status/campaign/" + campaignId + "/toggle?tenant=" + accountId, {})
  }

  public toggleAdStatus(accountId: number, adId: number): Observable<any> {
    return this.backend.sendPost("/api/v1/status/ad/" + adId + "/toggle?tenant=" + accountId, {});
  }

  public toggleDemographicsStatus(demographics: string, accountId: number, demographicsId: number): Observable<any> {
    return this.backend.sendPost("/api/v1/status/" + demographics + "/" + demographicsId + "/toggle?tenant=" + accountId, {});
  }

  public getStatusChangesCsvDeprecated(accountId: number) {
    return this.backend.getRaw("/api/v1/status/copy/deprecated?tenant=" + accountId);
  }

  public getStatusChangesCsv(accountId: number) {
    return this.backend.getRaw("/api/v1/status/copy?tenant=" + accountId);
  }

  public postCsvStatusChangesCompleted(accountId: number) {
    return this.backend.sendPost("/api/v1/status/csv/clear?tenant=" + accountId, {})
  }

  public getStatusDaily(userFilterId: number, accountFilterId: number, dateFilter: Date): Observable<any> {
    const filter = new OptionalURLParams({
      "user": userFilterId?.toString() ?? "",
      "account": accountFilterId?.toString() ?? "",
      "date": format(dateFilter, "yyyy-MM-dd")
    });

    return this.backend.getAll("/api/v1/status/daily?" + filter.getParams());
  }

  public setMaxConv(campaignId: number, accountId: number){
    return this.backend.sendPost("/api/v1/status/campaign/" + campaignId + "/max_conversions?tenant=" + accountId, {});
  }
}
