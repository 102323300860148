import { Component, OnInit } from '@angular/core';
import { CardComponent } from "../../../lib/ui/card/card.component";
import { TitleComponent } from "../../../lib/ui/title/title.component";
import { InputComponent } from "../../../lib/ui/input/input.component";
import { InputDirective } from '../../../lib/ui/input/input.directive';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, UntypedFormArray, Validators } from '@angular/forms';
import { NgFor } from '@angular/common';
import { ButtonComponent } from "../../../lib/ui/button/button.component";
import { DaterangepickerComponent } from "../../../lib/ui/daterangepicker/daterangepicker.component";
import { DateRange, Ranges } from '../../../lib/ui/daterangepicker/ranges';
import { differenceInDays, formatDuration } from 'date-fns';
import { ITemplateRange, ITemplate } from '../../classes/template';
import { Templatev4Service } from '../../services/templatev4.service';
import { ValidationComponent } from 'src/app/lib/ui/validation/validation.component';

@Component({
  selector: 'ga-create-template-view',
  standalone: true,
  imports: [
    NgFor, 
    ReactiveFormsModule, 
    CardComponent, 
    TitleComponent,
    InputComponent, 
    InputDirective,
    ButtonComponent, 
    DaterangepickerComponent,
    ValidationComponent
  ],
  templateUrl: './create-template-view.component.html',
  styleUrl: './create-template-view.component.css'
})
export class CreateTemplateViewComponent implements OnInit {

  templateForm!: FormGroup
  dateRanges = Ranges.getRanges();
  standardDateRange = Ranges.getDefault();
  isCreatingTemplate = false;

  constructor(private fb: FormBuilder, private templatev4: Templatev4Service) {}

  ngOnInit(): void {
    this.templateForm = this.fb.group({
      name: ['', Validators.required],
      ranges: this.fb.array([
        this.fb.group({
          name: ['', Validators.required],
          from: [this.standardDateRange.from],
          to: [this.standardDateRange.to]
        })
      ])
    })
  }

  addRange() {
    this.ranges.push(this.fb.group({
      name: ['', Validators.required],
      from: [this.standardDateRange.from],
      to: [this.standardDateRange.to]
    }))
  }

  removeRange(index: number) {
    this.ranges.removeAt(index);
  }

  get ranges() {
    return this.templateForm.controls['ranges'] as FormArray
  }

  dates(range: DateRange, formIndex: any) {
    this.ranges.controls[formIndex].get('from')?.setValue(range.from);
    this.ranges.controls[formIndex].get('to')?.setValue(range.to);
  }

  get name() {
    return this.templateForm.get('name');
  }

  getRangeName(index: number) {
    let ranges = this.templateForm.controls["ranges"] as UntypedFormArray
    return ranges.at(index).get('name')
  }

  getRangeFrom(index: number) {
    let ranges = this.templateForm.controls["ranges"] as UntypedFormArray
    return ranges.at(index).get('from')
  }

  getRangeTo(index: number) {
    let ranges = this.templateForm.controls["ranges"] as UntypedFormArray
    return ranges.at(index).get('to')
  }
  
  createTemplate() {
    if (this.templateForm.invalid) {
      return this.templateForm.markAllAsTouched()
    }

    this.isCreatingTemplate = true;
    let templateData = this.templateForm.value;

    let newTemplate: ITemplate = {
      id: 0,
      name: templateData.name,
      ranges: []
    } 

    let index = 0;

    for (let range of templateData.ranges) {
      const dateRange = new DateRange(templateData.ranges[index].from, templateData.ranges[index].to);
      const isCustom = Ranges.isCustom(dateRange)
      const preset = Ranges.getRangesReversed()[dateRange.toString()]

      newTemplate.ranges.push({
        preset: preset ?? null,
        name: range.name,
        to_as_date: templateData.ranges[index].from,
        from_as_date: templateData.ranges[index].to,
        is_custom: isCustom,
        date_from: differenceInDays(templateData.ranges[index].from, new Date()) + " 00:00:00",
        date_to: differenceInDays(templateData.ranges[index].to, new Date()) + " 00:00:00",
      })

      index++
    }

    this.templatev4.createTemplate(newTemplate).subscribe((template: ITemplate) => {
      this.isCreatingTemplate = false;
    })
  }

}
