import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { ButtonComponent } from "../../../lib/ui/button/button.component";
import { InputDirective } from 'src/app/lib/ui/input/input.directive';
import { CommentType, IComment } from '../../classes/comment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Commentv4Service } from '../../services/commentv4.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'ga-comment-overview',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    ButtonComponent,
    InputDirective,
    DatePipe
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './comment-overview.component.html',
  styleUrl: './comment-overview.component.css'
})
export class CommentOverviewComponent implements OnInit {

  @Input() entityId!: number
  @Input() type!: CommentType

  text: string = ""
  comments: Array<IComment> = []

  constructor(private commentv4: Commentv4Service, private confirmation: ConfirmationService) { }

  /**
   * 
   */
  ngOnInit(): void {
    this.commentv4.load(this.type, this.entityId).subscribe((comments: Array<IComment>) => {
      this.comments = comments;
    })
  }

  /**
   * 
   */
  send() {
    this.commentv4.send(this.type, this.entityId, this.text).subscribe(() => {
      this.commentv4.load(this.type, this.entityId).subscribe((comments: Array<IComment>) => {
        this.comments = comments;
        this.text = '';
      })
    })
  }

  /**
   * 
   * @param commentId 
   */
  delete(commentId: number) {
    this.confirmation.confirm({
      header: 'Delete comment',
      icon: 'delete',
      key: 'delete',
      message: 'Do you really want to delete this comment?',
      accept: () => this.commentv4.delete(commentId).subscribe(() =>
        this.commentv4.load(this.type, this.entityId).subscribe((comments: Array<IComment>) => {
          this.comments = comments;
        })
      )
    })
  }

}
