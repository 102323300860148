import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'field',
  standalone: true
})
export class FieldPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (value === 'is_serving_ads' || value === 'creator__pk' || value === 'uploader__pk') {
      return ''
    }

    return value.replace("__pk", " ")
    .split('_')
    .map(item => item.toLowerCase())
    .map(item => item.substring(0, 1).toUpperCase() + item.substring(1, item.length))
    .join(' ');
  }

}
