<div class="w-full md:w-1/3 mx-auto">
    <mave-card [footer]="false">
        <div header>
            <mave-sub-title text="Create template"></mave-sub-title>
        </div>

        <ganal-template-save
            submitMessage="Create Template"
            (submit)="submitTemplate($event)">
        </ganal-template-save>

    </mave-card>
</div>
