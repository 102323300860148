import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Url from 'src/app/lib/classes/url';
import { IStatus, IStatusChangeResponse, IStatusHistory, IStatusPayload, IStatusPending } from '../interfaces/status-change';
import { format } from 'date-fns';
import { ISuccessResponse } from 'src/app/lib/classes/response/success';
import { IAutomationExecute } from '../interfaces/status';


@Injectable({
  providedIn: 'root'
})
export class Statusv4Service {

  constructor(private http: HttpClient) { }

  apply(payload: IStatusPayload): Observable<IStatusChangeResponse> {
    let url = new Url("/statuses/apply");

    return this.http.post<IStatusChangeResponse>(url.build(), payload);
  }

  /**
   * 
   * @param accountId 
   * @param type 
   * @returns 
   */
  getActiveHistory(accountId: number, type: number): Observable<Array<IStatusHistory>> {
    let url = new Url("/statuses/active_history/" + accountId + "/type/" + type);

    return this.http.get<Array<IStatusHistory>>(url.build());
  }

  /**
   * 
   * @param type 
   * @returns 
   */
  getAllActiveHistory(type: number): Observable<Array<IStatusHistory>> {
    let url = new Url("/statuses/active_history/type/" + type);

    return this.http.get<Array<IStatusHistory>>(url.build());
  }

  /**
   * 
   * @returns 
   */
  all(): Observable<IStatus[]> {
    let url = new Url("/statuses")

    return this.http.get<IStatus[]>(url.build());
  }

  /**
   * 
   * @param account 
   * @param campaign 
   * @returns 
   */
  history(account: number, campaign: number | undefined = undefined): Observable<IStatus[]> {
    let url = new Url("/statuses/history");

    url = url.query("account", account);

    if (campaign !== undefined) {
      url = url.query("campaign", campaign);
    }

    return this.http.get<IStatus[]>(url.build());
  }

  /**
   * 
   * @param statusChangeId 
   */
  delete(statusChangeId: number) {
    let url = new Url("/statuses/" + statusChangeId)

    return this.http.delete<void>(url.build());
  }

  /**
   * 
   * @param accountId 
   * @returns 
   */
  deleteAll(accountId: number) {
    let url = new Url("/statuses/" + accountId + "/delete_all")

    return this.http.delete<void>(url.build())
  }

  /**
   * 
   * @param date 
   * @returns 
   */
  daily(userId: number, accountId: number, date: Date): Observable<IStatus[]> {
    let url = new Url("/statuses/daily")
      .query("user", userId)
      .query("account", accountId)
      .query('completed_at', format(date, 'Y-MM-dd'))

    return this.http.get<IStatus[]>(url.build())
  }


  pending(): Observable<IStatusPending> {
    let url = new Url("/statuses/pending")

    return this.http.get<IStatusPending>(url.build());
  }

  retry(statusChangeId: number): Observable<void> {
    let url = new Url("/statuses/retry/" + statusChangeId);

    return this.http.post<void>(url.build(), {});
  }

  retryAllInAccount(accountId: number) {
    let url = new Url("/statuses/retry/account/" + accountId);

    return this.http.post<void>(url.build(), {});
  }

  copy(accountId: number): Observable<string> {
    let url = new Url("/statuses/" + accountId + "/csv")

    return this.http.get<string>(url.build(), {});
  }

  verify(accountId: number): Observable<void> {
    let url = new Url("/statuses/" + accountId + "/csv/verify")

    return this.http.get<void>(url.build(), {});
  }

}