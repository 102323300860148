<div>
    <!-- <mave-delete-modal #deleteModal title="Delete Account"
        [message]="'Do you really want to delete: ' + selectedAccount.name + '?'"
        (delete)="deleteAccount(selectedAccount)">
    </mave-delete-modal>
    <ganal-refresh-modal #refreshModal></ganal-refresh-modal> -->


    <mv-card [table]="true">
        <div header>
            <div class="flex justify-between items-center text-sm">
                <mv-title [size]="3">Current Accounts ({{ accounts.length }})</mv-title>

                <div class="flex -m-2">
                    <mv-button variant="primary" routerLink="/account/refresh" class="mr-3" label="Refresh Account(s)"></mv-button>
                    <mv-button variant="primary" routerLink="/account/create" class="mr-3" label="Add Account(s)"></mv-button>
                    <mv-button routerLink="/account/permissions" label="Change Permissions"
                        variant="secondary"></mv-button>
                </div>
            </div>
        </div>

        <div body>
            <p-table [value]="accounts" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th [width]="1"></th> -->
                        <th>Name</th>
                        <th>Mcc Id</th>
                        <th>Customer Id</th>
                        <th>Last status change</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-account>
                    <tr [routerLink]="['/campaign/account/' + account.id ]">
                        <!-- <td>
                            <div class="rounded-full h-2 w-2"
                                [class.bg-gray]="account.status !== 4 && account.status !== 2"
                                [class.bg-green]="account.status === 2" [class.bg-red]="account.status === 4"></div>
                        </td> -->
                        <td class="font-bold">{{ account.name }}</td>
                        <td>{{ account.mcc_id }}</td>
                        <td>{{ account.customer_id }}</td>
                        <td>{{ account.last_status_change | date: 'MMM d, y - HH:mm:ss':'+0100' }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5" class="mv-table-empty">
                            <i class="material-symbols-outlined mv-table-empty__icon">search_off</i>
                            <div class="mv-table-empty__label">No Results</div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </mv-card>
</div>