<mv-card>
    <div header>
        <div class="flex items-center">
            
            <label class="font-bold text-nowrap text-xs mr-2" for="comment">{{ type === 0 ? 'Account': 'Campaign' }} comments</label>

            <input mvInput [(ngModel)]="text" name="comment" placeholder="Send comment ..." class="w-full mr-2" type="text">

            <mv-button icon="send" (click)="send()"></mv-button>
        </div>        
    </div>
    <div body>
        <div class="ga-comment__overview">
            <div *ngIf="comments.length === 0" class="ga-comment__empty">No Comments</div>
            <div *ngFor="let comment of comments" class="ga-comment group">
                <div class="ga-comment__author-holder">
                    <div>{{ comment.created_at | date: 'd MMM - HH:mm'}}</div>
                    <div class="ga-comment__author">{{ comment.author.name }}</div>
                </div>
                <div class="ga-comment__holder">
                    
                    <div class="ga-comment__text">
                        {{ comment.text }}
                    </div>
                    <div class="ga-comment__delete-button invisible group-hover:visible">
                        <mv-button
                            icon="delete" 
                            variant="danger-transparent" 
                            (click)="delete(comment.id)">
                        </mv-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mv-card>