<ng-container>
    <div class="flex mb-4">

        <div class="w-full mr-4">
            <ga-comment-overview *ngIf="account" [entityId]="accountId" [type]="0"></ga-comment-overview>  
            <!-- <ganal-comment-overview [commentType]="'account'" [accountId]="accountId"></ganal-comment-overview> -->
        </div>

  
        <div class="w-full mr-4">
            <ga-comment-overview *ngIf="account" [entityId]="campaignId" [type]="1"></ga-comment-overview>  
        </div>

        <ga-change-history class="w-full" *ngIf="account" #changes [changeHistoryType]="'account'" [accountId]="accountId"
            [campaignId]="campaignId">
        </ga-change-history>

    </div>
</ng-container>


<ng-container *ngIf="isRangesLoaded && !isLoadingAccount">
    <ga-ad-totals #adTotals [campaignId]="campaignId" [account]="account" [ranges]="ranges">
        <ga-feature feature="new_templates">
            <ga-template-selector [ranges]="ranges" (change)="changeRanges($event)"></ga-template-selector>

            <div fallback>
                <ganal-rangeselect-multi [ranges]="ranges"
                    (rangesChange)="changeRangesLegacy($event)"></ganal-rangeselect-multi>
            </div>
        </ga-feature>
    </ga-ad-totals>
</ng-container>


<ng-container *ngIf="isRangesLoaded && !isLoadingAccount">
    <div class="mt-4">
        <ga-ad-table #adTable [isTv]="campaign.name.toUpperCase().includes('TV')" [campaignId]="campaignId" [account]="account" [ranges]="ranges" [data]="campaignData"
            (loaded)="setAdsLoaded($event)"></ga-ad-table>
    </div>

    <div class="mt-4">
        <ga-devices-table #devicesTable [campaignId]="campaignId" [account]="account" [ranges]="ranges" [data]="campaignData"
            (loaded)="setDevicesLoaded($event)"></ga-devices-table>
    </div>

    <div class="mt-4" *ngIf="isAdsLoaded && isDevicesLoaded && isAdGroupLoaded">
        <ga-ages-table #agesTable [campaignId]="campaignId" [adGroupId]="adGroupId" [account]="account" [ranges]="ranges"
            [data]="campaignData"></ga-ages-table>
    </div>

    <div class="mt-4" *ngIf="isAdsLoaded && isDevicesLoaded && isAdGroupLoaded">
        <ga-parentals-table #parentalsTable [campaignId]="campaignId" [adGroupId]="adGroupId" [account]="account" [ranges]="ranges"
            [data]="campaignData"></ga-parentals-table>
    </div>

    <div class="mt-4" *ngIf="isAdsLoaded && isDevicesLoaded && isAdGroupLoaded">
        <ga-genders-table #gendersTable [campaignId]="campaignId" [adGroupId]="adGroupId" [account]="account" [ranges]="ranges"
            [data]="campaignData"></ga-genders-table>
    </div>

    <div class="mt-4" *ngIf="isAdsLoaded && isDevicesLoaded && isAdGroupLoaded">
        <ga-incomes-table #incomesTable [campaignId]="campaignId" [adGroupId]="adGroupId" [account]="account" [ranges]="ranges"
            [data]="campaignData"></ga-incomes-table>
    </div>
</ng-container>

<div class="mt-10"></div>

<ga-ad-workbar *ngIf="account" [campaignId]="campaignId" [account]="account"></ga-ad-workbar>