import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Campaignv4Service } from 'src/app/campaign/services/campaignv4.service';
import { ICampaignMonday } from 'src/app/campaign/interfaces/campaign';
import { TooltipModule } from 'primeng/tooltip';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'ga-monday',
  standalone: true,
  imports: [
    TooltipModule,
    ToastModule
  ],
  templateUrl: './monday.component.html',
  styleUrl: './monday.component.css'
})
export class MondayComponent implements OnInit {
  @Input() campaignId!: number;
  @Input() currency!: string;


  constructor(
    private campaignv4: Campaignv4Service, 
    private clipboard: Clipboard,
    private toast: MessageService
  ) { }

  ngOnInit(): void {
      
  }

  getCurrency() {
    return this.currency === 'EUR' ? '€' : '$'
  }

  mondayToText(monday: ICampaignMonday): string {
    return "Spend Y: " + monday.cost_yesterday.toFixed(0) + this.getCurrency()  + "/" + monday.budget_yesterday.toFixed(0) + this.getCurrency() + "\n" +  
    "ROI Y: " + (monday.roi_yesterday * 100).toFixed(0) + "%\n" +
    "ROI L7: " + (monday.roi_last_seven_days * 100).toFixed(0) + "%\n" +
    "CTR Y: " + monday.ctr_yesterday.toFixed(2) + "%\n" +
    "CTR L7: " + monday.ctr_last_seven_days.toFixed(2) + "%\n" +
    "CPM Y: " + monday.cpm_yesterday.toFixed(2) + this.getCurrency()
  }

  copy() {
    this.campaignv4.monday(this.campaignId).subscribe((monday: ICampaignMonday) => {
      this.clipboard.copy(this.mondayToText(monday));
      this.toast.add({
        severity: 'info',
        summary: 'Copy',
        detail: 'Copied data for monday'
      })
    })
  }

}
