import { Component, Input, OnInit } from '@angular/core';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { Adv4Service } from '../../services/adv4.service';
import { DateRange } from 'src/app/lib/classes/daterange';
import { IAdTotals } from '../../classes/ad/ad';
import { CurrencyPipe, NgFor, NgIf, PercentPipe } from '@angular/common';
import { IAccount } from 'src/app/account/interfaces/account';
import { RangeLabelPipe } from '../../pipes/range-label.pipe';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';

@Component({
  selector: 'ga-ad-totals',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CardComponent,
    PercentPipe,
    CurrencyPipe,
    RangeLabelPipe,
    TitleComponent
  ],
  templateUrl: './ad-totals.component.html',
  styleUrl: './ad-totals.component.css'
})
export class AdTotalsComponent implements OnInit {
  @Input() campaignId!: number;
  @Input() account!: IAccount;
  @Input() ranges!: Array<DateRange>;

  rangeTotals: Map<String, IAdTotals> = new Map()
  adIds: Array<number> = [];

  isAdChangesLoaded = false;
  loadedCount = 0;

  total: ITotalsView = {};

  constructor(private adv4: Adv4Service) {}

  ngOnInit(): void {
    this.load(this.ranges)
  }

  load(ranges: Array<DateRange>) {
    for (let range of ranges) {
      this.adv4.getAdTotals(this.campaignId, range).subscribe((totals: any) => {
        this.total[`roi_range_${range}`] = (totals.total_revenue - totals.total_cost) / totals.total_cost;
        this.total[`cpm_range_${range}`] = (totals.total_cost / (totals.total_impressions / 1000));
        this.total[`ad_ctr_range_${range}`] = (totals.total_clicks / totals.total_impressions);
        this.total[`ltr_range_${range}`] = (totals.total_clicks_tracked / totals.total_views_tracked);
        this.total[`cvr_range_${range}`] = (totals.total_conversions_tracked / totals.total_clicks_tracked);
        this.total[`cost_range_${range}`] = totals.total_cost || 0;         
        this.total[`profit_range_${range}`] = totals.total_profit || 0;    
        this.total[`revenue_range_${range}`] = totals.total_revenue || 0;   
        this.total[`clicks_range_${range}`] = totals.total_clicks || 0;     
        this.total[`conversions_range_${range}`] = totals.total_conversions || 0;
        this.total[`conversions_tracked_range_${range}`] = totals.total_conversions_tracked || 0;
        this.total[`clicks_tracked_range_${range}`] = totals.total_clicks_tracked || 0;
        this.total[`views_tracked_range_${range}`] = totals.total_views_tracked || 0;
        this.total[`impressions_range_${range}`] = totals.total_impressions || 0;
      })
    }
  }

}

interface ITotalsView {
  [range: string]: any | null
}
