import { Component, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { Account, IAccount } from 'src/app/account/interfaces/account';
import { Accountv4Service } from 'src/app/account/services/accountv4.service';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { Syncv4Service } from '../../services/syncv4.service';
import { ISynchronizationPagination } from '../../interfaces/sync';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ChartModule } from 'primeng/chart';
import { GraphBuilder } from 'src/app/campaign/classes/graph/graph-builder';
import { FixedColorGraphPainter } from 'src/app/campaign/classes/graph/graph-painter';
import { SyncFailedActiveAccountValueParser, SyncFailedValueParser, SyncSuccessRatioParser, SyncSuccessValueParser } from 'src/app/campaign/classes/graph/graph-value-parser';
import { GraphOptions } from 'src/app/campaign/classes/graph/graph-options';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DateRange } from 'src/app/lib/classes/daterange';
import { startOfToday, startOfYesterday, subWeeks } from 'date-fns';
import { DaterangepickerComponent } from 'src/app/lib/ui/daterangepicker/daterangepicker.component';
import { SyncFailedValueFormatter } from 'src/app/campaign/classes/graph/formatters/igraph-formatter';

@Component({
  selector: 'ga-index-sync-view',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ReactiveFormsModule,
    CardComponent,
    TitleComponent,
    TableModule,
    DropdownModule,
    InputSwitchModule,
    DatePipe,
    ChartModule,
    DaterangepickerComponent
  ],
  templateUrl: './index-sync-view.component.html',
  styleUrl: './index-sync-view.component.css'
})
export class IndexSyncViewComponent implements OnInit {
  accounts!: IAccount[]
  sync!: ISynchronizationPagination

  range: DateRange = new DateRange(subWeeks(startOfToday(), 1), startOfToday())

  limit: number = 20
  offset = 0

  metrics: any;
  options: any;

  isLoadingGraph = true;
  isSyncsLoaded = false;

  filterForm: FormGroup = this.fb.group({
    account: ['', Validators.required],
    hasFailed: ['', Validators.required]
  })

  hoverBackgroundColor = GraphOptions.getHoverBackgroundColor();

  constructor(private fb: FormBuilder, private accountv4: Accountv4Service, private syncv4: Syncv4Service) {}

  ngOnInit(): void {
    this.fetch()
    this.getMetrics();

    this.accountv4.getAccounts().subscribe((accounts: IAccount[]) => {
      this.accounts = accounts;
    })

    this.filterForm.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => {
      this.fetch()
    })
  }

  getMetrics() {
    this.syncv4.metrics(this.range).subscribe((metrics: any) => {
      this.metrics = new GraphBuilder()
        .push(metrics, new FixedColorGraphPainter('#004000'), new SyncSuccessValueParser())
        // .push(metrics, new FixedColorGraphPainter('#ffba61'), new SyncFailedValueParser())
        .push(metrics, new FixedColorGraphPainter('#4C0013'), new SyncFailedActiveAccountValueParser())
        .push(metrics, new FixedColorGraphPainter('#333333'), new SyncSuccessRatioParser())
        .build(GraphBuilder.labelsFromGraphData(metrics));

        let syncsArray = metrics.map((item: any) => (item.y['succeeded']));
        let maxSyncs = Math.max(...syncsArray)

        this.options = GraphOptions.getSyncGraphOptions(true, Account.noop());
        Chart.register(ChartDataLabels);

        this.isLoadingGraph = false;
    })
  }

  /**
   * 
   */
  fetch() {
    let account = this.filterForm.get('account')?.value;
    let hasFailed = this.filterForm.get('hasFailed')?.value;

    this.syncv4.paginate(account, !hasFailed, this.range, this.limit, this.offset).subscribe((sync: ISynchronizationPagination) => {
      this.sync = sync;
      this.isSyncsLoaded = true;
    })
  }

  dateRangeChanged(range: DateRange) {
    this.range = range;
    this.getMetrics()
    this.fetch()
  }

  /**
   * 
   * @param event 
   */
  paginate(event: TableLazyLoadEvent) {
    this.offset = event.first ?? 0;
    this.fetch()
  }

}
