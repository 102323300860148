import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reportStatus',
  standalone: true
})
export class ReportStatusPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    return {
      0: 'Pending',
      1: 'Generating',
      2: 'Ready',
      3: 'Failed'
    }[value] ?? 'Invalid';
  }

}
