import { Component } from '@angular/core';
import { CardComponent } from "../../../lib/ui/card/card.component";
import { TableModule } from 'primeng/table';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { IUser } from 'src/app/auth/classes/user';
import { NgIf } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonComponent } from "../../../lib/ui/button/button.component";
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { TitleComponent } from "../../../lib/ui/title/title.component";

@Component({
  selector: 'ga-index-permissions-view',
  standalone: true,
  imports: [NgIf, FormsModule, TooltipModule, CardComponent, InputSwitchModule, TableModule, ButtonComponent, TitleComponent],
  templateUrl: './index-permissions-view.component.html',
  styleUrl: './index-permissions-view.component.css'
})
export class IndexPermissionsViewComponent {

  users!: IUser[]

  constructor(private userv4: Userv4Service) {}

  ngOnInit(): void {
    this.userv4.all().subscribe((users: IUser[]) => {
      this.users = users;
    })
  }

  toggle(userId: number) {
    this.userv4.toggle(userId).subscribe(() => {

    })
  }

  reports(userId: number) {
    this.userv4.reports(userId).subscribe(() => {
      this.userv4.preloadUsers();
      this.userv4.all().subscribe((users: IUser[]) => {
        this.users = users;
      })
    })
  }

}
