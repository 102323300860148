<div class="ga-permissions__page">
    <mv-card [table]="true">
        <div header>
            <mv-title [size]="3">Manage Permissions</mv-title>
        </div>
        <div body>
            <p-table [value]="users">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Permissions</th>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td class="w-28">
                            <div class="flex items-center">
                                <div class="flex item-center mr-2">
                                    <p-inputSwitch styleClass="-my-2" [ngModel]="user.is_active" (ngModelChange)="toggle(user.id)"></p-inputSwitch>
                                </div>
                                <div pTooltip="Receives Reports" tooltipPosition="top" *ngIf="user.optin_reports" class="flex items-center mr-2">
                                    <i class="material-symbols-outlined text-primary-light text-md">summarize</i>
                                </div>
                                <div pTooltip="Superuser (Dev only)" tooltipPosition="top" *ngIf="user.is_superuser" class="flex items-center">
                                    <i class="material-symbols-outlined text-red text-md">construction</i>
                                </div>
                            </div>
                        </td>
                        <td>{{ user.name }}</td>
                        <td class="w-12">
                            <div class="flex">
                                <mv-button (click)="reports(user.id)" icon="summarize" variant="primary-transparent" pTooltip="Turn On/Off reports"></mv-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </mv-card>
</div>