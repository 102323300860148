import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Dialog, DialogModule } from 'primeng/dialog';
import { IAccount } from 'src/app/account/interfaces/account';
import { InputComponent } from 'src/app/lib/ui/input/input.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { ICampaign, ICampaignDetail } from '../../interfaces/campaign';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { CurrencyPipe, NgIf } from '@angular/common';
import { IStatusChangeType } from 'src/app/status/interfaces/status-change';
import { ConfirmationService } from 'primeng/api';
import { ValidationComponent } from 'src/app/lib/ui/validation/validation.component';
import { CvalidationComponent } from 'src/app/lib/ui/cvalidation/cvalidation.component';

@Component({
  selector: 'ga-budget-dialog',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    DialogModule,
    InputComponent,
    TitleComponent,
    ButtonComponent,
    CurrencyPipe,
    ReactiveFormsModule,
    CvalidationComponent
  ],
  templateUrl: './budget-dialog.component.html',
  styleUrl: './budget-dialog.component.css'
})
export class BudgetDialogComponent implements AfterViewInit {
  @ViewChild('dialog') dialog!: Dialog;

  @Input() account!: IAccount;
  @Input() campaign!: ICampaignDetail;
  @Input() open!: boolean;

  @Output() openChange = new EventEmitter<boolean>();
  @Output() campaignChange = new EventEmitter<ICampaignDetail>();
  @Output() submitted = new EventEmitter<void>();

  budgetForm: FormGroup = this.fb.group({
    'budget': [this.budget, Validators.required]
  })

  constructor(
    private statusv4: Statusv4Service, 
    private confirmation: ConfirmationService,
    private fb: FormBuilder
  ) {}

  ngAfterViewInit(): void {
    this.dialog.visibleChange.subscribe(() => {
      this.openChange.emit(false);
    })
  }

  setBudget(budget: number) {
    this.budgetForm.get('budget')?.setValue(budget);
  }

  get budget() {
    return this.budgetForm?.get("budget");
  }

  close(event: Event) {
    this.dialog.close(event);
  }

  toggle(isOpen: boolean) {
    this.openChange.emit(isOpen);
  }

  isDrasticChange(value: number, oldValue: number) {
    return (value > (oldValue + (oldValue + 1)) || value < (oldValue - (oldValue * 0.50)))
  }

  apply() {
    this.statusv4.apply({
      id: this.campaign.id,
      account_id: this.account.id,
      campaign_id: this.campaign.id,
      ad_group_id: 0,
      type: IStatusChangeType.Budget,
      value: this.budget?.value.toString(),
    }).subscribe(() => {
      this.openChange.emit(false);
      this.submitted.emit();
    })
  }


  formatBudget(value: number, account: IAccount) {
    return new CurrencyPipe('en_US', 'USD').transform(value, account.currency)
  }

  changeBudget() {
    if (!this.budgetForm.valid) {
      return
    }

    if (this.isDrasticChange(this.budget?.value, this.campaign.budget)) {
      this.confirmation.confirm({
        header: 'Are you sure you want to make such a drastic budget change?',
        message: this.formatBudget(this.campaign.budget, this.account) + "|" + this.formatBudget(this.budget?.value, this.account),
        icon: 'price_change',
        key: 'budget',
        accept: () => this.apply()
      })
    } else {
      this.apply()
    }
  }

}
