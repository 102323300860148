import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'mv-cvalidation',
  standalone: true,
  imports: [NgIf],
  templateUrl: './cvalidation.component.html',
  styleUrl: './cvalidation.component.css'
})
export class CvalidationComponent {
  @Input() condition: boolean = false;
  @Input() label: string = '';
}
