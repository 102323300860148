import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Url from 'src/app/lib/classes/url';
import { IReport, IReportCampaign, IReportPagination } from '../classes/report';
import { ITotals } from 'src/app/lib/interfaces/statistics';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class Reportsv4Service {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @param name 
   * @param userId 
   * @param type 
   * @param limit 
   * @param offset 
   * @returns 
   */
  paginate(name: string, userId: number, type: number, limit: number = 10, offset: number = 0): Observable<IReportPagination> {
    let url = new Url("/reports")
      .query('limit', limit)
      .query('offset', offset)
      .query('name', name)
      .query('user', userId)
      .query('type', type)

    return this.http.get<IReportPagination>(url.build())
  }

  /**
   * 
   * @param report 
   * @returns 
   */
  save(report: IReport) {
    let url = new Url("/reports/create")
    return this.http.post(url.build(), report)
  }

  /**
   * 
   * @param reportId 
   * @returns 
   */
  show(reportId: number): Observable<IReport> {
    let url = new Url("/reports/" + reportId)

    return this.http.get<IReport>(url.build())
  }

  /**
   * 
   * @param reportId 
   * @returns 
   */
  showReportCampaigns(reportId: number): Observable<IReportCampaign[]> {
    let url = new Url("/reports/" + reportId + "/show/campaigns")

    return this.http.get<IReportCampaign[]>(url.build());
  }

  /**
   * 
   * @param reportId 
   * @returns 
   */
  totals(reportId: number): Observable<ITotals> {
    let url = new Url("/reports/" + reportId + "/show/totals")

    return this.http.get<ITotals>(url.build());
  }

  /**
   * 
   * @param date 
   * @returns 
   */
  daily(userId: number, date: Date): Observable<IReport[]> {
    let url = new Url("/reports/daily")
      .query("user", userId)
      .query("date", format(date, 'yyyy-MM-dd'));

    return this.http.get<IReport[]>(url.build());
  }

  /**
   * 
   * @param reportId 
   * @returns 
   */
  delete(reportId: number): Observable<void> {
    let url = new Url("/reports/" + reportId)
    return this.http.delete<void>(url.build())
  }

  /**
   * 
   * @param reportId 
   * @returns 
   */
  duplicate(reportId: number): Observable<void> {
    let url = new Url("/reports/" + reportId + "/duplicate")
    
    return this.http.post<void>(url.build(), null)
  }

}
