import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Authv4Service } from '../../../auth/services/authv4.service';
import { UserService } from '../../services/user.service';
import { PolledService } from 'src/app/lib/services/polled.service';
import { ICredentials } from 'src/app/auth/classes/credentials';
import { featureSet } from 'src/app/lib/classes/feature';
import { FeatureService, IFeature } from 'src/app/feature.service';
import { ButtonComponent } from '../../../mavejs/form/button/button.component';
import { NgIf } from '@angular/common';
import { InputDirective } from '../../../mavejs/form/input.directive';
import { TextboxComponent } from '../../../mavejs/form/textbox/textbox.component';
import { SubTitleComponent } from '../../../mavejs/text/sub-title/sub-title.component';
import { CardComponent } from '../../../mavejs/base/card/card.component';

@Component({
    selector: 'ganal-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [CardComponent, SubTitleComponent, FormsModule, ReactiveFormsModule, TextboxComponent, InputDirective, NgIf, ButtonComponent]
})
export class LoginComponent implements OnInit {

  isSubmitting = false;
  isUnauthorized = false;
  loginForm!: FormGroup;

  constructor(private userService: UserService, private feature: FeatureService, private polling: PolledService, private authv4: Authv4Service, private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email, Validators.minLength(5)]),
      password: this.fb.control('', [Validators.required, Validators.minLength(6)])
    })
  }

  login() {
    this.isSubmitting = true

    this.authv4.login(this.loginForm.value.email, this.loginForm.value.password).subscribe({
      next: (credentials: ICredentials) => {
        this.authv4.set(credentials);

        // this.authv4.setExpirationDate()
    
        this.feature.getFeatures().subscribe((features: Array<IFeature>) => {
          for (let feature of features) {
            featureSet(feature.name, feature.is_enabled)
          }

          location.reload();
        });

        this.isSubmitting = false;

        this.router.navigateByUrl('/')
        this.polling.initializePolling();
        this.authv4.emitIsAuthenticatedEvent()
      }, error: () => {
        this.isSubmitting = false;
        this.isUnauthorized = true;
        this.authv4.logout()
      }
    })

  }

  get email() {
    return this.loginForm.get('email')
  }

  get password() {
    return this.loginForm.get('password')
  }
}

export interface User {
  id: number;
  email: string;
  name: string;
}

export interface LoginResponse {
  token: string;
  managedUser: User;
}
