<p-dialog #dialog [visible]="open" styleClass="ga-report-dialog" (visibleChange)="toggle($event)" draggable="false" [modal]="true">
   
    <ng-template pTemplate="header">
        <mv-title [size]="3">Create Report</mv-title>       
    </ng-template>

    <form [formGroup]="createReportForm" (submit)="createReport()">
        <div class="flex -mt-6 mb-12">
            <div class="w-1/2 mr-3">
                <mv-input label="Label">
                    <input type="text" formControlName="name" placeholder="Report name" mvInput>
                    <mv-validation [control]="name" error="required" message="Report name is required"></mv-validation>
                </mv-input>
            </div>
            <div class="w-1/2">
                <mv-input label="Range">
                    <mv-daterangepicker (dates)="dateRangeChanged($event)" [date]="range"></mv-daterangepicker>
                </mv-input>
                <mv-input>
                    <input type="hidden" formControlName="range_from">
                    <mv-validation [control]="range_from" error="required" message="Report range from"></mv-validation>
                </mv-input>
                <mv-input>
                    <input type="hidden" formControlName="range_to">
                    <mv-validation [control]="range_to" error="required" message="Report range to"></mv-validation>
                </mv-input>
            </div>
        </div>

        <div class="mt-3" formArrayName="filters">        
            <ng-container *ngFor="let filter of filters.controls; let i = index">
                <div [formGroupName]="i" class="ga-filter mt-3">
                    <mv-input label="Field">
                        <p-dropdown styleClass="mv-dropdown" (onChange)="autoselect($event, i)" placeholder="Select Field" [options]="fields" formControlName="field"></p-dropdown>
                    </mv-input>
                    
                    <mv-input label="Operator" *ngIf="filters.controls[i].value.field !== 'is_serving_ads'" >
                        <ng-container *ngIf="isNormal(filters.controls[i].value.field)">
                            <p-dropdown styleClass="mv-dropdown" placeholder="Select Operator" [options]="types" formControlName="operator"></p-dropdown>                            
                        </ng-container>

                        <ng-container *ngIf="filters.controls[i].value.field === 'creator__pk' || filters.controls[i].value.field === 'uploader__pk'">
                            <p-dropdown styleClass="mv-dropdown" placeholder="Select Operator" [options]="userTypes" formControlName="operator"></p-dropdown>
                        </ng-container>
                        
                        <ng-container *ngIf="filters.controls[i].value.field === 'name'">
                            <p-dropdown styleClass="mv-dropdown" placeholder="Select Operator" [options]="nameTypes" formControlName="operator"></p-dropdown>
                        </ng-container>
                    </mv-input>

                    <input *ngIf="filters.controls[i].value.field === 'is_serving_ads'" type="hidden" value="iexact" formControlName="operator">

                    <mv-input label="Value">
                        <ng-container *ngIf="filters.controls[i].value.field === 'is_serving_ads'">
                            <div class="pt-1 ml-1">
                                <p-inputSwitch formControlName="value"></p-inputSwitch>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="filters.controls[i].value.field === 'creator__pk' || filters.controls[i].value.field === 'uploader__pk'">
                            <p-dropdown styleClass="mv-dropdown" optionLabel="name" optionValue="id" placeholder="Select Type" [options]="userValues" formControlName="value"></p-dropdown>
                        </ng-container>

                        <div *ngIf="filters.controls[i].value.field === 'is_serving_ads'"></div>

                        <ng-container *ngIf="isNormal(filters.controls[i].value.field) || filters.controls[i].value.field === 'name'">
                            <input mvInput placeholder="0,00" type="text" formControlName="value">
                        </ng-container>                    
                    </mv-input>

                    <div class="flex justify-end">
                        <mv-input label="">
                            <mv-button variant="danger" icon="delete" (click)="removeFilter($event, i)"></mv-button>
                        </mv-input>
                    </div>
                </div>
            </ng-container>

            <div class="flex justify-end mt-3">
                <mv-button variant="primary" icon="add" label="Add Filter" (click)="addFilter($event)"></mv-button>
            </div>
        </div>
    
        <div class="flex justify-between mt-12">
            <div class="flex">
                <mv-button [loading]="isCreatingReport" variant="primary" label="Create Report" type="submit"></mv-button>
                <p-checkbox class="ml-3" formControlName="with_zero_cost" label="With zero cost" [binary]="true" />
            </div>
            
            <mv-button variant="danger" label="Cancel" (click)="close($event)"></mv-button>
        </div>
    </form>

</p-dialog>