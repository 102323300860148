import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operator',
  standalone: true
})
export class OperatorPipe implements PipeTransform {

  transform(value: string, field: string): string {
    if (field === 'is_serving_ads' || field === 'creator__pk' || field === 'uploader__pk') {
      return ''
    }

    return {
      'gt': '>',
      'gte': '>=',
      'lt': '<',
      'lte': '<=',
      'iexact': '=',
      'icontains': 'Contains'
    }[value] ?? '<none>'
  }

}
