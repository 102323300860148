import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Accountv4Service } from 'src/app/account/services/accountv4.service';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { InputComponent } from 'src/app/lib/ui/input/input.component';
import { InputDirective } from 'src/app/lib/ui/input/input.directive';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ProgressBarModule } from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'ga-authorize',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    CardComponent,
    ButtonComponent,
    TitleComponent,
    InputComponent,
    InputDirective,
    InputMaskModule,
    ProgressBarModule,
    CheckboxModule
  ],
  templateUrl: './authorize.component.html',
  styleUrl: './authorize.component.css'
})
export class AuthorizeComponent {

  authorizationUrl: string = ''
  hasCode = false;
  code = '';
  mcc = '';
  accounts: ICustomer[] = []

  isCreatingAccounts = false;

  constructor(private route: ActivatedRoute, private router: Router, private accountv4: Accountv4Service) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.hasCode = (params.code !== '' && params.code !== undefined);
      
      if (this.hasCode) {
        this.code = params.code
      } else {
        this.accountv4.getAuthorizationUrl(false).subscribe((data: any) => {
          window.location.href = data.url;
        })
      }
    })
  }

  load() {
    this.accountv4.list(this.mcc, this.code).subscribe((response: any) => {
      this.accounts = response.accounts;
    })
  }

  create() {
    let accountsToCreate = this.accounts
      .filter((item: ICustomer) => item.selected && !item.exists)
      .map((item: ICustomer) => {
        item['status'] = 0
        item['us_cpm'] = 0
        item['last_seven_days_youtube_percentage'] = 0

        return item;
      })

    if (accountsToCreate.length === 0) {
      this.router.navigateByUrl("/account")
      return;
    }

    this.isCreatingAccounts = true;

    this.accountv4.create(accountsToCreate).subscribe(() => {
      this.isCreatingAccounts = false;
      this.router.navigateByUrl("/account")
    })
  }

}

export interface ICustomer {
  id: number;
  name: string;
  customer_id: string;
  mcc_id: string;
  refresh_token: string;
  status?: number;
  us_cpm?: number;
  last_seven_days_youtube_percentage?: number;
  selected: boolean;
  exists: boolean;
}

export interface IRefreshableCustomer {
  mcc_id: string;
  account_names: string;
}