import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { StatusService } from 'src/app/status/services/status.service';
import { UserService } from 'src/app/user/services/user.service';
import { IAlert } from '../../interfaces/ialert';
import { AlertService } from '../../services/alert.service';
import { Authv4Service } from '../../../auth/services/authv4.service';
import { PolledService } from "../../services/polled.service";
import { Menu, MenuModule } from "primeng/menu";
import { User } from 'src/app/template/classes/template';
import { Syncv4Service } from 'src/app/sync/services/syncv4.service';
import { ISynchronization } from 'src/app/sync/interfaces/sync';
import { featureIsActivated } from '../../classes/feature';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { IStatusPending } from 'src/app/status/interfaces/status-change';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { IUser } from 'src/app/auth/classes/user';
import { NotificationsComponent } from '../notifications/notifications.component';
import { ButtonDirective } from 'primeng/button';
import { NotifyBadgeComponent } from '../../../mavejs/base/notify-badge/notify-badge.component';
import { NavbarItemComponent } from '../navbar-item/navbar-item.component';
import { TitleComponent } from '../../../mavejs/text/title/title.component';
import { NgIf, DatePipe } from '@angular/common';

@Component({
  selector: 'ganal-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [RouterLink, NgIf, TitleComponent, NavbarItemComponent, NotifyBadgeComponent, ButtonDirective, NotificationsComponent, MenuModule, DatePipe]
})
export class NavbarComponent implements OnInit {

  @Input() pageTitle?: string;

  @ViewChild('desktopMenu') desktopMenu!: Menu;
  @ViewChild('userMenu') userMenu!: Menu;

  constructor(
    private statusService: StatusService,
    private authv4: Authv4Service,
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private userv4: Userv4Service,
    // private auth: AuthService, 
    private polledService: PolledService,
    private syncv4: Syncv4Service,
    private statusv4: Statusv4Service
  ) {
  }

  totalCount = 0;
  totalCountString: string = '';
  showGreenIndicator = false;
  showOrangeIndicator = false;
  showRedIndicator = false;
  csvCount = 0;
  alert: IAlert = { message: "" };
  user!: IUser;
  items!: MenuItem[];
  desktopMenuItems!: MenuItem[];
  showMenu: boolean = false;
  showMenuDekstop: boolean = false;

  lastSync!: ISynchronization;

  ngOnInit(): void {
    this.getUser();
    this.setDesktopMenuItems();
    this.getPolledData();
    setInterval(() => this.getPolledData(), 60000);
  }

  getUser() {
    this.userv4.self().subscribe((user: IUser) => {
      this.user = user;

      if (this.user.is_superuser) {
        this.items = [
          {
            label: 'Developer Mode',
            items: [
              { label: 'Api Access', routerLink: '/admin/api_keys' },
            ]
          },
          {
            label: 'User',
            items: [

              { label: 'User Management', routerLink: '/account/permissions' },
              { label: 'User Profile', routerLink: '/user/profile' }
            ]
          },
          {
            label: 'Actions',
            items: [
              { label: 'Logout', icon: 'pi pi-sign-out', command: () => this.logout() }
            ]
          }
        ]
      } else {
        this.items = [
          {
            label: 'User',
            items: [
              { label: 'User Management', routerLink: '/account/permissions' },
              { label: 'User Profile', routerLink: '/user/profile' }
            ]
          },
          {
            label: 'Actions',
            items: [
              { label: 'Logout', icon: 'pi pi-sign-out', command: () => this.logout() }
            ]
          }
        ]
      }
    })
  }

  getPolledData() {
    this.statusv4.pending().subscribe((pending: IStatusPending) => {
      this.showGreenIndicator = pending.has_new_manual_changes;
      this.showOrangeIndicator = pending.has_older_than_two_hours_manual_changes;

      if (this.showOrangeIndicator) {
        this.showGreenIndicator = false;
      }

      this.showRedIndicator = pending.has_statuses_with_errors
      this.totalCount = pending.count;

      if (this.totalCount > 0) {
        this.totalCountString = '(' + this.totalCount + ')';
      } else {
        this.totalCountString = '';
      }
    });

    this.syncv4.last().subscribe((sync: ISynchronization) => {
      this.lastSync = sync;
    })
  }

  /**
   * @deprecated Never used this once
   * @param alert
   */
  setAlert(alert: IAlert) {
    this.alert = alert;
    this.alertService.alert$.emit(alert);
  }

  logout() {
    this.authv4.logout()
    this.router.navigateByUrl("/user/login");
  }

  showHideMenu() {
    this.showMenu = !this.showMenu;
  }

  setDesktopMenuItems() {
    this.desktopMenuItems = [
      {
        label: 'Other',
        items: [
          { label: 'Sync log', routerLink: '/sync' },
          { label: 'Accounts', routerLink: '/account' },
          { label: 'Templates', routerLink: '/template' }
        ]
      }
    ]
  }

  // close menu's when scrolling
  @HostListener('window:scroll', ['$event'])
  hideNavbarMenus() {
    this.desktopMenu.hide();
    this.userMenu.hide();
  }

}

export interface IStatusCount {
  normalCount: number;
  csvCount: number;
  totalCount: number;
}
