<div class="w-full md:w-1/3 mx-auto">
  <mv-card>
    <div header>
      <mv-title [size]="3">Settings</mv-title>
    </div>
    <div body>
      <div class="flex flex-col gap-5" *ngIf="!isloading">
        <div class="flex items-center justify-between">
          <span>Email notifications:</span>
          <!-- <p-toggleButton [(ngModel)]="emailNotifications" (onChange)="toggleEmailNotifications()" onIcon="pi pi-check" offIcon="pi pi-times"></p-toggleButton> -->

          <p-inputSwitch [(ngModel)]="emailNotifications" (onChange)="toggleEmailNotifications()"></p-inputSwitch>
        </div>
        <div class="flex items-center justify-between">
          <span>Holiday backgrounds</span>
          <p-inputSwitch [(ngModel)]="hasBackgrounds" (onChange)="toggleBackgrounds()"></p-inputSwitch>
        </div>
        <div class="flex items-center justify-between">
          <span>Telefoonnummer:</span>
        
          <input type="text" mvInput class="w-52" [(ngModel)]="currentUser.phone">
        </div>
        <div class="flex place-self-end">
          <mv-button icon="save" variant="primary" label="Save" (click)="saveUserProfile()"></mv-button>
        </div>
        <div *ngIf="currentUser.is_superuser">
          <mv-title [size]="3">New Features</mv-title>
        </div>
        <div *ngIf="currentUser.is_superuser">
          <div class="ga-feature-item" *ngFor="let feature of features">
            <div class="font-bold text-sm text-black">{{ feature.name | summary }}</div>
            <div class="ga-feature-item__switch">
              <p-inputSwitch [(ngModel)]="feature.is_enabled" (ngModelChange)="toggleFeature(feature)"></p-inputSwitch>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mv-card>
</div>
