import { Component } from '@angular/core';
import { Accountv4Service } from 'src/app/account/services/accountv4.service';
import { AuthorizationCode } from '../../legacy/create/create.component';
import { CardComponent } from "../../../../lib/ui/card/card.component";
import { TitleComponent } from "../../../../lib/ui/title/title.component";
import { InputComponent } from "../../../../lib/ui/input/input.component";
import { ButtonComponent } from "../../../../lib/ui/button/button.component";
import { ProgressBarModule } from 'primeng/progressbar';
import { InputMaskModule } from 'primeng/inputmask';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { ICustomer, IRefreshableCustomer } from '../authorize/authorize.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { LimitTrailingPipe } from "../../../../lib/pipes/limit-trailing.pipe";

@Component({
  selector: 'ga-refresh',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    CardComponent,
    TitleComponent,
    InputComponent,
    ButtonComponent,
    ProgressBarModule,
    InputMaskModule,
    CheckboxModule,
    DropdownModule,
    LimitTrailingPipe
],
  templateUrl: './refresh.component.html',
  styleUrl: './refresh.component.css'
})
export class RefreshComponent {
  
  authorizationUrl: string = ''
  hasCode = false;
  code = '';
  mcc!: IRefreshableCustomer;
  accounts: ICustomer[] = []
  mccs: any[] = []

  isLoadingMccs = true;
  isCreatingAccounts = false;
  isRefreshing = false;

  constructor(private route: ActivatedRoute, private router: Router, private accountv4: Accountv4Service) { }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.hasCode = (params.code !== '' && params.code !== undefined);
      
      if (this.hasCode) {
        this.code = params.code
        this.accountv4.mcc().subscribe((mccs: any[]) => {
          this.mccs = mccs;
          this.isLoadingMccs = false;
        })
      } else {
        this.accountv4.getAuthorizationUrl(true).subscribe((data: any) => {
          window.location.href = data.url;
        })
      }
    })
  }

  refresh() {
    this.isRefreshing = true;
    this.accountv4.refresh(this.mcc.mcc_id, this.code).subscribe(() => {
      this.isRefreshing = false;
      this.router.navigateByUrl("/account")
    })
  }

}

