import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'state',
  standalone: true
})
export class StatePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    return {
      0: 'Pending',
      1: 'Executed',
      2: 'Checked',
      3: 'Invalid',
      4: 'Proposed' 
    }[value]
  }

}
