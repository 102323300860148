<div class="ga-template-view">
    <mv-card>
        <div header>
            <div class="flex justify-between">
                <div>
                    <mv-title [size]="3">Templates for user</mv-title>
                </div>
                <div>
                    <mv-button label="Create Template" variant="primary" routerLink="/template/create"></mv-button>
                </div>
            </div>
            
        </div>
        <div body>
            <div *ngIf="templates && templates.length > 0" class="ga-template-grid">
                <div class="ga-template-item" *ngFor="let template of templates">
                    <div>
                        {{ template.name }}
                        <div class="ga-template-item-ranges">
                            <span *ngFor="let range of template.ranges">{{ range.name }};</span>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div pTooltip="Set as default template" tooltipPosition="top">
                            <i 
                                (click)="default(template.id)"
                                class="material-symbols-outlined ga-template-default" 
                                [class.ga-template-default-activated]="defaultTemplateId === template.id">
                                check_circle
                            </i>
                        </div>
                       
                        <mv-button variant="danger" icon="delete" (click)="delete(template.id)"></mv-button>
                    </div>
                </div>
            </div>

            <div *ngIf="templates && templates.length === 0" class="mv-empty">
                <i class="material-symbols-outlined mv-empty__icon">search_off</i>
                <div class="mv-empty__label">No Results</div>
            </div>
        </div>

    </mv-card>

</div>