import { HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, retry, delay, retryWhen } from 'rxjs/operators';

export const serverErrorInterceptor: HttpInterceptorFn = (req, next) => {

  let router = inject(Router);

  return next(req).pipe(
    retry({count: 1, delay: (error) => {
      if (error.status === HttpStatusCode.NotFound) {
        return throwError(() => error);
      }

      return next(req);
    }}),
    catchError((error: any) => {
      if (error.status === HttpStatusCode.InternalServerError) {
        router.navigateByUrl('/errors/500');
        return next(req);
      }

      if (error.status === HttpStatusCode.Unauthorized) {
        router.navigateByUrl("/user/login");
        return next(req);
      }

      return throwError(() => error);
    })
  )
};
