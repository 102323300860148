import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICampaignData } from 'src/app/campaign/interfaces/campaign';
import { costDistribution, estimateRoi, IParental } from '../../classes/demographics/demographics';
import { Parentalv4Service } from 'src/app/adgroup/services/parentalv4.service';
import { IRange } from 'src/app/lib/classes/ranges';
import { DateRange } from 'src/app/lib/classes/daterange';
import { CurrencyPipe, DecimalPipe, NgFor, NgIf, PercentPipe } from '@angular/common';
import { IAccount } from 'src/app/account/interfaces/account';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConversionDirective } from 'src/app/lib/directives/conversion.directive';
import { GradeDirective } from 'src/app/adgroup/directives/grade.directive';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { RangeLabelPipe } from '../../pipes/range-label.pipe';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { MessageService } from 'primeng/api';
import { IStatusChangeType, IStatusHistory, IStatusPayload } from 'src/app/status/interfaces/status-change';
import { SyncComponent } from 'src/app/lib/ui/sync/sync.component';

@Component({
  selector: 'ga-parentals-table',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    CardComponent,
    TableModule,
    CurrencyPipe,
    InputSwitchModule,
    PercentPipe,
    DecimalPipe,
    ConversionDirective,
    GradeDirective,
    TitleComponent,
    RangeLabelPipe,
    SyncComponent
  ],
  templateUrl: './parentals-table.component.html',
  styleUrl: './parentals-table.component.css'
})
export class ParentalsTableComponent {
  @Input() campaignId!: number;
  @Input() adGroupId!: number;
  @Input() account!: IAccount;
  @Input() ranges!: Array<DateRange>
  @Input() data!: Array<ICampaignData>

  @Output() rangesChange = new EventEmitter<Array<IRange>>();
  @Output() loaded = new EventEmitter<number>();

  parentals: Array<IParental> = []

  rangeParentals: Map<String, Map<number, IParental>> = new Map()
  parentalsById = new Map();
  parentalIds: Array<number> = [];

  parentalChanges: Array<IStatusHistory> = [];
  isParentalsChangesLoaded = false;
  loadedCount = 0;
  

  constructor(private parentalv4: Parentalv4Service, private statusv4: Statusv4Service, private message: MessageService) {}

  ngOnInit(): void {
    this.load(this.ranges)
    this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Parental).subscribe((changes: Array<IStatusHistory>) => {
      this.parentalChanges = changes;
      this.isParentalsChangesLoaded = true;
    });
  }

  load(ranges: Array<DateRange>) {
    this.isParentalsChangesLoaded = false;
    
    for (let range of ranges) {
      this.parentalv4.getParentals(this.campaignId, range).subscribe((parentals: Array<any>) => {
        let mappedParentals = new Map()

        for (let parental of parentals) {
          if (this.parentalsById.has(parental.id)) {
            let existingParental = this.parentalsById.get(parental.id);

            existingParental[`roi_range_${range}`] = this.estimateRoi(range.toString(), parental.total_cost, parental.total_conversions);
            existingParental[`cost_range_${range}`] = parental.total_cost || null;         
            existingParental[`profit_range_${range}`] = parental.total_profit || 0;    
            existingParental[`revenue_range_${range}`] = parental.total_revenue || 0;   
            existingParental[`clicks_range_${range}`] = parental.total_clicks || 0;     
            existingParental[`conversions_range_${range}`] = parental.total_conversions || null;
            existingParental[`conversions_tracked_range_${range}`] = parental.total_conversions_tracked || null;
            existingParental[`impressions_range_${range}`] = parental.total_impressions || 0;

            this.parentalsById.set(parental.id, existingParental);
          } else {
            let newParental = { ...parental }; 

            newParental[`roi_range_${range}`] = this.estimateRoi(range.toString(), parental.total_cost, parental.total_conversions);
            newParental[`cost_range_${range}`] = parental.total_cost || null;
            newParental[`profit_range_${range}`] = parental.total_profit || 0;
            newParental[`revenue_range_${range}`] = parental.total_revenue || 0;
            newParental[`clicks_range_${range}`] = parental.total_clicks || 0;
            newParental[`conversions_range_${range}`] = parental.total_conversions || null;
            newParental[`conversions_tracked_range_${range}`] = parental.total_conversions_tracked || null;
            newParental[`impressions_range_${range}`] = parental.total_impressions || 0;

            this.parentalsById.set(parental.id, newParental);
            this.parentalIds.push(parental.id);
          }

          mappedParentals.set(parental.id, parental)
        }

        this.rangeParentals.set(range.toString(), mappedParentals);

        this.loadedCount++;
        this.loaded.emit(this.loadedCount);

        if (this.loadedCount === ranges.length) {
          this.parentals = Array.from(this.parentalsById.values());  
          this.isParentalsChangesLoaded = true;
        }
      })
    }
  }

  estimateRoi(range: string, cost: number, conversions: number): number | null {
    let metrics = this.data.find((item: ICampaignData) => item.range_key === range);
    let roi = estimateRoi(metrics?.revenue ?? 0, cost, metrics?.conversions ?? 0, conversions)

    return roi
  }

  costDistribution(range: string, cost: number) {
    let metrics = this.data.find((item: ICampaignData) => item.range_key === range);

    return costDistribution(metrics?.cost ?? 0, cost)
  }

  changeStatus(parental: IParental) {
    let payload: IStatusPayload = {
      id: parental.id,
      account_id: this.account.id,
      campaign_id: this.campaignId,
      ad_group_id: this.adGroupId,
      type: IStatusChangeType.Parental
    }

    this.statusv4.apply(payload).subscribe({
      complete: () => {
        this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Parental).subscribe((changes: Array<IStatusHistory>) => {
          this.parentalChanges = changes;
        })
      },
      error: () => this.message.add({
        severity: 'error',
        summary: 'Could not change status'
      })
    })
  }

  hasStatus(parental: IParental) {
    if (parental === undefined) {
      return false;
    }

    return this.parentalChanges.find(item => item.entity_id === parental.id) !== undefined
  }
}
