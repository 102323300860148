import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'type',
  standalone: true
})
export class TypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return {
      0: 'Campaign',
      1: 'Ad',
      2: 'Device',
      3: 'Age',
      4: 'Parental',
      5: 'Gender',
      6: 'Income',
      7: 'Budget',
      8: 'Cpa',
      9: 'Max Conversions',
    }[value] ?? 'None';
  }

}
