import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { Reportsv4Service } from '../../services/reportsv4.service';
import { IReport, IReportCampaign } from '../../classes/report';
import { ITotals } from 'src/app/lib/interfaces/statistics';
import { TableModule } from 'primeng/table';
import { CurrencyPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { ConversionDirective } from 'src/app/lib/directives/conversion.directive';
import { TotalsComponent } from 'src/app/lib/components/totals/totals.component';
import { FieldPipe } from '../../pipes/field.pipe';
import { OperatorPipe } from '../../pipes/operator.pipe';
import { FilterValuePipe } from '../../pipes/filter-value.pipe';
import { IUser } from 'src/app/auth/classes/user';
import { Userv4Service } from 'src/app/user/services/userv4.service';

@Component({
  selector: 'ga-show-report-view',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CardComponent,
    TableModule,
    DatePipe,
    TitleComponent,
    CurrencyPipe,
    ConversionDirective,
    RouterLink,
    TotalsComponent,
    FieldPipe,
    OperatorPipe,
    FilterValuePipe
  ],
  templateUrl: './show-report-view.component.html',
  styleUrl: './show-report-view.component.css'
})
export class ShowReportViewComponent implements OnInit {

  @Input() id!: number;

  report!: IReport
  reportCampaigns!: IReportCampaign[]
  totals!: ITotals
  users!: IUser[]

  isTotalsLoaded = false;
  isReportLoaded = false;
  isReportCampaignsLoaded = false;
  isUsersLoaded = false;

  constructor(
    private reportv4: Reportsv4Service, 
    private userv4: Userv4Service,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe(param => {
      let id = +param['id']

      this.userv4.all().subscribe((users: IUser[]) => {
        this.users = users;
        this.isUsersLoaded = true;
      })

      this.reportv4.show(id).subscribe((report: IReport) => {
        this.report = report
        this.isReportLoaded = true;
      })

      this.reportv4.showReportCampaigns(id).subscribe((reportCampaigns: IReportCampaign[]) => {
        this.reportCampaigns = reportCampaigns;
        this.isReportCampaignsLoaded = true;
      })

      this.reportv4.totals(id).subscribe((totals: ITotals) => {
        this.totals = totals;
        this.isTotalsLoaded = true;
      });
    })
  }

}
