import {
  endOfMonth,
  startOfYesterday,
  subWeeks,
  startOfMonth,
  startOfToday,
  subMonths, startOfWeek, endOfWeek, addDays, subDays, startOfYear
} from 'date-fns'
import { IDateRangeMapping, ISummaryMapping } from '../interfaces/date-range-mapping'
import { DateRange } from './daterange'
import { ITemplateRange } from 'src/app/template/classes/template'

/**
 * @deprecated Old Ranges object
 */
export class Ranges {
  static getRangesReversed() {
    throw new Error('Method not implemented.')
  }
  get ranges(): IDateRangeMapping {
    return {
      'TODAY': new DateRange(startOfToday(), startOfToday()),
      'YESTERDAY': new DateRange(startOfYesterday(), startOfYesterday()),
      'LAST_7_DAYS': new DateRange(subWeeks(startOfToday(), 1), startOfYesterday()),
      'TWO_DAYS_AGO':new DateRange(subDays(startOfToday(), 2), subDays(startOfToday(), 2)),
      'THIS_MONTH': new DateRange(startOfMonth(new Date()), endOfMonth(new Date())),
      'LAST_MONTH': new DateRange(startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))),
      'LAST_14_DAYS': new DateRange(subWeeks(startOfToday(), 2), startOfYesterday()),
      'LAST_30_DAYS': new DateRange(subDays(startOfToday(), 30), startOfYesterday()),
      'ONE_WEEK_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 1)), 1), addDays(endOfWeek(subWeeks(new Date(), 1)), 1)),
      'TWO_WEEKS_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 2)), 1), addDays(endOfWeek(subWeeks(new Date(), 2)), 1)),
      'THREE_WEEKS_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 3)), 1), addDays(endOfWeek(subWeeks(new Date(), 3)), 1)),
      'FOUR_WEEKS_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 4)), 1), addDays(endOfWeek(subWeeks(new Date(), 4)), 1)),
      'THIS_YEAR': new DateRange(startOfYear(new Date()), startOfToday()),
    }
  }

  get rangesReversed(): ISummaryMapping {
    const today = this.ranges['TODAY'].toString();
    const yesterday = this.ranges['YESTERDAY'].toString();
    const lastSevenDays = this.ranges['LAST_7_DAYS'].toString();
    const twoDaysAgo = this.ranges['TWO_DAYS_AGO'].toString();
    const thisMonth = this.ranges['THIS_MONTH'].toString();
    const lastMonth = this.ranges['LAST_MONTH'].toString();
    const lastFourtheenDays = this.ranges['LAST_14_DAYS'].toString();
    const lastThirtyDays = this.ranges['LAST_30_DAYS'].toString();
    const oneWeekAgo = this.ranges['ONE_WEEK_AGO'].toString();
    const twoWeeksAgo = this.ranges['TWO_WEEKS_AGO'].toString();
    const threeWeeksAgo = this.ranges['THREE_WEEKS_AGO'].toString();
    const fourWeeksAgo = this.ranges['FOUR_WEEKS_AGO'].toString();
    const thisYear = this.ranges['THIS_YEAR'].toString();

    return {
      [today]: 'TODAY',
      [yesterday]: 'YESTERDAY',
      [twoDaysAgo]: 'TWO_DAYS_AGO',
      [thisMonth]: 'THIS_MONTH',
      [lastMonth]: 'LAST_MONTH',
      [lastFourtheenDays]: 'LAST_14_DAYS',
      [lastThirtyDays]: 'LAST_30_DAYS',
      [oneWeekAgo]: 'ONE_WEEK_AGO',
      [twoWeeksAgo]: 'TWO_WEEKS_AGO',
      [threeWeeksAgo]: 'THREE_WEEKS_AGO',
      [fourWeeksAgo]: 'FOUR_WEEKS_AGO',
      [lastSevenDays]: 'LAST_7_DAYS',
      [thisYear]: 'LAST_7_DAYS'
    }
  }

  get rangesPicker(): any {
    let pickerRanges: any = {};

    for (let [rangeKey, range] of Object.entries(this.ranges)) {
      pickerRanges[rangeKey] = [range.from, range.to]
    }

    return pickerRanges;
  }

  static getItems(): Array<IRange> {
    return [
      // { value: 0, name: 'TODAY' },
      { value: 1, name: 'YESTERDAY' },
      { value: 2, name: 'LAST_7_DAYS' },
      // { value: 3, name: 'TWO_DAYS_AGO' },
      { value: 4, name: 'THIS_MONTH' },
      { value: 5, name: 'LAST_MONTH' },
      // { value: 6, name: 'LAST_14_DAYS' },
      { value: 7, name: 'LAST_30_DAYS' },
      { value: 8, name: 'ONE_WEEK_AGO' },
      { value: 9, name: 'TWO_WEEKS_AGO' },
      { value: 10, name: 'THREE_WEEKS_AGO' },
      // { value: 11, name: 'FOUR_WEEKS_AGO' },
      { value: 12, name: 'THIS_YEAR' }
    ]
  }

  static parseFromRanges(ranges: ITemplateRange[]): DateRange[] {
    return ranges.map((range: ITemplateRange) => {
      if (range.is_custom && range.from_as_date !== undefined && range.to_as_date !== undefined) {
        return new DateRange(range.from_as_date, range.to_as_date)
      } else {
        return new Ranges().ranges[range.preset]
      }
    })
  }

}

export interface IRange {
  value: number;
  name: string;
  isSelected?: boolean;
}








