<p-dialog #dialog [visible]="open" (visibleChange)="toggle($event)" draggable="false" [modal]="true">
    <ng-template pTemplate="header">
        <div class="flex flex-col mr-12">
            <div>
                <mv-title [size]="3">Change Budget</mv-title>
            </div>
            <div class="text-sm">{{ campaign ? campaign.name : '-' }}</div>
            <div class="text-sm text-primary-light">{{ account ? account.name : '-' }}</div>
        </div>
    </ng-template>
    
    
    <form *ngIf="budgetForm" [formGroup]="budgetForm" (submit)="changeBudget()">
        <mv-input label="Budget">
            <div button>
                <div *ngIf="account !== undefined" class="p-1 pr-3 text-secondary font-bold">
                    {{ account.currency === 'EUR' ? '€' : '$' }}
                </div>
            </div>
            <input class="mv-input-bare" formControlName="budget" type="text">
        </mv-input>
    
        <div class="-mb-3" *ngIf="campaign && isDrasticChange(budget?.value, campaign.budget)">
            <mv-cvalidation [condition]="isDrasticChange(budget?.value, campaign.budget)" label="Very high budget change, beware"></mv-cvalidation>
        </div>
       
        <span class="font-bold text-sm">Weekly spend: </span><span class="text-primary font-bold text-sm">{{ (budget?.value) | currency }}</span>
    </form>

    <div class="flex justify-between mt-12">
        <div class="flex">
            <mv-button class="mr-2" variant="primary" label="Set Budget" (click)="changeBudget()"></mv-button>
        </div>
        <div>
            <mv-button variant="secondary" (click)="close($event)" label="Cancel"></mv-button>
        </div>
    </div>
</p-dialog>