import {
    addDays,
    differenceInDays,
    endOfMonth, endOfWeek,
    endOfYear,
    startOfMonth,
    startOfToday,
    startOfWeek,
    startOfYear,
    startOfYesterday, subDays,
    subMonths,
    subWeeks
} from "date-fns";
import { format } from 'date-fns'
import { IRange } from "../../classes/ranges";
import { ITemplateRange } from "src/app/template/classes/template";

export interface IRangeNumberMapping {
    [range: number]: string;
}

export interface IRangeStringMapping {
    [range: string]: number;
}

export class DateRange {
    from = new Date();
    to = new Date();

    constructor(from: Date, to: Date) {
        this.from = from;
        this.to = to;
    }

    getFrom(): Date {
        return this.from;
    }

    getTo(): Date {
        return this.to;
    }

    setFrom(date: Date) {
        this.from = date;
    }

    setTo(date: Date) {
        this.to = date;
    }

    getFromFormatted(): string {
        return format(this.from, 'yyyy-MM-dd');
    }

    getToFormatted(): string {
        return format(this.to, 'yyyy-MM-dd');
    }

    getFromFormattedView(): string {
        return format(this.from, 'yyyy/MM/dd');
    }

    getToFormattedView(): string {
        return format(this.to, 'yyyy/MM/dd');
    }

    getDiff(): number {
        let diff = differenceInDays(this.to, this.from)
        return diff === 0 ? diff + 1 : diff;
    }

    isValid(): boolean {
        if (this.from === undefined || this.to === undefined) {
            return false;
        }

        if (this.from === null || this.to === null) {
            return false;
        }

        return true;
    }

    toString(): string {
        return this.getFromFormatted() + " - " + this.getToFormatted();
    }
}

export interface RangeMapping {
    [key: string]: DateRange | undefined
}

export interface RangeReverseMapping {
    [range: string]: string
}

export class Ranges {

    static getDefault(): DateRange {
        return new DateRange(subWeeks(startOfToday(), 1), startOfYesterday())
    }

    static getRanges(): RangeMapping {
        return {
            'TODAY': new DateRange(startOfToday(), startOfToday()),
            'YESTERDAY': new DateRange(startOfYesterday(), startOfYesterday()),
            'LAST_7_DAYS': new DateRange(subWeeks(startOfToday(), 1), startOfYesterday()),
            'THIS_WEEK': new DateRange(addDays(startOfWeek(new Date()), 1), new Date()),
            'LAST_WEEK': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 1)), 1), addDays(endOfWeek(subWeeks(new Date(), 1)), 1)),
            'THIS_MONTH': new DateRange(startOfMonth(new Date()), endOfMonth(new Date())),
            'LAST_MONTH': new DateRange(startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))),
            'LAST_14_DAYS': new DateRange(subWeeks(startOfToday(), 2), startOfYesterday()),
            'LAST_30_DAYS': new DateRange(subDays(startOfToday(), 30), startOfYesterday()),
            'ONE_WEEK_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 1)), 1), addDays(endOfWeek(subWeeks(new Date(), 1)), 1)),
            'TWO_WEEKS_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 2)), 1), addDays(endOfWeek(subWeeks(new Date(), 2)), 1)),
            'THREE_WEEKS_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 3)), 1), addDays(endOfWeek(subWeeks(new Date(), 3)), 1)),
            'FOUR_WEEKS_AGO': new DateRange(addDays(startOfWeek(subWeeks(new Date(), 4)), 1), addDays(endOfWeek(subWeeks(new Date(), 4)), 1)),
            'THIS_YEAR': new DateRange(startOfYear(new Date()), endOfYear(new Date())),
            'CUSTOM': undefined
        }
    }

    static getRangesReversed(): RangeReverseMapping {
        const ranges = Ranges.getRanges();

        const today = ranges['TODAY']?.toString() ?? '';
        const yesterday = ranges['YESTERDAY']?.toString() ?? '';
        const lastSevenDays = ranges['LAST_7_DAYS']?.toString() ?? '';
        const thisWeek = ranges['THIS_WEEK']?.toString() ?? '';
        const lastWeek = ranges['LAST_WEEK']?.toString() ?? '';
        const thisMonth = ranges['THIS_MONTH']?.toString() ?? '';
        const lastMonth = ranges['LAST_MONTH']?.toString() ?? '';
        const lastFourtheenDays = ranges['LAST_14_DAYS']?.toString() ?? '';
        const lastThirtyDays = ranges['LAST_30_DAYS']?.toString() ?? '';
        const oneWeekAgo = ranges['ONE_WEEK_AGO']?.toString() ?? '';
        const twoWeeksAgo = ranges['TWO_WEEKS_AGO']?.toString() ?? '';
        const threeWeeksAgo = ranges['THREE_WEEKS_AGO']?.toString() ?? '';
        const fourWeeksAgo = ranges['FOUR_WEEKS_AGO']?.toString() ?? '';

        return {
            [today]: 'TODAY',
            [yesterday]: 'YESTERDAY',
            [thisWeek]: 'THIS_WEEK',
            [lastWeek]: 'LAST_WEEK',
            [thisMonth]: 'THIS_MONTH',
            [lastMonth]: 'LAST_MONTH',
            [lastFourtheenDays]: 'LAST_14_DAYS',
            [lastThirtyDays]: 'LAST_30_DAYS',
            [oneWeekAgo]: 'ONE_WEEK_AGO',
            [twoWeeksAgo]: 'TWO_WEEKS_AGO',
            [threeWeeksAgo]: 'THREE_WEEKS_AGO',
            [fourWeeksAgo]: 'FOUR_WEEKS_AGO',
            [lastSevenDays]: 'LAST_7_DAYS'
        }
    }

    static getMapping(): IRangeNumberMapping {
        return {
            0: 'TODAY',
            1: 'YESTERDAY',
            2: 'LAST_7_DAYS',
            3: 'TWO_DAYS_AGO',
            4: 'THIS_MONTH',
            5: 'LAST_MONTH',
            6: 'LAST_14_DAYS',
            7: 'LAST_30_DAYS',
            8: 'ONE_WEEK_AGO',
            9: 'TWO_WEEKS_AGO',
            10: 'THREE_WEEKS_AGO',
            11: 'FOUR_WEEKS_AGO',
            12: 'THIS_YEAR',
        }
    }

    static getCodeMapping(): IRangeStringMapping {
        return {
            'TODAY': 0,
            'YESTERDAY': 1,
            'LAST_7_DAYS': 2,
            'TWO_DAYS_AGO': 3,
            'THIS_MONTH': 4,
            'LAST_MONTH': 5,
            'LAST_14_DAYS': 6,
            'LAST_30_DAYS': 7,
            'ONE_WEEK_AGO': 8,
            'TWO_WEEKS_AGO': 9,
            'THREE_WEEKS_AGO': 10,
            'FOUR_WEEKS_AGO': 11,
            'THIS_YEAR': 12,
        }
    }

    static getItems(): Array<IRange> {
        return [
            // { value: 0, name: 'TODAY' },
            { value: 1, name: 'YESTERDAY' },
            { value: 2, name: 'LAST_7_DAYS' },
            // { value: 3, name: 'TWO_DAYS_AGO' },
            { value: 4, name: 'THIS_MONTH' },
            { value: 5, name: 'LAST_MONTH' },
            // { value: 6, name: 'LAST_14_DAYS' },
            { value: 7, name: 'LAST_30_DAYS' },
            { value: 8, name: 'ONE_WEEK_AGO' },
            { value: 9, name: 'TWO_WEEKS_AGO' },
            { value: 10, name: 'THREE_WEEKS_AGO' },
            // { value: 11, name: 'FOUR_WEEKS_AGO' },
            { value: 12, name: 'THIS_YEAR' }
        ]
    }

    static toCodeArray(ranges: Array<string>): Array<number> {
        const rangeStringArray = [];
        const rangeNumberMapping = this.getCodeMapping();

        for (let range of ranges) {
            rangeStringArray.push(rangeNumberMapping[range]);
        }

        return rangeStringArray;
    }

    static toNumbers(ranges: Array<DateRange>): Array<number> {
        const rangeNumberArray = [];

        for (let range of ranges) {
            let dateRangeKey = range.toString()
            let key = Ranges.getRangesReversed()[dateRangeKey]

            rangeNumberArray.push(Ranges.getCodeMapping()[key]);
        }

        return rangeNumberArray;
    }

    static parseFromRanges(ranges: ITemplateRange[]): DateRange[] {
        let rangeMap = ranges.map((range: ITemplateRange) => {
            let cRange = null

            if (range.is_custom && range.from_as_date !== undefined && range.to_as_date !== undefined) {
                return new DateRange(range.from_as_date, range.to_as_date)
            } else {
                return Ranges.getRanges()[range.preset] ?? Ranges.getDefault()
            }
        })

        return this.unique(rangeMap)
    }

    static unique(ranges: DateRange[]): DateRange[] {
        let existingRanges: any = []

        return <DateRange[]> ranges.map((item: DateRange) => {
            if (existingRanges.includes(item.toString())) {
                return null;
            } else {
                existingRanges.push(item.toString())
                return item;
            }
        }).filter((range: DateRange | null) => range !== null);
    }

    static isCustom(range: DateRange) {
        return this.getRangesReversed()[range.toString()] === undefined
    }
}

