<form [formGroup]="templateForm" (submit)="save()">

    <mave-textbox label="Name">
      <input formControlName="name" maveInput>
    </mave-textbox>

    <div class="mt-4"></div>

    <mave-label>Select Timeranges</mave-label>
    <div formArrayName="timeranges" class="px-6 py-5 rounded-lg bg-whitish mt-2">
        <div *ngFor="let timerange of timeranges.controls; let currentIndex = index">
          <div class="flex py-2">
            <mave-label for="timerange-{{ currentIndex }}">
              <input id="timerange-{{ currentIndex }}" class="mr-2" type="checkbox" [formControlName]="currentIndex">
              {{ timerangeLabels[currentIndex] | summary }}
            </mave-label>
          </div>
        </div>
    </div>

    <div class="mt-3 border-t border-gray -mx-5 p-5 pb-0">
        <mave-button
            [label]="submitMessage"
            [disabled]="!templateForm.valid"
            [loading]="isSubmitting"
            (press)="save()">
        </mave-button>
    </div>

</form>
