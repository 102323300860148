import { Component, OnInit, ViewChild } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { IUser } from 'src/app/auth/classes/user';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { InputComponent } from 'src/app/lib/ui/input/input.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { CreateReportDialogComponent } from '../../components/create-report-dialog/create-report-dialog.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Reportsv4Service } from '../../services/reportsv4.service';
import { IReport, IReportPagination, Report } from '../../classes/report';
import { DatePipe, LowerCasePipe, NgIf } from '@angular/common';
import { ReportStatusPipe } from '../../pipes/report-status.pipe';
import { ReportTypePipe } from '../../pipes/report-type.pipe';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { RouterLink } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DateRange } from 'src/app/lib/classes/daterange';
import { startOfToday, startOfYesterday, subWeeks } from 'date-fns';

@Component({
  selector: 'ga-index-report-view',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    TableModule,
    InputComponent,
    DropdownModule,
    ButtonComponent,
    TitleComponent,
    CreateReportDialogComponent,
    DatePipe,
    ReportStatusPipe,
    ReportTypePipe,
    LowerCasePipe,
    RouterLink
  ],
  templateUrl: './index-report-view.component.html',
  styleUrl: './index-report-view.component.css'
})
export class IndexReportViewComponent implements OnInit {
  @ViewChild('dialog') dialog!: CreateReportDialogComponent
  
  types = [
    {label: 'Daily', value: '0'},
    {label: 'Custom', value: '1'}
  ]

  users: IUser[] = []
  reports!: IReportPagination
  limit: number = 20
  createReportIsOpen = false
  offset = 0
  filterForm!: FormGroup;
  range: DateRange = new DateRange(subWeeks(startOfToday(), 1), startOfYesterday());
  
  constructor(
    private fb: FormBuilder, 
    private userv4: Userv4Service, 
    private reportsv4: Reportsv4Service,
    private confirmation: ConfirmationService,
  ) {

  }

  ngOnInit(): void {
    this.userv4.all().subscribe((users: Array<IUser>) => {
      this.users = users
    })

    this.userv4.self().subscribe((user: IUser) => {
      this.filterForm = this.fb.group({
        search: [''],
        user: [user.id],
        type: ['1', Validators.required]
      })

      this.fetch()

      this.filterForm.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => {
        this.fetch()
      })
    })  
  }

  fetch() {
    let search = this.filterForm.get('search')?.value;
    let user = this.filterForm.get('user')?.value;
    let type = this.filterForm.get('type')?.value;

    this.reportsv4.paginate(search, user, type, this.limit, this.offset).subscribe((reports: IReportPagination) => {
      this.reports = reports
    })
  }

  openCreateReportDialog() {
    this.createReportIsOpen = true;
  }

  paginate(event: TableLazyLoadEvent) {
    this.offset = event.first ?? 0;
    this.fetch()
  }

  new() {
    this.dialog.setReport(Report.noop(this.range.from, this.range.to))
    this.createReportIsOpen = true;
  }

  duplicate(reportId: number) {
    this.reportsv4.show(reportId).subscribe((report: IReport) => {
      this.createReportIsOpen = true;
      this.dialog.setReport(report)
    })
  }

  delete(reportId: number) {
    this.confirmation.confirm({
      header: 'Delete report',
      icon: 'delete',
      key: 'delete',
      message: 'Do you really want to delete this report?',
      accept: () => this.reportsv4.delete(reportId).subscribe(() => {
        this.fetch()
      })
    })
  }

}
