<mv-card [table]="true">
    <div header>
        <div class="flex items-center justify-between mb-3" *ngIf="isReportLoaded">
            <div>
                <mv-title [size]="3">{{ report.name }}</mv-title>
            </div>
            <div *ngIf="isUsersLoaded">
                <div class="font-bold text-md -mb-2">Filters</div>
                <span class="font-bold text-primary-dark text-sm" *ngFor="let filter of report.filters; let i = index">{{ filter.field | field }} {{ filter.operator | operator:filter.field }} {{ filter.value | filterValue: filter.field:users }}<span *ngIf="i < (report.filters.length - 1)">, </span></span>
            </div>
        </div>

        <div class="my-2">
            <ga-totals *ngIf="isTotalsLoaded" [totals]="totals"></ga-totals>
        </div>
    </div>
    <div body>
        <p-table 
            #r
            *ngIf="isReportCampaignsLoaded"
            [value]="reportCampaigns"
            sortField="cost"
            [sortOrder]="-1"
            sortMode="single"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th pSortableColumn="cost" class="text-right">Cost <p-sortIcon field="cost"/></th>
                    <th pSortableColumn="conversions_tracked" class="text-right">Conversions <p-sortIcon field="conversions_tracked"/></th>
                    <th pSortableColumn="clicks" class="text-right">Clicks <p-sortIcon field="clicks"/></th>
                    <th pSortableColumn="views" class="text-right">Views <p-sortIcon field="views"/></th>
                    <th pSortableColumn="profit" class="text-right">Profit <p-sortIcon field="profit"/></th>
                    <th pSortableColumn="revenue" class="text-right">Revenue <p-sortIcon field="revenue"/></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-campaign>
                <tr [routerLink]="['/ad/' + campaign.account.id + '/account/' + campaign.campaign.id + '/adgroup']">
                    <td>{{ campaign.campaign.name }}</td>
                    <td class="font-mono">{{ campaign.cost | currency: campaign.account.currency:undefined }}</td>
                    <td class="font-mono">
                        <span class="font-bold" gaConversion [mvolger]="campaign.conversions_tracked" [googleconv]="campaign.conversions" type="mvolger">{{ campaign.conversions_tracked ?? '-' }}</span>/
                        <span class="text-gray" gaConversion [mvolger]="campaign.conversions_tracked" [googleconv]="campaign.conversions" type="google">{{ campaign.conversions ?? '-' }}</span>
                    </td>
                    <td class="font-mono">{{ campaign.clicks }}</td>
                    <td class="font-mono">{{ campaign.views }}</td>
                    <td class="font-mono">{{ campaign.profit | currency: campaign.account.currency:undefined }}</td>
                    <td class="font-mono">{{ campaign.revenue | currency: campaign.account.currency:undefined }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="15" class="mv-table-empty">
                        <i class="material-symbols-outlined mv-table-empty__icon">search_off</i>
                        <div class="mv-table-empty__label">No Results</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</mv-card>