<ga-create-report-dialog
    #dialog
    [(open)]="createReportIsOpen"
    (submitted)="fetch()"
    (generating)="fetch()"
>
</ga-create-report-dialog>

<mv-card [table]="true">
    <div header>
        <div class="flex items-center justify-between">
            <div class="flex -my-5">
                <div class="flex items-center mr-4">
                    <mv-title [size]="3">Reports</mv-title>
                </div>
                <form class="flex items-center" *ngIf="filterForm" [formGroup]="filterForm">
                    <div class="mr-3">
                        <mv-input>
                            <div button>
                                <div class="mv-input-button">
                                    <div class="material-symbols-outlined">search</div>
                                </div>
                            </div>
                            <input type="text" formControlName="search" placeholder="Search" class="mv-input-bare">
                        </mv-input>
                    </div>
                    <div class="mr-3">
                        <p-dropdown styleClass="mv-dropdown" optionLabel="name" optionValue="id" [options]="users" placeholder="User" [showClear]="true" formControlName="user"></p-dropdown>
                    </div>
                    <div class="mr-3">
                        <p-dropdown styleClass="mv-dropdown" optionLabel="label" optionValue="value" [options]="types" placeholder="Type" [showClear]="true" formControlName="type"></p-dropdown>
                    </div>         
                </form>
            </div>
            <div>
                <mv-button (click)="new()" variant="primary" icon="add" label="New"></mv-button>
            </div>
        </div>
    </div>
    <div body>
        <p-table 
        *ngIf="reports"
        [value]="reports.results"
        [lazy]="true"
        [rows]="limit"
        [paginator]="true"
        [totalRecords]="reports.count"
        (onLazyLoad)="paginate($event)"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Date Range</th>
                    <th>Filters</th>
                    <th>By</th>
                    <th>Status</th>
                    <th>Generated At</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-report>
                <tr>
                    <td><a [routerLink]="['/reports/report/' + report.id]">({{ report.report_campaign_count }}) {{ report.name }}</a></td>
                    <td><span class="font-bold ga-report-type" [class.ga-report-type-custom]="report.type !== 0">{{ report.type | reportType }}</span></td>
                    <td>{{ report.range_from  | date: 'd/M'}} - {{ report.range_to | date: 'd/M'}}</td>
                    <td>{{ report.type === 0 ? 'Not applicable' : '' }}</td>
                    <td>{{ report.user.name }}</td>
                    <td><span [class]="'font-bold ga-report-status ga-report-status-' + (report.status | reportStatus | lowercase)" >{{ report.status | reportStatus }}</span></td>
                    <td>{{ report.generated_at | date }}</td>
                    <td class="w-4 text-right">
                        <div class="flex -m-1 text-right">
                            <mv-button class="-mr-3" icon="folder_copy" variant="positive-transparent" label="Duplicate" (click)="duplicate(report.id)"></mv-button>
                            <mv-button icon="delete" variant="danger-transparent" label="Delete" (click)="delete(report.id)"></mv-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="15" class="mv-table-empty">
                        <i class="material-symbols-outlined mv-table-empty__icon">explore_off</i>
                        <div class="mv-table-empty__label">No Results found</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</mv-card>