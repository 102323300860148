import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/lib/backend.service';
import { Observable } from 'rxjs';
import { ISecret } from '../interfaces/isecret';

@Injectable({
  providedIn: 'root'
})
export class SecretService {

  constructor(private backend: BackendService) { }

  public generateSecret(name: string): Observable<ISecret> {
    return this.backend.sendPost("/api/v1/secret/generate", { name: name });
  }

  public deleteSecret(secret: ISecret): Observable<ISecret> {
    return this.backend.sendDelete("/api/v1/secret/" + secret.key + "/delete");
  }

  public getSecrets(): Observable<Array<ISecret>> {
    return this.backend.getAll("/api/v1/secret/list");
  }

}
