import { Account, IAccount } from "src/app/account/interfaces/account";
import { AccountCpmValueParser, AdCtrValueParser, AdjustedRoiGraphValueParser, CVRValueParser, GraphValueParser, LanderCtrValueParser, SyncFailedValueParser, SyncSuccessRatioParser, SyncSuccessValueParser } from "../graph-value-parser";

export interface IGraphFormatter {

  format(): string;
  min(): number;
  max(): number;

}

export class GraphRoiFormatterBuilder implements IGraphFormatter {
  private value: any;

  constructor(value: any) {
    this.value = value.roi;
  }

  format(): string {
    return Math.round(this.value) + '%';
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }
}

export class GraphFillFormatterBuilder implements IGraphFormatter {
  private account: IAccount;
  private value: any;

  constructor(value: any, account: IAccount) {
    this.value = value;
    this.account = account;
  }

  public format(): string {
    return '\n' + Account.getCurrencyCode(this.account) + this.value.cost + '\n' + Account.getCurrencyCode(this.account) + this.value.budget;
  }

  public max(): number {
    return 60
  }

  public min(): number {
      return -50;
  }

}


export class AdjustedGraphRoiFormatterBuilder implements IGraphFormatter {

  private value!: any;
  private parser!: AdjustedRoiGraphValueParser;

  constructor(value: any, parser: AdjustedRoiGraphValueParser) {
    this.value = value;
    this.parser = parser;
  }

  public format(): string {
    return Math.round(this.parser.reverse(this.value)) + '%';
  }

  max(): number {
      return 100;
  }

  min(): number {
      return 0;
  }

}

export class AdjustedCPMFormatter implements IGraphFormatter {
  
  private value!: any;
  private parser!: AccountCpmValueParser;
  private account: IAccount;

  constructor(value: any, parser: AccountCpmValueParser, account: IAccount) {
    this.value = value;
    this.parser = parser;
    this.account = account;
  }

  public format(): string {
    return Math.round(this.parser.reverse(this.value)) + Account.getCurrencyCode(this.account);
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }

}

export class AdjustedAdCtrFormatter implements IGraphFormatter {
  private value!: any;
  private parser!: AdCtrValueParser;

  constructor(value: any, parser: AdCtrValueParser) {
    this.value = value;
    this.parser = parser;
  }

  public format(): string {
    return Math.round(this.value) + '%';
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }

}

export class AdjustedLanderCTRFormatter implements IGraphFormatter {
  private value!: any;
  private parser!: LanderCtrValueParser;

  constructor(value: any, parser: LanderCtrValueParser) {
    this.value = value;
    this.parser = parser;
  }

  public format(): string {
    return Math.round(this.value * 100) + '%';
  }

  max(): number {
    return 100
  }

  min(): number {
      return 0;
  }
}

export class AdjustedCVRFormatter implements IGraphFormatter {
  private value!: any;
  private parser!: CVRValueParser;

  constructor(value: any, parser: CVRValueParser) {
    this.value = value;
    this.parser = parser;
  }

  public format(): string {
    return Math.round(this.value * 100) + '%';
  }

  max(): number {
    return 1
  }

  min(): number {
      return 0;
  }
}


/**
 * 
 */
export class SyncSuccessValueFormatter implements IGraphFormatter {
  private value!: any;
  private parser!: SyncSuccessValueParser;

  constructor(value: any, parser: SyncSuccessValueParser) {
    this.value = value;
    this.parser = parser;
  }

  min(): number {
    return 0;
  }

  max(): number {
    return 1500;
  }

  format(): string {
    return this.value;
  }

}

/**
 * 
 */
export class SyncFailedValueFormatter implements IGraphFormatter {
  private value!: any;
  private parser!: SyncFailedValueParser;

  constructor(value: any, parser: SyncFailedValueParser) {
    this.value = value;
    this.parser = parser;
  }

  min(): number {
    return 0;
  }

  max(): number {
    return 1500;
  }

  format(): string {
    return this.value;
  }

}

/**
 * 
 */
export class SyncFailedActiveAccountValueFormatter implements IGraphFormatter {
  private value!: any;
  private parser!: SyncFailedValueParser;

  constructor(value: any, parser: SyncFailedValueParser) {
    this.value = value;
    this.parser = parser;
  }

  min(): number {
    return 0;
  }

  max(): number {
    return 1500;
  }

  format(): string {
    return this.value;
  }

}

/**
 * 
 */
export class SyncSuccessRatioFormatter implements IGraphFormatter {

  private value!: any;
  private parser!: SyncSuccessRatioParser;

  constructor(value: any, parser: SyncFailedValueParser) {
    this.value = value;
    this.parser = parser;
  }

  min(): number {
    return 0;
  }
  
  max(): number {
    return 100;
  }

  format(): string {
    return (this.value * 100).toFixed(2) + "%";
  }

}