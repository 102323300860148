/// <reference types="@angular/localize" />

import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
import localeEnBe from '@angular/common/locales/en-BE';
import { GanalComponent } from './app/ganal.component';
import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { bootstrapApplication } from '@angular/platform-browser';
import { serverErrorInterceptor } from './app/lib/interceptors/server-error.interceptor';
import { NotFoundInterceptor } from './app/lib/interceptors/not-found-interceptor';
import { ForbiddenInterceptor } from './app/lib/interceptors/forbidden-interceptor';
import { SessionInterceptor } from './app/lib/interceptors/session-interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app/ganal.routes';
import { MessageService } from 'primeng/api';

if (environment.production) {

  enableProdMode();
}


registerLocaleData(localeEnBe)

bootstrapApplication(GanalComponent, {
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NotFoundInterceptor, multi: true },

        provideHttpClient(withInterceptors([
          serverErrorInterceptor
        ])),
        provideHttpClient(withInterceptorsFromDi()),
        provideNoopAnimations(),
        provideRouter(routes, withComponentInputBinding())
    ]
})
  .catch(err => console.error(err));
