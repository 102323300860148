<div class="flex">
    <mv-card class="ad-totals__sidepane mr-4">
        <div header>
            <mv-title [size]="3">Range Selector</mv-title>   
        </div>
        <div body>
            <div class="ad-totals__range-card">
                <ng-content></ng-content>
            </div>
        </div>
    </mv-card>
    <mv-card class="w-full" [hasHeader]="false">
        <div body>
            <div class="flex justify-between">
                <ng-container *ngFor="let range of ranges">
                    <div *ngIf="range">
                        <div class="ad-totals__range">
                            {{ range | rangeLabel }}
                        </div>
                        <div class="ad-totals__grid">
                            <div>
                                <div class="ad-totals__number">{{ total['cost_range_' + range] |
                                    currency: account.currency:undefined:'1.0-0' }}</div>
                                <div class="ad-totals__label">Cost</div>
                            </div>
                            <div>
                                <div class="ad-totals__number">
                                    {{ total['revenue_range_' + range] | currency: account.currency:undefined:'1.0-0'}}
                                </div>
                                <div class="ad-totals__label">Revenue</div>
                            </div>
                            <div>
                                <div class="ad-totals__number ga-totals-green">
                                    {{ total['profit_range_' + range] | currency: account.currency:undefined:'1.0-0'}}
                                </div>
                                <div class="ad-totals__label ga-totals-green-light">Profit</div>
                            </div>
                            <div>
                                <div class="ad-totals__number">
                                    {{ total['roi_range_' + range ] | percent: '1.0' }}
                                </div>
                                <div class="ad-totals__label">ROI</div>
                            </div>
                            <div>
                                <div class="ad-totals__number ga-totals-yellow">
                                    {{ total['cpm_range_' + range] | currency: account.currency:undefined:'1.0-2' }}
                                </div>
                                <div class="ad-totals__label ga-totals-yellow-light ga-totals-red-light">CPM</div>
                            </div>
                            <div>
                                <div class="ad-totals__number ga-totals-red">
                                    {{ total['ad_ctr_range_' + range] | percent: '1.2' }}
                                </div>
                                <div class="ad-totals__label ga-totals-red-light">Ad CTR</div>
                            </div>
                            <div>
                                <div class="ad-totals__number ga-totals-blue">{{ total['conversions_tracked_range_' + range] ?? '-' }}</div>
                                <div class="ad-totals__label ga-totals-blue-light">Conv</div>
                            </div>
                            <div>
                                <div class="ad-totals__number">{{ total['ltr_range_' + range] | percent: '1.2' }}</div>
                                <div class="ad-totals__label">LCTR</div>
                            </div>
                            <div>
                                <div class="ad-totals__number">{{ total['cvr_range_' + range] | percent }}</div>
                                <div class="ad-totals__label">CVR</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

    </mv-card>
</div>