<div class="ga-template-form">
    <mv-card>
        <div header>
            <mv-title [size]="3">Templates</mv-title>
        </div>
        <div body>
            <form [formGroup]="templateForm" (submit)="createTemplate()">
                <mv-input label="Name">
                    <input type="text" formControlName="name" mvInput>
                    <mv-validation [control]="name" error="required" message="Give a name to your template"></mv-validation>
                </mv-input>
                
                <div class="mt-3"></div>
                <mv-button variant="secondary" icon="add" label="Add Range" (click)="addRange()"></mv-button>
                <div class="my-4">
                    <div formArrayName="ranges">
                        <div *ngFor="let range of ranges.controls; let i = index" class="mt-3">
                            <form [formGroupName]="i">
                                <div class="ga-template-range">
                                    <div class="ga-template-range-form">
                                        <mv-input [label]="'Range ' + (i + 1) + ' Name'">
                                            <input type="text" placeholder="Range name" formControlName="name" mvInput>
                                            <mv-validation [control]="getRangeName(i)" error="required" message="Should name this range"> </mv-validation>
                                        </mv-input>
                                        
                                        <div class="h-2"></div>
                                        <mv-input [label]="'Range ' + (i + 1) + ' dates'">
                                            <mv-daterangepicker (dates)="dates($event, i)" [date]="standardDateRange"></mv-daterangepicker>
                                            <mv-validation [control]="getRangeFrom(i)" error="required" message="Should have starting date in range"></mv-validation>
                                            <mv-validation [control]="getRangeTo(i)" error="required" message="Should have ending date in range"></mv-validation>
                                        </mv-input>
                                    </div>
                                    <div class="ga-template-range-actions">
                                        <mv-button icon="close" (click)="removeRange(i)"></mv-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <mv-button [loading]="isCreatingTemplate" label="Create Template" variant="primary" type="submit"></mv-button>
            </form>
        </div>
    </mv-card>
</div>

