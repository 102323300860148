import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CardComponent } from 'src/app/mavejs/base/card/card.component';
import { ButtonComponent } from 'src/app/mavejs/form/button/button.component';

@Component({
  selector: 'ganal-error500',
  standalone: true,
  imports: [CardComponent, ButtonComponent],
  templateUrl: './error500.component.html',
  styleUrl: './error500.component.scss'
})
export class Error500Component {


  constructor(private location: Location) {}


  back() {
    this.location.back();
  }

}
