import { Component } from '@angular/core';
import { ButtonComponent } from 'src/app/mavejs/form/button/button.component';
import { Location } from '@angular/common';

@Component({
  selector: 'ganal-error403',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './error403.component.html',
  styleUrl: './error403.component.scss'
})
export class Error403Component {


  constructor(private location: Location) {}


  back() {
    this.location.back();
  }

}
