import { Component, OnInit } from '@angular/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CardComponent } from '../../../lib/ui/card/card.component';
import { TitleComponent } from '../../../lib/ui/title/title.component';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../../lib/ui/button/button.component';
import { Templatev4Service } from '../../services/templatev4.service';
import { ITemplate } from '../../classes/template';
import { ConfirmationService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';


@Component({
    selector: 'ga-index-template-view',
    standalone: true,
    templateUrl: './index-template-view.component.html',
    styleUrl: './index-template-view.component.css',
    imports: [NgIf, NgFor, NgClass, CardComponent, TitleComponent, ButtonComponent, RouterLink, TooltipModule]
})
export class IndexTemplateViewComponent implements OnInit {

  templates!: Array<ITemplate>;
  defaultTemplateId!: number;

  constructor(private templatev4: Templatev4Service, private confirmation: ConfirmationService) {}

  ngOnInit(): void {
    this.defaultTemplateId = this.templatev4.getDefaultTemplateId();
    this.templatev4.getTemplates().subscribe((templates: Array<ITemplate>) => {
      this.templates = templates;
    });
  }

  delete(templateId: number) {
    this.confirmation.confirm({
      header: 'Delete template',
      icon: 'delete',
      key: 'delete',
      message: 'Do you really want to delete this template?',
      accept: () => this.templatev4.deleteTemplate(templateId).subscribe(() => {
        this.templates = this.templates.filter(item => item.id !== templateId)
      })
    })
  }

  default(templateId: number) {
    this.templatev4.setDefaultTemplate(templateId);
    this.defaultTemplateId = this.templatev4.getDefaultTemplateId();
  }

}
