import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Accountv4Service } from '../../services/accountv4.service';
import { Account, IAccount } from '../../interfaces/account';
import { Router, RouterLink } from '@angular/router';
import { ISortEvent } from 'src/app/mavejs/data/datatable/datatable.component';
import { DeleteModalComponent } from 'src/app/mavejs/ui/delete-modal/delete-modal.component';

import { TableModule } from 'primeng/table';
import { TitleComponent } from "../../../lib/ui/title/title.component";
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';

@Component({
    selector: 'ga-index-account-view',
    standalone: true,
    templateUrl: './index-account-view.component.html',
    styleUrl: './index-account-view.component.css',
    imports: [CurrencyPipe, DatePipe, RouterLink, TableModule, CardComponent, ButtonComponent, TitleComponent]
})
export class IndexAccountViewComponent {
  @ViewChild('deleteModal') deleteModal!: DeleteModalComponent;

  accountv4!: Accountv4Service;
  accounts!: Array<IAccount>
  selectedAccount: IAccount = Account.noop();
  accountHeaders = [
    { value: 'name', label: 'Name', placeRight: false, sortable: false },
    { value: 'mccId', label: 'Mcc ID', placeRight: false, sortable: false },
    { value: 'customerId', label: 'Customer ID', placeRight: false, sortable: false },
    { value: 'lastStatusChange', label: 'Last Status Change', placeRight: false, sortable: false },
    { value: '', label: '', placeRight: false, sortable: false },
  ]

  sortBy: string = "name"

  isDeleteModalOpen = false;
  refreshAccountIsLoading = false;
  isAccountsLoading = true;

  constructor(private router: Router, accountv4: Accountv4Service) {
    this.accountv4 = accountv4;
    this.router = router;
  }

  ngOnInit(): void {
    this.getAccounts();
  }

  getAccounts() {
    this.isAccountsLoading = true;
    this.accountv4.getAccounts().subscribe((accounts: any) => {
      this.accounts = accounts;
      this.isAccountsLoading = false;
    });
  }

  sort(value: ISortEvent) {
    this.sortBy = value.header;
    this.getAccounts()
  }

  superAdminAccount(account: IAccount) {
    this.router.navigateByUrl("/account/" + account.id + "/superadmin")
  }

  openDeleteAccountModal(account: IAccount) {
    this.selectedAccount = account;
    this.deleteModal.openModal();
  }

  // deleteAccount(account: IAccount) {
  //   this.accountService.deleteAccount(account)
  //     .subscribe(() => {
  //       this.accountRequest.doRequest();
  //     })

  //   this.deleteModal.closeModal();
  // }
}
