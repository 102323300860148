import { Component, OnInit } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { Statusv4Service } from '../../services/statusv4.service';
import { IStatus } from '../../interfaces/status-change';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { OperationPipe } from '../../pipes/operation.pipe';
import { TypePipe } from '../../pipes/type.pipe';
import { StatusValuePipe } from '../../pipes/status-value.pipe';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { CalendarModule } from 'primeng/calendar';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Accountv4Service } from 'src/app/account/services/accountv4.service';
import { IAccount } from 'src/app/account/interfaces/account';
import { IUser } from 'src/app/auth/classes/user';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { StatePipe } from '../../pipes/state.pipe';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { NotifyBadgeComponent } from 'src/app/mavejs/base/notify-badge/notify-badge.component';
import { RouterLink } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Automationv4Service } from '../../services/automationv4.service';
import { ILoadingStateArray } from 'src/app/lib/classes/state';

@Component({
  selector: 'ga-index-status-view',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    TableModule,
    DatePipe,
    OperationPipe,
    TypePipe,
    StatusValuePipe,
    ButtonComponent,
    TitleComponent,
    CalendarModule,
    DropdownModule,
    TooltipModule,
    NotifyBadgeComponent,
    StatePipe,
    RouterLink
  ],
  templateUrl: './index-status-view.component.html',
  styleUrl: './index-status-view.component.css'
})
export class IndexStatusViewComponent implements OnInit {

  statusAccounts!: IStatusAccount[]
  dailyStatuses!: IStatus[]
  accounts!: IAccount[]
  users!: IUser[]

  filterForm: FormGroup = this.fb.group({
    user: [''],
    account: [''],
    date: [new Date(), Validators.required]
  });

  selectedAutomations: any = {};

  isCopyingCsv: ILoadingStateArray = {};
  isVerifyingCsv: ILoadingStateArray = {};

  constructor(
    private statusv4: Statusv4Service,
    private confirmation: ConfirmationService,
    private accountv4: Accountv4Service,
    private automationv4: Automationv4Service,
    private fb: FormBuilder,
    private userv4: Userv4Service,
    private clipboard: Clipboard,
    private toast: MessageService
  ) { }

  ngOnInit(): void {
    this.statusv4.daily(
      this.filterForm.get('user')?.value,
      this.filterForm.get('account')?.value,
      this.filterForm.get('date')?.value
    ).subscribe((statuses: IStatus[]) => {
      this.dailyStatuses = statuses;
    })
    
    this.filterForm.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => {
      this.statusv4.daily(
        this.filterForm.get('user')?.value,
        this.filterForm.get('account')?.value,
        this.filterForm.get('date')?.value
      ).subscribe((statuses: IStatus[]) => {
        this.dailyStatuses = statuses;
      })
    })

    this.userv4.self().subscribe((user: IUser) => {    
      this.userv4.all().subscribe((users: IUser[]) => {
        this.users = users;
      });

      this.accountv4.getAccounts().subscribe((accounts: IAccount[]) => {
        this.accounts = accounts;
      })

      this.statusv4.all().subscribe((statuses: IStatus[]) => {
        this.statusAccounts = this.rearrangeStatusses(statuses)
      })
    })
  }

  /**
   * 
   * @param statusChangeId 
   */
  delete(statusChangeId: number) {
    this.confirmation.confirm({
      header: 'Delete status change',
      icon: 'delete',
      key: 'delete',
      message: 'Do you really want to delete this status change?',
      accept: () => this.statusv4.delete(statusChangeId).subscribe(() => {
        this.statusv4.all().subscribe((statuses: IStatus[]) => {
          this.statusAccounts = this.rearrangeStatusses(statuses)
        })
        this.statusv4.daily(
          this.filterForm.get('user')?.value,
          this.filterForm.get('account')?.value,
          this.filterForm.get('date')?.value
        ).subscribe((statuses: IStatus[]) => {
          this.dailyStatuses = statuses;
          this.isVerifyingCsv[this.filterForm.get('account')?.value] = false;
        })
      })
    })
  }

  /**
   * 
   * @param account 
   */
  deleteAll(account: IStatusAccount) {
    this.confirmation.confirm({
      header: 'Delete all status changes for ' + account.name,
      icon: 'delete',
      key: 'delete',
      message: 'Do you really want to delete all these status changes?',
      accept: () => this.statusv4.deleteAll(account.id).subscribe(() => {
        this.statusv4.all().subscribe((statuses: IStatus[]) => {
          this.statusAccounts = this.rearrangeStatusses(statuses)
        })
      })
    })
  }

  /**
   * 
   * @param statusChangeId 
   */
  retry(statusChangeId: number) {
    this.confirmation.confirm({
      header: 'Retry status change',
      icon: 'refresh',
      key: 'positive',
      message: 'Do you really want to retry this status change?',
      accept: () => this.statusv4.retry(statusChangeId).subscribe(() => {
        this.statusv4.all().subscribe((statuses: IStatus[]) => {
          this.statusAccounts = this.rearrangeStatusses(statuses)
        })
      })
    })
  }

  /**
   * 
   * @param accountId 
   */
  retryAll(accountId: number) {
    this.confirmation.confirm({
      header: 'Retry status changes',
      icon: 'refresh',
      key: 'positive',
      message: 'Do you really want to retry all status changes in this account',
      accept: () => this.statusv4.retryAllInAccount(accountId).subscribe(() => {
        this.statusv4.all().subscribe((statuses: IStatus[]) => {
          this.statusAccounts = this.rearrangeStatusses(statuses)
        })
      })
    })
  }

  /**
   * @deprecated This should be removed when moved to single table layout
   * @param statuses 
   * @returns 
   */
  rearrangeStatusses(statuses: IStatus[]): IStatusAccount[] {
    let statusAccounts: IStatusAccount[] = []

    for (let status of statuses) {
      let statusAccountIndex = statusAccounts.findIndex((item: IStatusAccount) => item.id === status.account.id);
      let statusAccount = null;

      if (statusAccountIndex === -1) {
        statusAccounts.push({
          id: status.account.id,
          name: status.account.name,
          statuses: []
        })

        statusAccountIndex = statusAccounts.findIndex((item: IStatusAccount) => item.id === status.account.id);
        statusAccount = statusAccounts[statusAccountIndex]
      } else {
        statusAccount = statusAccounts[statusAccountIndex]
      }

      statusAccount.statuses.push(status)
    }

    return statusAccounts;
  }

  /**
   * 
   * @param accountId 
   * @returns 
   */
  hasCsv(accountId: number) {
    let status = this.statusAccounts.find((account: IStatusAccount) => account.id === accountId);

    return status?.statuses.findIndex((status: IStatus) => status.is_csv === true) !== -1;
  }

  /**
   * 
   * @param accountId 
   */
  copy(accountId: number) {
    this.isCopyingCsv[accountId] = true;
    this.statusv4.copy(accountId).subscribe((response: string) => {
      this.clipboard.copy(response);
      this.toast.add({
        severity: 'info',
        summary: 'Csv Changes copied',
        detail: 'Go ahead and paste them in the Google Ads Editor'
      })
      this.isCopyingCsv[accountId] = false;
    });
  }

  /**
   * 
   * @param accountId 
   */
  verify(accountId: number) {
    this.isVerifyingCsv[accountId] = true;
    this.statusv4.verify(accountId).subscribe(() => {
      this.statusv4.all().subscribe((statuses: IStatus[]) => {
        this.statusAccounts = this.rearrangeStatusses(statuses)
        this.statusv4.daily(
          this.filterForm.get('user')?.value,
          this.filterForm.get('account')?.value,
          this.filterForm.get('date')?.value
        ).subscribe((statuses: IStatus[]) => {
          this.dailyStatuses = statuses;
          this.isVerifyingCsv[accountId] = false;
        })
      })
    });
  }

  /**
   * 
   * @param accountId 
   * @param statusChangeId 
   */
  select(accountId: number, statusChangeId: number) {
    if (this.selectedAutomations[accountId] === undefined) {
      this.selectedAutomations[accountId] = []
    }

    let statusChangeExists = this.selectedAutomations[accountId].includes(statusChangeId);

    if (statusChangeExists) {
      this.selectedAutomations[accountId] = this.selectedAutomations[accountId].filter((statusChange: number) => statusChange !== statusChangeId)
    } else {
      this.selectedAutomations[accountId].push(statusChangeId)
    }    
  }

  /**
   * 
   * @param accountId 
   */
  execute(accountId: number) {
    this.automationv4.execute({
      account_id: accountId,
      statuses: this.selectedAutomations[accountId]
    }).subscribe(() => {
      this.statusv4.all().subscribe((statuses: IStatus[]) => {
        this.statusAccounts = this.rearrangeStatusses(statuses)
      })
    })
  }

}

export interface IStatusAccount {
  id: number;
  name: string;
  statuses: IStatus[];
}
