import { CurrencyPipe, DatePipe, NgFor, NgIf, PercentPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { IUser } from 'src/app/auth/classes/user';
import { ConversionDirective } from 'src/app/lib/directives/conversion.directive';
import { LimitTrailingPipe } from 'src/app/lib/pipes/limit-trailing.pipe';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { IReport } from 'src/app/reports/classes/report';
import { Reportsv4Service } from 'src/app/reports/services/reportsv4.service';
import { Userv4Service } from 'src/app/user/services/userv4.service';

@Component({
  selector: 'ga-index-daily-reports-view',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ReactiveFormsModule,
    CardComponent,
    DropdownModule,
    CalendarModule,
    TitleComponent,
    TableModule,
    CurrencyPipe,
    PercentPipe,
    RouterLink,
    DatePipe,
    LimitTrailingPipe
  ],
  templateUrl: './index-daily-reports-view.component.html',
  styleUrl: './index-daily-reports-view.component.css'
})
export class IndexDailyReportsViewComponent implements OnInit {

  users!: IUser[];
  reportFilterForm!: FormGroup;
  reports!: IReport[];

  isLoaded: boolean = false;

  constructor(private fb: FormBuilder, private userv4: Userv4Service, private reportv4: Reportsv4Service) {}

  ngOnInit(): void {
    this.userv4.all().subscribe((users: IUser[]) => {
      this.users = users;
    });

    this.userv4.self().subscribe((user: IUser) => {
      this.reportFilterForm = this.fb.group({
        'user': [user.id],
        'date': [new Date()]
      })

      this.reportv4.daily(
        this.reportFilterForm.get('user')?.value, 
        this.reportFilterForm.get('date')?.value
      ).subscribe((reports: IReport[]) => {
        this.reports = reports;
        this.isLoaded = true
      })

      this.reportFilterForm.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => {
        this.isLoaded = false
        this.reportv4.daily(
          this.reportFilterForm.get('user')?.value, 
          this.reportFilterForm.get('date')?.value
        ).subscribe((reports: IReport[]) => {
          this.reports = reports;
          this.isLoaded = true
        })
      })
    })   

    
  }

}
