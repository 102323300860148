<div class="ga-account-authorize">
    <mv-card>
        <div header>
            <mv-title [size]="3">Connect with MCC</mv-title>
        </div>
        <div body>
            <div class="flex flex-col items-center justify-center h-48" *ngIf="!hasCode">
                <!-- <div class="mb-6 text-black font-bold text-xl">Please click authorize to start adding google accounts
                </div> -->

                <div>Redirecting to google ...</div>
                <p-progressBar mode="indeterminate" [style]="{ height: '3px', width: '18rem', 'margin-top': '1rem' }" />

                <!-- <mv-button icon="security" label="Authorize" (click)="authorize()"></mv-button> -->
            </div>
            <div *ngIf="hasCode && accounts.length === 0">
                <mv-input label="MCC ID" class="w-full">
                    <p-inputMask styleClass="w-full" type="text" [(ngModel)]="mcc" placeholder="123-456-7890" mask="999-999-9999"/>
                </mv-input>
                <div class="h-2"></div>
                <mv-button icon="manage_accounts" label="Load Accounts" (click)="load()"></mv-button>
            </div>

            <div *ngIf="accounts.length > 0 && !isCreatingAccounts">
                <span class="text-black font-bold text-sm">Accounts</span>
                <div class="ga-account-authorize__accounts">
                    <div *ngFor="let account of accounts; let i = index" class="ga-account-authorize__account-item">
                        {{ account.name }}
                        <span class="ga-account-authorize__badge" *ngIf="account.exists">Active</span>
                        <p-checkbox *ngIf="!account.exists" [binary]="true" [(ngModel)]="accounts[i].selected"></p-checkbox>
                    </div>                
                </div>
                <div class="flex justify-between mt-3">
                    <mv-button variant="primary" label="Create Accounts" (click)="create()"></mv-button>
                    <mv-button variant="secondary" label="Cancel"></mv-button>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center h-48" *ngIf="isCreatingAccounts">
                <div>Creating and syncing accounts ({{ accounts.length }}) ...</div>
                <p-progressBar mode="indeterminate" [style]="{ height: '3px', width: '18rem', 'margin-top': '1rem' }" />
            </div>
        </div>
    </mv-card>
</div>