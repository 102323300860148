import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAccount } from 'src/app/account/interfaces/account';
import { ICampaignData } from 'src/app/campaign/interfaces/campaign';
import { DateRange } from 'src/app/lib/classes/daterange';
import { CurrencyPipe, DecimalPipe, NgFor, NgIf, PercentPipe } from '@angular/common';
import { IRange } from 'src/app/lib/classes/ranges';
import { costDistribution, estimateRoi, IIncome } from '../../classes/demographics/demographics';
import { Incomev4Service } from 'src/app/adgroup/services/incomev4.service';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConversionDirective } from 'src/app/lib/directives/conversion.directive';
import { GradeDirective } from 'src/app/adgroup/directives/grade.directive';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { RangeLabelPipe } from '../../pipes/range-label.pipe';
import { IStatus, IStatusChangeType, IStatusHistory, IStatusPayload } from 'src/app/status/interfaces/status-change';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { MessageService } from 'primeng/api';
import { SyncComponent } from 'src/app/lib/ui/sync/sync.component';

@Component({
  selector: 'ga-incomes-table',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    CardComponent,
    TableModule,
    CurrencyPipe,
    InputSwitchModule,
    PercentPipe,
    DecimalPipe,
    ConversionDirective,
    GradeDirective,
    TitleComponent,
    RangeLabelPipe,
    SyncComponent
  ],
  templateUrl: './incomes-table.component.html',
  styleUrl: './incomes-table.component.css'
})
export class IncomesTableComponent {
  @Input() campaignId!: number;
  @Input() adGroupId!: number;
  @Input() account!: IAccount;
  @Input() ranges!: Array<DateRange>
  @Input() data!: Array<ICampaignData>

  @Output() rangesChange = new EventEmitter<Array<IRange>>();
  @Output() loaded = new EventEmitter<number>();

  incomes: Array<IIncome> = []

  rangeIncomes: Map<String, Map<number, IIncome>> = new Map()
  incomesById = new Map();
  incomeIds: Array<number> = [];

  incomeChanges: Array<IStatusHistory> = [];
  isIncomesChangesLoaded = false;
  loadedCount = 0;

  constructor(private incomev4: Incomev4Service, private statusv4: Statusv4Service, private message: MessageService) {}

  ngOnInit(): void {
   this.load(this.ranges);
   this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Income).subscribe((changes: Array<IStatusHistory>) => {
    this.incomeChanges = changes;
    this.isIncomesChangesLoaded = true;
  });
  }

  load(ranges: Array<DateRange>) {
    this.isIncomesChangesLoaded = false;
    
    for (let range of ranges) {
      this.incomev4.getIncomes(this.campaignId, range).subscribe((incomes: Array<any>) => {
        let mappedIncomes = new Map()

        for (let income of incomes) {
          if (this.incomesById.has(income.id)) {
            let existingIncome = this.incomesById.get(income.id);

            existingIncome[`roi_range_${range}`] = this.estimateRoi(range.toString(), income.total_cost, income.total_conversions);
            existingIncome[`cost_range_${range}`] = income.total_cost || 0;         
            existingIncome[`profit_range_${range}`] = income.total_profit || 0;    
            existingIncome[`revenue_range_${range}`] = income.total_revenue || 0;   
            existingIncome[`clicks_range_${range}`] = income.total_clicks || 0;     
            existingIncome[`conversions_range_${range}`] = income.total_conversions || 0;
            existingIncome[`conversions_tracked_range_${range}`] = income.total_conversions_tracked || 0;
            existingIncome[`impressions_range_${range}`] = income.total_impressions || 0;
  
            this.incomesById.set(income.id, existingIncome);
          } else {
            let newIncome = { ...income }; 

            newIncome[`roi_range_${range}`] = this.estimateRoi(range.toString(), income.total_cost, income.total_conversions);
            newIncome[`cost_range_${range}`] = income.total_cost || 0;
            newIncome[`profit_range_${range}`] = income.total_profit || 0;
            newIncome[`revenue_range_${range}`] = income.total_revenue || 0;
            newIncome[`clicks_range_${range}`] = income.total_clicks || 0;
            newIncome[`conversions_range_${range}`] = income.total_conversions || 0;
            newIncome[`conversions_tracked_range_${range}`] = income.total_conversions_tracked || 0;
            newIncome[`impressions_range_${range}`] = income.total_impressions || 0;

            this.incomesById.set(income.id, newIncome);
            this.incomeIds.push(income.id);
          }

          mappedIncomes.set(income.id, income)
        }

        this.rangeIncomes.set(range.toString(), mappedIncomes);

        this.loadedCount++;
        this.loaded.emit(this.loadedCount);

        if (this.loadedCount === ranges.length) {
          this.incomes = Array.from(this.incomesById.values());  
          this.isIncomesChangesLoaded = true;
        }
      })
    }
  }

  estimateRoi(range: string, cost: number, conversions: number): number | null {
    let metrics = this.data.find((item: ICampaignData) => item.range_key === range);
    let roi = estimateRoi(metrics?.revenue ?? 0, cost, metrics?.conversions ?? 0, conversions)

    return roi
  }

  costDistribution(range: string, cost: number) {
    let metrics = this.data.find((item: ICampaignData) => item.range_key === range);

    return costDistribution(metrics?.cost ?? 0, cost)
  }

  changeStatus(income: IIncome) {
    let payload: IStatusPayload = {
      id: income.id,
      account_id: this.account.id,
      campaign_id: this.campaignId,
      ad_group_id: this.adGroupId,
      type: IStatusChangeType.Income
    }

    this.statusv4.apply(payload).subscribe({
      complete: () => {
        this.statusv4.getActiveHistory(this.account.id, IStatusChangeType.Income).subscribe((changes: Array<IStatusHistory>) => {
          this.incomeChanges = changes;
        })
      },
      error: () => this.message.add({
        severity: 'error',
        summary: 'Could not change status'
      })
    })
  }

  hasStatus(income: IIncome) {
    if (income === undefined) {
      return false;
    }

    return this.incomeChanges.find(item => item.entity_id === income.id) !== undefined
  }
}
