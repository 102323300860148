export interface IAutomationExecute {
  account_id: number;
  statuses: Array<number>;
}

export interface IStatus {
  disabled: boolean;
  enabled: boolean;
  modified: boolean;
  newStatus: string;
}

export class Status {
  static noop() {
    return {
      disabled: false,
      enabled: false,
      modified: false,
      newStatus: "None"
    }
  }
}
