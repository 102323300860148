<mv-card [table]="true">
  <div header>
    <div class="flex items-center justify-between" [ngClass]="{'ga-ad-table__tv': isTv}">
      <div class="flex items-center">
        <div>
          <mv-title [size]="3">Ads</mv-title>
        </div>
        <div class="ml-6 font-bold" *ngIf="isTv">Dit is een TV campaign, de ad data is niet betrouwbaar!</div>
      </div>
      
      <ga-sync [accountId]="account.id" type="ads"></ga-sync>
    </div>
  </div>
  <div body>
    <div class="flex">
      <div class="w-full border-r border-gray">
        <p-table [value]="ads" [sortField]="'cost_range_ + ranges[0]'" [sortOrder]="-1">
          <ng-template pTemplate="header">

            <tr class="border-b border-gray-light">
              <ng-container *ngFor="let range of ranges; let i = index">
                <th *ngIf="i === 0"></th>
                <th class="text-right border-l border-gray" colspan="6">
                  <span class="text-secondary font-bold capitalize mr-3">{{ range | rangeLabel }}</span>
                  <span class="text-secondary-light font-bold">{{ range.toString() }}</span>
                </th>
              </ng-container>
            </tr>

            <tr>
              <ng-container *ngFor="let range of ranges; let i = index">
                <th *ngIf="i === 0" class="w-96">Name</th>
                <th class="text-right border-l border-gray">Roi</th>
                <th [pSortableColumn]="'cost_range_' + range" class="text-right">Cost <p-sortIcon
                    [field]="'cost_range_' + range" /></th>
                <th [pSortableColumn]="'profit_range_' + range" class="text-right">Profit <p-sortIcon
                      [field]="'profit_range_' + range" /></th>
                <th [pSortableColumn]="'conversions_tracked_range_' + range" class="text-right">Conv <p-sortIcon
                    [field]="'conversions_tracked_range_' + range" /></th>
                <th class="text-right">Ad Ctr</th>
                <th class="text-right">LCtr</th>
              </ng-container>
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-ad>
            <tr>
              <td class="ga-ad-table__firstcol">
                <div class="ga-ad-table__name">
                  <div class="ga-ad-table__controls">
                    <p-inputSwitch styleClass="-my-2 mr-2" [class.red]="ad.status === 2 && hasStatus(ad)"
                      [class.yellow]="ad.status !== 2 && hasStatus(ad)"
                      [ngModel]="ad.status === 2 && !hasStatus(ad) || ad.status !== 2 && hasStatus(ad)"
                      (ngModelChange)="changeStatus(ad)">
                    </p-inputSwitch>
                    <a class="ga-ad-table__video" pTooltip="Watch Video"
                    *ngIf="rangeAds.get(ranges[0].toString())?.get(ad.id)?.video_id"
                    [href]="'https://youtu.be/' + rangeAds.get(ranges[0].toString())?.get(ad.id)?.video_id"
                    target="_blank">
                    <i class="material-symbols-outlined">videocam</i>
                    </a>
                    <a class="ga-ad-table__lander" pTooltip="Go to lander"
                      *ngIf="rangeAds.get(ranges[0].toString())?.get(ad.id)?.lander"
                      [href]="rangeAds.get(ranges[0].toString())?.get(ad.id)?.lander" target="_blank">
                      <i class="material-symbols-outlined">request_quote</i>
                    </a>
                  </div>

                  <p-overlayPanel #videoThumbnailPanel [style]="{ padding: '0px'}" [dismissable]="true">
                    <ng-template pTemplate>
                      <div class="flex">
                        <div class="mr-4">
                          <img class="m-0"
                            [src]="'https://img.youtube.com/vi/' + rangeAds.get(ranges[0].toString())?.get(ad.id)?.video_id + '/mqdefault.jpg'"
                            [alt]="'Video thumbnail for: ' + rangeAds.get(ranges[0].toString())?.get(ad.id)?.video_id" />
                        </div>
                        <div>
                          <div class="mt-1">
                            <div class="font-bold text-sm">Long Headline</div>
                            <div class="text-sm">{{ rangeAds.get(ranges[0].toString())?.get(ad.id)?.long_headline }}
                            </div>
                          </div>
                          <div class="mt-1">
                            <div class="font-bold text-sm">Description</div>
                            <div class="text-sm">{{ rangeAds.get(ranges[0].toString())?.get(ad.id)?.description }}</div>
                          </div>
                          <div class="mt-1">
                            <div class="font-bold text-sm">Headline</div>
                            <div class="text-sm">{{ rangeAds.get(ranges[0].toString())?.get(ad.id)?.headline }}</div>
                          </div>
                          <div class="mt-1">
                            <div class="font-bold text-sm">Call To Action</div>
                            <div class="text-sm">{{ rangeAds.get(ranges[0].toString())?.get(ad.id)?.call_to_action }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="ad.video_id === '' || ad.video_id === null || ad.video_id === undefined">
                        <span class="font-bold text-xs">No Video in this ad</span>
                      </div>
                    </ng-template>
                  </p-overlayPanel>
                  <div (mouseleave)="videoThumbnailPanel.hide()" (mouseenter)="videoThumbnailPanel.show($event)">{{
                    rangeAds.get(ranges[0].toString())?.get(ad.id)?.name | adName }}</div>
                    
                    
                  <div *ngIf="ad.approval_status == 2" class="text-red material-symbols-outlined">close</div>
                </div>
              </td>

              <ng-container *ngFor="let range of ranges; let i = index">
                <td class="font-mono border-l border-gray">
                  <span *ngIf="ad['roi_range_' + range]" gaGrade
                    [roi]="ad['roi_range_' + range]"
                    [conversions]="ad['conversions_tracked_range_' + range]">
                    {{ ad['roi_range_' + range] | percent: '1.0' }}
                  </span>
                  <span *ngIf="!ad['roi_range_' + range]">-%</span>
                </td>
                <td class="font-mono">
                  <div class="flex justify-end">
                    {{ (ad['cost_range_' + range] | currency: account.currency:undefined:'1.0') ?? '-' }} <span class="text-gray-dark mx-1">|</span> <span class="text-gray-dark float-right"> {{ (costDistribution(range.toString(), ad['cost_range_' + range]) | percent:'1.0-0') ?? '-%' }}</span>
                  </div>
                </td>
                <td class="font-mono">{{ (ad['profit_range_' + range] | currency: account.currency:undefined:'1.0') ?? '-' }}</td>
                <td class="font-mono">
                  <ga-conv [entity]="ad" [tracked]="'conversions_tracked_range_' + range" [google]="'conversions_range_' + range"></ga-conv>
                </td>
                <td class="font-mono">
                  {{ ((ad['clicks_range_' + range] / ad['impressions_range_' + range]) | percent: '1.2') ?? '-%'
                  }}
                </td>
                <td class="font-mono">
                  {{ ((ad['clicks_tracked_range_' + range] / ad['views_tracked_range_' + range]) | percent: '1.1') ?? '-%'
                  }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</mv-card>