<div class="w-full md:w-1/3 mx-auto">

  <mave-card [footer]="false">
    <div header>
      <div class="flex justify-between items-center">
        <mave-sub-title text="Templates"></mave-sub-title>

        <div class="-m-2">
          <mave-button icon="summarize" routerLink="/template/create" label="Create Template"></mave-button>
        </div>
      </div>
    </div>

    <div>

      <mave-empty *ngIf="user && templates && templates.length === 0"></mave-empty>

      <div class="-mt-4" *ngIf="user">

        <div *ngFor="let template of templates"
          class="mt-4 bg-whitish active:bg-gray-light transition-all cursor-pointer font-bold text-sm text-secondary p-8 rounded-md">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <div class="w-12">
                <mave-tooltip text="Default template" [hidden]="template.id !== default">
                  <div class="material-symbols-outlined text-primary-light font-bold">done</div>
                </mave-tooltip>
              </div>
              <div>{{ template.name }}</div>
            </div>
            <div class="flex">
              <mave-action [hidden]="template.id === default" icon="delete" label="Delete"
                type="delete" (click)="deleteTemplate(template)"></mave-action>
              <mave-action icon="done_all" label="Set Default" (click)="setTemplateDefault(template)"></mave-action>
            </div>
          </div>
        </div>
      </div>

    </div>

  </mave-card>


</div>
