import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpStatusCode
} from '@angular/common/http';

import { Observable, throwError, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Authv4Service } from '../../auth/services/authv4.service';
import { Router } from '@angular/router';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(private authv4: Authv4Service, private router: Router) { }

  

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authv4.isExpired()) {
      this.authv4.logout();
    }

    if (!this.authv4.hasToken()) {
      console.log('no token')
      return next.handle(req).pipe(
        catchError((error: any) => {
          if (req.method === "OPTIONS") {
            return throwError(() => error);
          }

          if (error.status === HttpStatusCode.InternalServerError) {
            if (this.router.url !== "/error/500") {
              this.router.navigateByUrl("/error/500");
              return throwError(() => error);
            }
          }

          if (error.status === HttpStatusCode.Unauthorized) {
            this.router.navigateByUrl("/user/login");
            return throwError(() => error);
          }

          if (this.router.url !== "/user/login") {
            this.router.navigateByUrl("/user/login");
            return throwError(() => error);
          }

          return throwError(() => error);
        })
      );
    }

    let authReq: any = req.clone({
      headers: req.headers.set('Authorization', 'Token ' + this.authv4.get().token)
    });   

    // return timer(Math.random() * 1500).pipe(switchMap(() => next.handle(authReq)));
    return next.handle(authReq);
  }

}

