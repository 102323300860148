import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISuccessResponse } from 'src/app/lib/classes/response/success';
import { IAutomationExecute } from '../interfaces/status';
import Url from 'src/app/lib/classes/url';

@Injectable({
  providedIn: 'root'
})
export class Automationv4Service {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @param data 
   * @returns 
   */
  execute(data: IAutomationExecute): Observable<ISuccessResponse> {
    let url = new Url("/automations/")

    return this.http.post<ISuccessResponse>(url.build(), data)
  }
}
