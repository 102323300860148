import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISynchronization, ISynchronizationPagination } from '../interfaces/sync';
import Url from 'src/app/lib/classes/url';
import { IAccount } from 'src/app/account/interfaces/account';
import { ISuccessResponse } from 'src/app/lib/classes/response/success';
import { format } from 'date-fns';
import { DateRange } from 'src/app/lib/classes/daterange';

@Injectable({
  providedIn: 'root'
})
export class Syncv4Service {

  /**
   * 
   * @param http 
   */
  constructor(private http: HttpClient) { }

  /**
   * 
   * @returns 
   */
  public paginate(accountId: number, hasSucceeded: boolean, range: DateRange, limit: number = 10, offset: number = 0): Observable<ISynchronizationPagination> {
    let url = new Url("/syncs")
      .query('account', accountId)
      .query('has_succeeded', hasSucceeded ? null : false)
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted())
      .query('limit', limit)
      .query('offset', offset)
    
    return this.http.get<ISynchronizationPagination>(url.build())
  }

  /**
   * 
   * @returns 
   */
  public last(): Observable<ISynchronization> {
    let url = new Url("/syncs/last")

    return this.http.get<ISynchronization>(url.build())
  }

  /**
   * 
   * @param type 
   * @param date 
   * @returns 
   */
  public sync(accountId: number, type: string, date: Date): Observable<ISuccessResponse> {
    let url = new Url("/util/sync/" + type)
      .query('account', accountId)
      .query('date', format(date, 'yyyy-MM-dd'))

    return this.http.get<ISuccessResponse>(url.build());
  }

  /**
   * 
   * @returns 
   */
  public metrics(range: DateRange): Observable<any> {
    let url = new Url("/syncs/metrics")
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted());
      
    return this.http.get<any>(url.build());
  }

}
