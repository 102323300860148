import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from 'src/app/auth/classes/user';
import { Userv4Service } from 'src/app/user/services/userv4.service';

@Pipe({
  name: 'filterValue',
  standalone: true
})
export class FilterValuePipe implements PipeTransform {

  constructor(private userv4: Userv4Service) {}

  transform(value: string, field: string, users: IUser[]): string {

    if (field === 'creator__pk' || field === 'uploader__pk') {
      return users.find((item: IUser) => item.id === parseInt(value))?.name ?? 'Unknown User'
    }

    if (field === 'is_serving_ads') {
      return value === '1' ? 'Is Serving Ads' : 'Is Not Serving'
    }

    return value + '€/$';
  }

}
