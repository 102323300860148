<p-dialog [visible]="isTemplateModalOpen" (visibleChange)="toggle()" draggable="false" [modal]="true" #templateModal>
  <ng-template pTemplate="header">
    <div>
      Make for Ranges: <span class="text-primary">{{ selectedRanges.join(",") | summaries }}</span>
    </div>
  </ng-template>

  <form [formGroup]="templateForm">
    <mv-input label="Name">
      <input type="text" class="mv-input" formControlName="name">
    </mv-input>
  </form>

  <div class="mt-6">
    <mv-button [loading]="isCreatingTemplate" label="Save" (click)="saveTemplate()"></mv-button>
  </div>

</p-dialog>

<div class="preset-ranges">
  <div class="flex flex-row justify-around">
    <button class="bg-gray-light text-black-lighter font-bold py-2 px-3 rounded-md mr-2 " (click)="showHideMenu()">
      <span *ngIf="selectedRanges.length > 1">{{selectedRanges.length}} selected</span>
      <span *ngIf="selectedRanges.length === 1">{{allRanges[selectedRanges[0]].name | summary}}</span>
    </button>

    <button class="bg-gray-light text-black-lighter font-bold py-2 px-3 rounded-md ml-2"
      (click)="showHideTemplateMenu()">
      <span>Templates</span>
    </button>
  </div>


  <!-- Range menu -->
  <ul class="rounded rounded-lg bg-white shadow shadow-xs border border-gray" *ngIf="showMenu">
    <li *ngFor="let range of allRanges" class="">
      <!-- hacky solution for range select -->
      <div [ngClass]="{'bg-gray-light': selectedRanges.includes(range.value)}" class="rounded-md range">
        <mave-action [label]="range.name | summary" type="secondary" (click)="selectRange(range)">
        </mave-action>
      </div>
    </li>
  </ul>

  <!-- Template menu -->
  <ul class="rounded rounded-lg bg-white shadow shadow-xs border border-gray" *ngIf="showTemplateMenu">
    <li *ngFor="let template of templates" class="">
      <mave-action [label]="template.name" type="secondary" (click)="changeTemplate(template)">
      </mave-action>
    </li>
    <li>
      <mv-button (click)="isTemplateModalOpen = true" variant="primary" label="Save Template"></mv-button>
    </li>
    <li class="-my-2">
      <mv-button size="small" variant="secondary" label="Details" (click)="details()"></mv-button>
    </li>
  </ul>
</div>