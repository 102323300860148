import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ICampaignMetricsResult } from '../../interfaces/campaign-metrics-result';
import { PaginatedResponse } from 'src/app/mavejs/http/interfaces/paginated-response';
import { Chart } from 'chart.js';
import { Domain } from '../../interfaces/domain';
import { DateRange } from 'src/app/lib/classes/daterange';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { DomainService } from '../../services/domain.service';
import { CampaignService } from '../../services/campaign.service';
import { CampaignGraphPainter, FillGraphPainter, FixedColorGraphPainter, GraphPainter } from '../../classes/graph/graph-painter';
import { AccountCpmValueParser, AdCtrValueParser, AdjustedRoiGraphValueParser, CostGraphValueParser, CVRValueParser, FillGraphValueParser, GraphValueParser, LanderCtrValueParser, RoiGraphValueParser } from '../../classes/graph/graph-value-parser';
import { IAccount } from 'src/app/account/interfaces/account';
import { Title } from '@angular/platform-browser';
import { IStatistics, ITotals, Totals } from 'src/app/lib/interfaces/statistics';
import { ICampaignCount } from '../../interfaces/campaign-count';
import { DeleteModalComponent } from 'src/app/mavejs/ui/delete-modal/delete-modal.component';
import { ModalComponent } from 'src/app/mavejs/ui/modal/modal.component';
import { ICommentThread } from 'src/app/comment/interfaces/icomment-thread';
import { min, startOfToday, startOfYesterday, subWeeks } from 'date-fns';
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TableModule } from 'primeng/table';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { DaterangepickerComponent } from 'src/app/lib/ui/daterangepicker/daterangepicker.component';
import { CurrencyPipe, DatePipe, JsonPipe, NgIf, PercentPipe } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ChartModule } from 'primeng/chart';
import { ISortEvent } from 'src/app/mavejs/data/datatable/datatable.component';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { StatisticsComponent } from 'src/app/lib/components/statistics/statistics.component';
import { ChangeHistoryComponent } from '../../components/change-history/change-history.component';
import { Campaignv4Service } from '../../services/campaignv4.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BadgeModule } from 'primeng/badge';
import { InlineGraphComponent } from "../../components/inline-graph/inline-graph.component";
import { CampaignTypeIconPipe } from '../../pipes/campaign-type-icon.pipe';
import { Statusv4Service } from 'src/app/status/services/statusv4.service';
import { ICampaign, IBudgetMapping, ICampaignMetrics, IRoiMapping, ICampaignDetail, Campaign } from '../../interfaces/campaign';
import { Dialog, DialogModule } from 'primeng/dialog';
import { InputComponent } from 'src/app/lib/ui/input/input.component';
import { SkeletonModule } from 'primeng/skeleton';
import { YtGradingDirective } from 'src/app/lib/directives/ytgrading.directive';
import { CpaDialogComponent } from '../../components/cpa-dialog/cpa-dialog.component';
import { BudgetDialogComponent } from '../../components/budget-dialog/budget-dialog.component';
import { Accountv4Service } from 'src/app/account/services/accountv4.service';
import { DropdownModule } from 'primeng/dropdown';
import { FeatureComponent } from 'src/app/feature/feature.component';
import { TotalsComponent } from 'src/app/lib/components/totals/totals.component';
import { featureIsActivated } from 'src/app/lib/classes/feature';
import { debounceTime } from 'rxjs';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { IUser } from 'src/app/auth/classes/user';
import { IStatusChangeType, IStatusHistory, IStatusPayload } from 'src/app/status/interfaces/status-change';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { GraphBuilder } from '../../classes/graph/graph-builder';
import { GraphOptions } from '../../classes/graph/graph-options';
import { CommentOverviewComponent } from 'src/app/comment/components/comment-overview/comment-overview.component';
import 'chartjs-adapter-date-fns';
import { ICommentPreview } from 'src/app/comment/classes/comment';
import { Commentv4Service } from 'src/app/comment/services/commentv4.service';
import { CommentOverviewDialogComponent } from 'src/app/comment/components/comment-overview-dialog/comment-overview-dialog.component';
import { MondayComponent } from 'src/app/lib/components/monday/monday.component';
import { SyncComponent } from 'src/app/lib/ui/sync/sync.component';
import { ConvComponent } from 'src/app/lib/components/conv/conv.component';
import { MessageService } from 'primeng/api';
import { GraphFillFormatterBuilder, GraphRoiFormatterBuilder } from '../../classes/graph/formatters/igraph-formatter';

@Component({
    selector: 'ga-index-campaign-view',
    standalone: true,
    templateUrl: './index-campaign-view.component.html',
    styleUrl: './index-campaign-view.component.css',
    imports: [
      NgIf,
      ReactiveFormsModule,
      FormsModule,
      CardComponent,
      StatisticsComponent,
      ChangeHistoryComponent,
      ChartModule,
      TitleComponent,
      DaterangepickerComponent,
      TableModule,
      YtGradingDirective,
      DatePipe,
      CurrencyPipe,
      PercentPipe,
      RouterLink,
      InputSwitchModule,
      OverlayPanelModule,
      BadgeModule,
      DialogModule,
      InlineGraphComponent,
      InputComponent,
      CampaignTypeIconPipe,
      SkeletonModule,
      CpaDialogComponent,
      BudgetDialogComponent,
      InputComponent,
      DropdownModule,
      FeatureComponent,
      TotalsComponent,
      TooltipModule,
      CheckboxModule,
      CommentOverviewComponent,
      CommentOverviewDialogComponent,
      MondayComponent,
      SyncComponent,
      ConvComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class IndexCampaignViewComponent  implements OnInit {
  @ViewChild('deleteModal') deleteModal!: DeleteModalComponent;
  @ViewChild('domainRenewelModal') domainRenewalModal!: ModalComponent;
  @ViewChild('cpa') cpa!: Dialog
  @ViewChild('budget') budget!: BudgetDialogComponent

  page: number;

  accountId!: number;
  account!: IAccount;
  accountCommentThread!: ICommentThread;
  campaignCount!: ICampaignCount;
  campaignMetrics!: Array<ICampaignMetrics>;
  campaignMetricsIsLoading = true;
  campaignService!: CampaignService;
  campaigns: Array<ICampaignMetricsResult> = []
  campaignNumbering: Map<number, number> = new Map();
  range: DateRange = new DateRange(subWeeks(startOfToday(), 1), startOfYesterday());
  isSwitchDisabled = false;

  selectedDomain!: Domain;
  isLoadingDomainRenew = false;

  creatorUsers!: any[];
  selectedCreator!: any;
  uploaderUsers!: any[];
  selectedUploader!: any;

  statistics!: IStatistics;
  overallStatistics!: IStatistics;
  isLoadingStatistics = true;
  isLoadingOverallStatistics = true;
  isLoadingAccount = true;

  showFilterStatistics = false;

  sortBy: string = ""
  direction: string = "desc"
  searchTerm: string = ""
  itemsPerPage: string = "50"

  itemWithCostZero: boolean = true;

  currentYear: string = new Date().getFullYear().toString();

  fillPainter!: GraphPainter;
  fillParser!: GraphValueParser;
  fillFormatter!: GraphFillFormatterBuilder;

  roiPainter!: GraphPainter;
  roiParser!: GraphValueParser;
  roiFormatter!: GraphRoiFormatterBuilder;

  isLoadingGraph: boolean = false;
  graphData: any;
  rawGraphData: any;
  graphOptions: any;

  changeCount: number = 0;

  isRoisLoaded = false;
  rois!: IRoiMapping;

  isFillLoaded = false;
  fills!: IBudgetMapping;

  campaignChanges: Array<IStatusHistory> = [];
  isCampaignChangesLoaded = false;

  budgetChanges: Array<IStatusHistory> = [];
  cpaChanges: Array<IStatusHistory> = [];
  maxConversionChanges: Array<IStatusHistory> = []

  cpaDialogIsOpen = false;
  newCpaValue = 200;

  budgetDialogIsOpen = false;
  newBudgetValue = 200;

  selectedCampaign!: ICampaignDetail;
  highlightedCampaign: number = 0;

  totals!: ITotals;
  isTotalsLoaded = false;
  shouldRenderGraph = true;

  commentPreviews: ICommentPreview[] = []
  commentDialogIsOpen = false;
  selectedCommentEntity = 0;

  bigGraphOptions: any;
  smallGraphOptions: any;

  filterForm: FormGroup = this.fb.group({
    search: ['', Validators.required],
    creator: [''],
    uploader: ['']
  })

  chartFilterForm = this.fb.group({
    'roi': [true, [Validators.required]],
    'cost': [true, [Validators.required]],
    'cpm': [false, [Validators.required]],
    'adctr': [false, [Validators.required]],
    'lctr': [false, [Validators.required]],
    'cvr': [false, [Validators.required]],
  })

  hoverBackgroundColor = GraphOptions.getHoverBackgroundColor();

  constructor(
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private message: MessageService,
    private accountv4: Accountv4Service,
    private statusv4: Statusv4Service,
    private campaignv4: Campaignv4Service,
    private domain: DomainService,
    private title: Title,
    private userv4: Userv4Service,
    private commentv4: Commentv4Service
  ) {
    this.page = 0;
    this.fillPainter = new FillGraphPainter();
    this.fillParser = new FillGraphValueParser();
    this.fillFormatter = new GraphFillFormatterBuilder(0, this.account);
    this.roiPainter = new CampaignGraphPainter();
    this.roiParser = new RoiGraphValueParser();
    this.roiFormatter = new GraphRoiFormatterBuilder(0);
  }

  ngOnInit(): void {
    this.filterForm.valueChanges
    .pipe(
      debounceTime(600) // ignores very quick secondary daterange event, to avoid loading previous data and to avoid multiple api fetches
    )
    .subscribe((event: any) => {
      this.getCampaignMetrics();
    })

    this.chartFilterForm.valueChanges.subscribe(() => {
      this.setChartVisuals();
    })

    this.router.params.subscribe(param => {
      this.accountId = +param["id"]

      Chart.register(ChartDataLabels);

      this.userv4.all().subscribe((users: IUser[]) => {
        this.creatorUsers = users;
        this.uploaderUsers = users;
      });

      this.isFillLoaded = false;
      this.campaignv4.getBudgets(this.accountId).subscribe((fills: IBudgetMapping) => {
        this.fills = fills;
        this.isFillLoaded = true;
      })

      this.isRoisLoaded = false;
      this.campaignv4.getRois(this.accountId).subscribe((rois: IRoiMapping) => {
        this.rois = rois;
        this.isRoisLoaded = true;
      })

      this.statusv4.getActiveHistory(this.accountId, IStatusChangeType.Campaign).subscribe((changes: Array<IStatusHistory>) => {
        this.campaignChanges = changes;
        this.isCampaignChangesLoaded = true;
      });

      this.statusv4.getActiveHistory(this.accountId, IStatusChangeType.Budget).subscribe((changes: Array<IStatusHistory>) => {
        this.budgetChanges = changes;
      });

      this.statusv4.getActiveHistory(this.accountId, IStatusChangeType.Cpa).subscribe((changes: Array<IStatusHistory>) => {
        this.cpaChanges = changes;
      });

      this.statusv4.getActiveHistory(this.accountId, IStatusChangeType.MaxConversions).subscribe((changes: Array<IStatusHistory>) => {
        this.maxConversionChanges = changes;
      });

      this.accountv4.getAccount(this.accountId).subscribe((account: IAccount) => {
        this.account = account
        this.isLoadingAccount = false;
        this.title.setTitle(account.name);
        this.getCampaignMetrics();
      })
    })
  }

  getCampaignMetrics() {
    this.isLoadingStatistics = true;
    this.isLoadingOverallStatistics = true;
    this.campaignMetricsIsLoading = true;
    this.isTotalsLoaded = false;

    this.totals = Totals.noop();

    if (featureIsActivated("campaign_statistics")) {
      this.campaignv4.getTotals(
        this.accountId,
        this.filterForm.get('search')?.value,
        this.filterForm.get('creator')?.value,
        this.filterForm.get('uploader')?.value,
        this.range
      ).subscribe((totals: ITotals) => {
        this.totals = totals;
        this.isTotalsLoaded = true
      });

      this.campaignv4.getGraph(
        this.accountId,
        this.filterForm.get('search')?.value,
        this.filterForm.get('creator')?.value,
        this.filterForm.get('uploader')?.value,
        this.range
      ).subscribe((graphData: any) => {
        this.shouldRenderGraph = graphData.length > 1;

        let moreThan7days = graphData.length > 7;

        let costArray = graphData.map((item: any) => (item.y['cost']));
        let roiArray = graphData.map((item: any) => (item.y['roi']));
        
        let maxCost = Math.max(...costArray);
        let minCost = Math.min(...costArray);
        let maxRoi = Math.max(...roiArray);
        let minRoi = Math.min(...roiArray);

        this.rawGraphData = graphData;
        this.setChartVisuals();

        this.graphOptions = GraphOptions.getCampaignGraphOptions(!moreThan7days, this.account, maxCost, minCost, maxRoi, minRoi);
        Chart.register(ChartDataLabels);
        this.isLoadingGraph = false;
      });
    } else {
      this.campaignService.getOverallCampaignStatistics(this.accountId, this.range).subscribe((overallStatistics: IStatistics) => {
        this.overallStatistics = overallStatistics;
        this.isLoadingOverallStatistics = false;
      })
    }

    this.campaignv4.getWithMetrics(
      this.accountId,
      this.filterForm.get('search')?.value,
      this.filterForm.get('creator')?.value,
      this.filterForm.get('uploader')?.value,
      this.range
    ).subscribe((metrics: any) => {
      this.campaignMetrics = metrics;
      this.campaignNumbering = Campaign.numbering(metrics);
      // this.itemWithCostZero = this.campaignMetrics.some(e => e.total_cost === 0);
      this.sort({header: this.sortBy, direction: this.direction});

      this.campaignMetricsIsLoading = false;

      if (!featureIsActivated("campaign_statistics")) {
        this.campaignService.getCampaignStatistics(this.accountId, this.range, this.filterForm.get('search')?.value).subscribe((statistics: IStatistics) => {
          this.statistics = statistics;
          this.isLoadingStatistics = false;
        })
      }
    });

    this.commentv4.previewsByAccount(this.accountId).subscribe((previews: ICommentPreview[]) => {
      this.commentPreviews = previews;
    })
  }

  /**
   * This function exists because of this task: 2405893345
   */
  getCampaignMetricsBackground() {
    this.campaignService.getCampaignMetrics(this.accountId, this.range, this.sortBy, this.itemsPerPage, this.direction, this.filterForm.get('search')?.value, this.page, this.selectedCreator?.id, this.selectedUploader?.id).subscribe((metrics: any) => {
      this.campaignMetrics = metrics;
      this.campaignMetricsIsLoading = false;
      this.itemWithCostZero = this.campaignMetrics.some(e => e.total_cost === 0);
    });
  }

  /**
   *
   * @param range
   */
  dateRangeChanged(range: DateRange) {
    this.range = range;
    this.getCampaignMetrics();
  }

  /**
   *
   * @param page
   */
  handlePageChange(page: number) {
    this.page = page;
    this.getCampaignMetrics()
  }

  /**
   *
   * @param event
   */
  sort(event: ISortEvent) {
    var sortable = ['#','cost','revenue','profit'];
    this.sortBy = event.header;
    this.direction = event.direction;

    if(sortable.indexOf(this.sortBy) > -1){
      this.campaignMetricsIsLoading = true;
      this.getCampaignMetricsBackground();
    }
  }

  /**
   *
   * @param account
   * @param campaignId
   * @returns
   */
  buildCampaignLink(account: IAccount, campaignId: number) {
    if (account === undefined || campaignId === undefined) {
      return '/campaign/account/' + this.accountId;
    }

    return '/ad/' + this.accountId + '/account/' + campaignId + '/adgroup'
  }

  /**
   *
   * @param domainId
   */
  openDomainRenewalDialog(domainId: number) {
    this.domain.findById(domainId, this.account.id).subscribe((domain: Domain) => {
      this.selectedDomain = domain;
    })

    this.domainRenewalModal.openModal();
  }

  /**
   *
   * @param domainId
   */
  renewDomain(domainId: number) {
    this.isLoadingDomainRenew = true;
    this.domain.renew(domainId, this.account.id).subscribe(() => {
      this.isLoadingDomainRenew = false;
      this.getCampaignMetricsBackground();
    })
  }

  /**
   * @deprecated
   * @param count
   */
  trackChangeCount(count: number) {
    this.changeCount = count;
  }

  /**
   *
   * @param campaign
   */
  changeStatus(campaign: ICampaign) {
    let payload: IStatusPayload = {
      id: campaign.id,
      account_id: this.accountId,
      campaign_id: campaign.id,
      ad_group_id: 0,
      type: IStatusChangeType.Campaign,
    }

    setTimeout(() => {
      this.statusv4.getActiveHistory(this.accountId, IStatusChangeType.Campaign).subscribe((changes: Array<IStatusHistory>) => {
        this.campaignChanges = changes;
      })
    }, 100)


    this.statusv4.apply(payload).subscribe({
      complete: () => {},
      error: () => this.message.add({
        severity: 'error',
        summary: 'Could not change status'
      })
    })
  }

  /**
   *
   * @param campaign
   * @returns
   */
  hasStatus(campaign: ICampaign) {
    if (campaign === undefined) {
      return false;
    }

    return this.campaignChanges.find(item => item.entity_id === campaign.id) !== undefined
  }

  /**
   *
   * @param campaign
   */
  openCpaDialog(campaign: ICampaign) {
    this.cpaDialogIsOpen = true;
    this.selectedCampaign = Campaign.detail(campaign);
    this.newCpaValue = parseInt(campaign.target_cpa)
  }

  /**
   *
   */
  changeCpa() {
    this.statusv4.getActiveHistory(this.accountId, IStatusChangeType.Cpa).subscribe((changes: Array<IStatusHistory>) => {
      this.cpaChanges = changes;
    })

    this.statusv4.getActiveHistory(this.accountId, IStatusChangeType.MaxConversions).subscribe((changes: Array<IStatusHistory>) => {
      this.maxConversionChanges = changes;
    })
  }

  /**
   *
   * @param entity_id
   * @returns
   */
  getCpaChange(entity_id: number) {
    let change = this.cpaChanges.find(item => item.entity_id === entity_id);

    if (change === undefined) {
      return 0;
    }

    return change.value
  }

  /**
   *
   * @param entity_id
   * @returns
   */
  hasMaxConversionsChange(entity_id: number) {
    let change = this.maxConversionChanges.find(item => item.entity_id === entity_id);

    if (change === undefined) {
      return false;
    }

    return true
  }

  /**
   *
   * @param campaign
   */
  openBudgetDialog(campaign: ICampaign) {
    this.budget.setBudget(campaign.budget);
    this.budgetDialogIsOpen = true;
    this.selectedCampaign = Campaign.detail(campaign);
    
  }

  /**
   *
   */
  changeBudget() {
    this.statusv4.getActiveHistory(this.accountId, IStatusChangeType.Budget).subscribe((changes: Array<IStatusHistory>) => {
      this.budgetChanges = changes;
    })
  }

  /**
   *
   * @param entity_id
   * @returns
   */
  getBudgetChange(entity_id: number) {
    let change = this.budgetChanges.find(item => item.entity_id === entity_id);

    if (change === undefined) {
      return 0;
    }

    return change.value
  }

  /**
   *
   * @param campaignId
   */
  highlight(campaignId: number) {
    this.highlightedCampaign = campaignId;
  }

  /**
   *
   */
  setChartVisuals() {
    let costArray = this.rawGraphData.map((item: any) => (item.y['cost']));
    let maxCost = Math.max(...costArray);
    let minCost = Math.min(...costArray);

    let builder = new GraphBuilder();

    builder.push(this.rawGraphData, new FixedColorGraphPainter('#333333'), new AdjustedRoiGraphValueParser(maxCost, minCost), !this.chartFilterForm.get('roi')?.value)
    builder.push(this.rawGraphData, new FixedColorGraphPainter('#CE0F0F'), new CostGraphValueParser(), !this.chartFilterForm.get('cost')?.value)
    builder.push(this.rawGraphData, new FixedColorGraphPainter('#4C0013'), new AccountCpmValueParser(this.account, maxCost), !this.chartFilterForm.get('cpm')?.value)
    builder.push(this.rawGraphData, new FixedColorGraphPainter('#000080'), new AdCtrValueParser(maxCost), !this.chartFilterForm.get('adctr')?.value)
    builder.push(this.rawGraphData, new FixedColorGraphPainter('#F05E16'), new LanderCtrValueParser(maxCost), !this.chartFilterForm.get('lctr')?.value)
    builder.push(this.rawGraphData, new FixedColorGraphPainter('#004000'), new CVRValueParser(maxCost), !this.chartFilterForm.get('cvr')?.value)

    this.graphData = builder.build(GraphBuilder.labelsFromGraphData(this.rawGraphData));
  }

  getComment(entityId: number): ICommentPreview {
    return this.commentPreviews.find((cp: ICommentPreview) => cp.entity_id === entityId) ?? {
      account_id: 0,
      entity_id: 0,
      count: 0 ,
      last_updated: new Date()
    };
  }

  /**
   *
   * @param campaign
   */
  openCommentDialog(entityId: number) {
    this.commentDialogIsOpen = true;
    this.selectedCommentEntity = entityId;
  }


  changeComment() {
    this.commentv4.previewsByAccount(this.accountId).subscribe((previews: ICommentPreview[]) => {
      this.commentPreviews = previews;
    })
  }
}

export interface PaginatedCampaignMetricsResult extends PaginatedResponse {
  content: Array<ICampaignMetricsResult>
}

export interface ICpaChange {
  maxConversions: boolean;
  modified: boolean;
  newValue: number;
  oldValue: number;
  newValueFormatted: string;
  oldValueFormatted: string;
}
