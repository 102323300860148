<div class="w-full md:w-1/3 mx-auto">
  <mave-modal title="Set secret name" #generateSecretModal>

    <mave-textbox label="Secret name">
      <input type="text" [(ngModel)]="secretName" maveInput>
    </mave-textbox>

    <div footer>
      <mave-button label="Generate" (press)="generate(secretName)"></mave-button>
    </div>

  </mave-modal>

  <mave-delete-modal
    #deleteSecretModal
    *ngIf="currentSecretForDeletion"
    title="Delete secret"
    [message]="'Do you really want to delete secret: ' + currentSecretForDeletion.name + '?'"
    (delete)="deleteSecret(currentSecretForDeletion)">
  </mave-delete-modal>

  <mave-card *ngIf="secrets">
    <div header>
      <mave-sub-title text="Generate Secret"></mave-sub-title>
    </div>

    <div *ngIf="secrets.length > 0" class="flex flex-row -m-5">
      <div class="w-1/3 border-r border-gray">
        <mave-list>
          <mave-loader [isLoading]="isLoadingSecrets">
            <mave-list-item *ngFor="let secret of secrets" (click)="selectSecret(secret)">
              <div>{{ secret.name }}</div>
              <div>
                <mave-action type="delete" icon="delete" (press)="openDeleteSecretModal(secret)"></mave-action>
              </div>
            </mave-list-item>
          </mave-loader>
        </mave-list>
      </div>
      <div class="w-2/3">
        <div class="p-5" *ngIf="currentSecret">
          <span class="text-sm font-bold text-secondary mb-5">{{ currentSecret.name }}</span>
          <mave-overlay-button icon="content_copy" (action)="copyKey()">
            <mave-textbox label="API Secret">
              <input type="text" disabled [(ngModel)]="currentSecret.key" maveInput />
            </mave-textbox>
          </mave-overlay-button>
        </div>
      </div>
    </div>

    <div *ngIf="secrets.length === 0">
      <div class="p-14 text-center">
        <div class="text-primary-light"><i class="material-symbols-outlined text-5xl">key</i></div>
        <div class="text-secondary-light text-md font-bold">No secrets generated</div>
      </div>
    </div>

    <div footer>
      <div class="flex">
        <mave-button class="mr-3" label="Generate" (press)="openSecretGenerationModal()"></mave-button>
      </div>
    </div>
  </mave-card>
</div>
