<mv-card [hasHeader]="false" *ngIf="reportFilterForm">
    <div body>
        <form [formGroup]="reportFilterForm">
            <span class="font-bold mr-3">Report for</span> <p-dropdown styleClass="mv-dropdown"  formControlName="user" optionLabel="name" optionValue="id" [options]="users"></p-dropdown> <span class="font-bold ml-3">on</span> <p-calendar styleClass="mv-calendar ml-4" [selectOtherMonths]="true" formControlName="date" dateFormat="dd/mm/yy"></p-calendar>
        </form>
    </div>
</mv-card>


<div class="mv-table-empty" *ngIf="reports.length === 0">
    <i class="material-symbols-outlined mv-table-empty__icon">error</i>
    <div class="mv-table-empty__label">No reports</div>
</div>

<div class="ga-daily-reports__grid">
    <mv-card [table]="true" *ngFor="let report of reports">
        <div header>
            <div class="flex justify-between">
                <div>
                    <mv-title [size]="3">{{ report.name }}</mv-title>
                </div>
                <div>
                    <mv-title [size]="4">Based on data from:
                        <span *ngIf="report.range_from !== report.range_to">{{ report.range_from  | date: 'MMM d, Y'}} - {{ report.range_to | date: 'MMM d, Y'}}</span>
                        <span *ngIf="report.range_from === report.range_to">{{ report.range_from  | date: 'MMM d, Y'}}</span>
                    </mv-title>
                </div>
            </div>
            
        </div>
        <div body>
            <p-table 
            #r
            *ngIf="isLoaded"
            [value]="report.campaigns"
            sortMode="single"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th pSortableColumn="cost" class="text-right">Cost <p-sortIcon field="cost"/></th>
                    <th pSortableColumn="profit" class="text-right">Profit <p-sortIcon field="profit"/></th>
                    <th class="text-right">ROI</th>
                    <th pSortableColumn="revenue" class="text-right">Revenue <p-sortIcon field="revenue"/></th>
                    <th *ngIf="report.name === 'Campaigns with reactivated ads'" pSortableColumn="budget" class="text-right">Budget <p-sortIcon field="budget"/></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-campaign>
                <tr>
                    <td>
                        <a class="flex" [routerLink]="['/ad/' + campaign.account.id + '/account/' + campaign.campaign.id + '/adgroup']">{{ campaign.campaign.name | limitTrailing:[80, '...'] }}</a>
                    </td>
                    <td class="font-mono">{{ campaign.cost | currency: campaign.account.currency:undefined:'1.0-0' }}</td>
                    <td class="font-mono">{{ campaign.profit | currency: campaign.account.currency:undefined:'1.0-0' }}</td>
                    <td class="font-mono">
                       {{ (campaign.profit / campaign.cost | percent) }}
                    </td>
                    <td class="font-mono">{{ campaign.revenue | currency: campaign.account.currency:undefined:'1.0-0' }}</td>
                    <td *ngIf="report.name === 'Campaigns with reactivated ads'" class="font-mono">{{ campaign.budget | currency: campaign.account.currency:undefined:'1.0-0' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="15" class="mv-table-empty">
                        <i class="material-symbols-outlined mv-table-empty__icon">explore_off</i>
                        <div class="mv-table-empty__label">No Results found</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        </div>
    </mv-card>
</div>
