
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/user/services/user.service';
import { TemplateService } from '../../../services/template.service';
import { ITemplate, ITemplateLegacy, User } from 'src/app/template/classes/template';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { Templatev4Service } from 'src/app/template/services/templatev4.service';
import { IUser } from 'src/app/auth/classes/user';
import { ActionComponent } from '../../../../mavejs/base/action/action.component';
import { TooltipComponent } from '../../../../mavejs/base/tooltip/tooltip.component';
import { EmptyComponent } from '../../../../mavejs/base/empty/empty.component';
import { NgIf, NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../../../mavejs/form/button/button.component';
import { SubTitleComponent } from '../../../../mavejs/text/sub-title/sub-title.component';
import { CardComponent } from '../../../../mavejs/base/card/card.component';

@Component({
    selector: 'ganal-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
    standalone: true,
    imports: [CardComponent, SubTitleComponent, ButtonComponent, RouterLink, NgIf, EmptyComponent, NgFor, TooltipComponent, ActionComponent]
})
export class IndexComponent implements OnInit {
  user!: IUser;
  isLoadingUser: boolean = false;
  templates!: Array<ITemplate>

  constructor(private title: Title, private userv4: Userv4Service, private templatev4: Templatev4Service) {}

  ngOnInit(): void {
    this.title.setTitle("Mave Google Analytics: Templates")

    this.loadUser();
  }

  loadUser() {
    this.isLoadingUser = true;
    this.userv4.self().subscribe((user: any) => {
      this.user = user;
      this.isLoadingUser = false;
    })

    this.templatev4.getTemplates().subscribe((templates: Array<ITemplate>) => {
      this.templates = templates;
    })
  }

  get default() {
    return this.templatev4.getDefaultTemplateId()
  }

  setTemplateDefault(template: ITemplate) {
    this.templatev4.setDefaultTemplate(template.id);
  }

  deleteTemplate(template: ITemplate) {
    this.templatev4.deleteTemplate(template.id).subscribe(() => {
      this.loadUser();
      this.templatev4.preloadTemplates();
      this.templatev4.getTemplates().subscribe((templates: Array<ITemplate>) => {
        this.templates = templates;
      })
    });
  }

}
