<div class="flex flex-col justify-center align-center text-center">

    <div class="flex align-center justify-center">
      <div class="error-icon material-symbols-outlined">encrypted</div>
    </div>
  
    <div>
      <h1 class="font-black text-black text-4xl">You have no permission to view this page</h1>
      <h3 class="mt-2 italic">Please ask for more rights or accept the situation. Thank you</h3>
  
      <div class="mt-4">
        <mave-button type="secondary" (click)="back()" label="Go To Previous page"></mave-button>
      </div>
    </div>
  
  </div>
  