<div class="flex">
    <div class="mv-daterangepicker">
        <div class="mv-daterangepicker-scroll mv-daterangepicker-scroll-l" (click)="back($event)">
            <i class="material-symbols-outlined">chevron_left</i>
        </div>
        <div>
            <div #pickerInput type="text" disabled class="mv-daterangepicker-input" (click)="toggle($event)">
                {{ render }}
            </div>
        </div>
        <div class="mv-daterangepicker-scroll mv-daterangepicker-scroll-r" (click)="next($event)">
            <i class="material-symbols-outlined">chevron_right</i>
        </div>
    </div>
    <div class="mv-daterangepicker__preset" (click)="toggle($event)">
        {{ selectedPresetLabel }}
    </div>
</div>
<div 
[@overlayAnimation]="{ value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }" 
#pickerPanel class="mv-daterangepicker-panel" 
*ngIf="showPanel">
    <div class="flex">
        <div class="mv-daterangepicker-presets">
            <div (click)="selectPreset(preset)" class="mv-daterangepicker-preset-item" *ngFor="let preset of rangePresets" [class.active]="preset.value === presetValue">{{ preset.label }}</div>
        </div>
        <div *ngFor="let month of months; let num = index" class="mv-daterangepicker-month">
            <div class="mv-daterangepicker-month-titlewrapper" *ngIf="!isGeneratingMonths">
                <div class="mv-daterangepicker-month-arrow back" (click)="previousMonths($event)"><span class="material-symbols-outlined">arrow_back</span></div>
                <div class="flex">
                    <div class="mv-daterangepicker-month-title">
                        {{ monthLabels[month.month] }}
                    </div>
                    <div class="mv-daterangepicker-month-year">
                        {{ month.year }}
                    </div>
                </div>
                <div class="mv-daterangepicker-month-arrow forward" (click)="nextMonths($event)"><span class="material-symbols-outlined">arrow_forward</span></div>
            </div>


            <div class="mv-daterangepicker-month-days">
                <div class="font-bold text-sm mb-3" *ngFor="let week of weekLabels">{{ week }}</div>
                <div *ngFor="let week of getWeekdaysUntil(month)"></div>

                <div 
                    *ngFor="let day of month.days" 
                    [class.selecting]="day.selected" 
                    [class.today]="day.dayString === todayString" 
                    (click)="select(day, $event)" 
                    class="mv-daterangepicker-month-day">
                    {{ day.day | date: 'd' }}
                </div>
            </div>
        </div>
    </div>
</div>