<div class="ga-account-refresh">
    <mv-card>
        <div header>
            <mv-title [size]="3">Connect with MCC</mv-title>
        </div>
        <div body>
            <div class="flex flex-col items-center justify-center h-48" *ngIf="!hasCode">
                <!-- <div class="mb-6 text-black font-bold text-xl">Please click authorize to start adding google accounts
                </div> -->

                <div>Redirecting to google ...</div>
                <p-progressBar mode="indeterminate" [style]="{ height: '3px', width: '18rem', 'margin-top': '1rem' }" />

                <!-- <mv-button icon="security" label="Authorize" (click)="authorize()"></mv-button> -->
            </div>
            <div *ngIf="hasCode && accounts.length === 0">
                <p-dropdown *ngIf="!isLoadingMccs" placeholder="No MCC Selected" [options]="mccs" [(ngModel)]="mcc" styleClass="w-full">
                    <ng-template pTemplate="placeholder">
                        test
                    </ng-template>
                    <ng-template let-customer pTemplate="selectedItem">
                        <div class="text-left">
                            <div class="font-bold text-sm">{{ customer.mcc_id ?? 'XXX-XXX-XXXX' }}</div>
                            <div class="text-sm overflow-hidden">{{ (customer.account_names | limitTrailing:[80, '...'])
                                ?? 'No Accounts' }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-customer pTemplate="item">
                        <div class="text-left">
                            <div class="font-bold text-sm">{{ customer.mcc_id }}</div>
                            <div class="text-sm overflow-hidden">{{ customer.account_names | limitTrailing:[80, '...']
                                }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <div class="h-3"></div>
                <mv-button [loading]="isRefreshing" icon="refresh" label="Refresh MCC Token" (click)="refresh()"></mv-button>
            </div>


        </div>
    </mv-card>
</div>