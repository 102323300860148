import { GraphPainter } from "./graph-painter";
import { GraphValueParser } from "./graph-value-parser";

export class GraphBuilder {

  private buildGraphData: any;

  constructor() {
    this.buildGraphData = {
      datasets: [],
      labels: []
    }
  }

  /**
   * 
   * @param graphData 
   * @param painter 
   * @param parser 
   * @returns 
   */
  public push(graphData: any, painter: GraphPainter, parser: GraphValueParser, hidden = false) {
    let newDataSet = {
      backgroundColor: new Array<string>(),
      borderColor: new Array<string>(),
      barPercentage: 0.9,
      categoryPercentage: 1.0,
      parser: parser,
      hidden: hidden,
      rawData: new Array<any>(),
      data: new Array<number>(),
      yAxisID: parser.key()
    }

    for (let item of graphData) {
      painter.setValue(item.y);

      newDataSet.backgroundColor.push(painter.getColor());
      newDataSet.borderColor.push(painter.getLineColor());
      newDataSet.rawData.push(item.y);
      newDataSet.data.push(parser.parse(item.y));
    }

    this.buildGraphData.datasets.push(newDataSet);

    return this;
  }

  /**
   *
   * @param graphData
   */
  public static labelsFromGraphData(graphData: any): Array<string> {
    let labels = [];

    for (let item of graphData) {
      labels.push(item.x);
    }

    return labels;
  }

  /**
   * 
   * @param labels 
   * @returns 
   */
  public build(labels: Array<string> = []) {
    this.buildGraphData.labels = labels;

    return this.buildGraphData;
  }


}
