import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { DateRange } from 'src/app/lib/classes/daterange';
import Url from 'src/app/lib/classes/url';
import { IAccountMetrics } from '../interfaces/account-metrics-result';
import { IAccount } from '../interfaces/account';
import { ITotals } from 'src/app/lib/interfaces/statistics';
import { ICustomer } from '../views/oauth/authorize/authorize.component';
import { ICostCurrencies } from 'src/app/campaign/interfaces/campaign';

@Injectable({
  providedIn: 'root'
})
export class Accountv4Service {

  private accounts!: Observable<IAccount[]>

  constructor(private http: HttpClient) { }

  public preloadAccounts(): void {
    let url = new Url("/account")

    this.accounts = this.http.get<IAccount[]>(url.build()).pipe(
      shareReplay(1)
    )
  }

  /**
   * 
   * @returns 
   */
  getAccounts(): Observable<IAccount[]> {
    return this.accounts;
  }

  /**
   * 
   * @param accountId 
   * @returns 
   */
  getAccount(accountId: number): Observable<IAccount> {
    let url = new Url("/account/" + accountId)

    return this.http.get<IAccount>(url.build());
  }
  
  /**
   * 
   * @param range 
   * @returns 
   */
  getWithMetrics(range: DateRange): Observable<Array<IAccountMetrics>> {
    let url = new Url("/account/with_metrics")
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted())

    return this.http.get<Array<IAccountMetrics>>(url.build())
  }

  /**
   * 
   * @param range 
   * @returns 
   */
  getTotals(range: DateRange): Observable<ITotals> {
    let url = new Url("/account/totals")
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted())

    return this.http.get<ITotals>(url.build());
  }

  /**
   * 
   * @param range 
   * @returns 
   */
  getGraph(range: DateRange) {
    let url = new Url("/account/graph")
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted())
      
    return this.http.get<any>(url.build())
  }

  /**
   * 
   * @param mccId 
   * @param code 
   * @returns 
   */
  list(mccId: string, code: string): Observable<any[]> {
    let url = new Url("/account/list")
      .query("mcc", mccId)
      .query("code", code)

    return this.http.get<any[]>(url.build())
  }

  /**
   * 
   * @param isRefresh 
   * @returns 
   */
  getAuthorizationUrl(isRefresh: boolean): Observable<any> {
    let url = new Url("/account/authorization_code")
      .query("endpoint", isRefresh ? "/account/refresh" : "/account/create")

    return this.http.get<any>(url.build())
  }

  create(accounts: Array<ICustomer>) {
    let url = new Url("/account/create");

    return this.http.post<void>(url.build(), accounts);
  }

  /**
   * 
   * @param range 
   * @returns 
   */
  currencies(range: DateRange): Observable<ICostCurrencies> {
    let url = new Url("/account/currency_cost")
      .query('from', range.getFromFormatted())
      .query('to', range.getToFormatted())
    
    return this.http.get<ICostCurrencies>(url.build());
  }

  /**
   * 
   * @returns 
   */
  mcc(): Observable<any> {
    let url = new Url("/account/mcc")

    return this.http.get<any>(url.build());
  }

  /**
   * 
   * @param mccId 
   * @param code 
   * @returns 
   */
  refresh(mccId: string, code: string) {
    let url = new Url("/account/refresh")

    return this.http.post<void>(url.build(), {'mcc': mccId, 'code': code})
  }

}
