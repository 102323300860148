<div class="flex flex-col justify-center align-center text-center">

  <div class="flex align-center justify-center">
    <img src="assets/lemons.png" alt="lemons">
  </div>

  <div>
    <h1 class="font-black text-4xl text-red">Server Error!</h1>
    <h3 class="mt-2 italic">"Sometimes life gives you lemons, or server errors"</h3>

    <div class="mt-4">
      <mave-button type="secondary" (click)="back()" label="Go To Previous page"></mave-button>
    </div>
  </div>

</div>
