import { GraphCostFormatterBuilder } from "./formatters/graph-cost-formatter-builder";
import { GraphGeneralFormatter } from "./formatters/graph-general-formatter";
import { AdjustedAdCtrFormatter, AdjustedCPMFormatter, AdjustedCVRFormatter, AdjustedGraphRoiFormatterBuilder, AdjustedLanderCTRFormatter, IGraphFormatter, SyncFailedActiveAccountValueFormatter, SyncFailedValueFormatter, SyncSuccessRatioFormatter, SyncSuccessValueFormatter, GraphRoiFormatterBuilder, GraphFillFormatterBuilder } from "./formatters/igraph-formatter";
import { AccountCpmValueParser, AdCtrValueParser, AdjustedRoiGraphValueParser, CVRValueParser, GraphValueParser, LanderCtrValueParser, SyncFailedActiveAccountValueParser, SyncFailedValueParser, SyncSuccessRatioParser, SyncSuccessValueParser } from "./graph-value-parser";
import { IAccount } from "src/app/account/interfaces/account";

export class GraphFormatterBuilder {

  public static get(value: number, rawValue: any, parser: GraphValueParser, account: IAccount): IGraphFormatter {
    switch (parser.key()) {
      case "fill":
        return new GraphFillFormatterBuilder(rawValue, account);
      case "roi":
        return new GraphRoiFormatterBuilder(rawValue);
      case "adjustedRoi":
        return new AdjustedGraphRoiFormatterBuilder(value, <AdjustedRoiGraphValueParser> parser);
      case "cost":
        return new GraphCostFormatterBuilder(value, account);
      case "cvr":
        return new AdjustedCVRFormatter(value, <CVRValueParser> parser)
      case "adctr":
        return new AdjustedAdCtrFormatter(value, <AdCtrValueParser> parser)
      case "lctr":
        return new AdjustedLanderCTRFormatter(value, <LanderCtrValueParser> parser)
      case "cpm":
        return new AdjustedCPMFormatter(value, <AccountCpmValueParser> parser, account)
      case "failed":
        return new SyncFailedValueFormatter(value, <SyncFailedValueParser> parser)
      case "active_failed":
        return new SyncFailedActiveAccountValueFormatter(value, <SyncFailedActiveAccountValueParser> parser)
      case "succeeded":
        return new SyncSuccessValueFormatter(value, <SyncSuccessValueParser> parser)
        case "sync_success_ratio":
          return new SyncSuccessRatioFormatter(value, <SyncSuccessRatioParser> parser)
      default:
        return new GraphGeneralFormatter(value);
    }
  }

}
