<mv-card>
    <div header>
        <div class="flex items-center justify-between">
            <mv-daterangepicker [date]="range" (dates)="dateRangeChanged($event)"></mv-daterangepicker>
            <mv-title [size]="4">Success ratio's of synchronizations</mv-title> 
        </div>
    </div>

    <div body>
        <p-chart 
            *ngIf="!isLoadingGraph" 
            type="line" 
            [data]="metrics" 
            [options]="options" 
            [width]="'100%'" 
            [height]="'200px'"
            [plugins]="[hoverBackgroundColor]"></p-chart>

        <div class="sync-view__metrics">
            <div class="sync-view__metrics-label">
                <div class="sync-view__color-badge" style="background-color: #333333;"></div>
                Success ratio
            </div>
            <div class="sync-view__metrics-label">
                <div class="sync-view__color-badge" style="background-color: #004000;"></div>
                Succeeded Syncs
            </div>
            <div class="sync-view__metrics-label">
                <div class="sync-view__color-badge" style="background-color: #4C0013;"></div>
                Failed syncs on active accounts
            </div>          
        </div>
    </div>
</mv-card>

<div class="mt-3"></div>

<mv-card [table]="true">
    <div header>
        <form class="flex items-center justify-between" [formGroup]="filterForm">
            <div class="flex items-center">
                <mv-title class="mr-4" [size]="3">Synchronization</mv-title>
                <p-dropdown 
                    styleClass="mv-dropdown"
                    placeholder="Select account"
                    formControlName="account"
                    [filter]="true" 
                    filterBy="name" 
                    optionLabel="name" 
                    optionValue="id" 
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="32"
                    [showClear]="true"
                    [options]="accounts">
                </p-dropdown>
            </div>
            <div class="flex items-center">
                <div class="text-xs font-bold text-secondary mr-3">Only Failed</div>
                <p-inputSwitch formControlName="hasFailed"></p-inputSwitch>
            </div>
        </form>
    </div>

    <div body>
        <p-table 
        [value]="sync.results" 
        [lazy]="true"
        [rows]="limit"
        [paginator]="true"
        [totalRecords]="sync.count"
        (onLazyLoad)="paginate($event)"
        *ngIf="isSyncsLoaded">
            <ng-template pTemplate="header">
                <tr>
                    <th [width]="30"></th>
                    <th [width]="250">Name</th>
                    <th>Account</th>
                    <th>Timeframe</th>
                    <th [width]="200">Created At</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sync>
                <tr>
                    <td class="flex items-center">
                        <div class="material-symbols-outlined sync-view__hassucceed text-green-dark" *ngIf="sync.has_succeeded">task_alt</div>
                        <div class="material-symbols-outlined sync-view__hassucceed text-red" *ngIf="!sync.has_succeeded">error</div>
                    </td>
                    <td>{{ sync.name }}</td>
                    <td>{{ sync.account.name }}</td>
                    <td>{{ sync.day | date }}</td>
                    <td>{{ sync.created_at | date: 'medium':undefined:'en-BE' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="15" class="mv-table-empty">
                        <i class="material-symbols-outlined mv-table-empty__icon">search_off</i>
                        <div class="mv-table-empty__label">No Results</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</mv-card>