<div>

  <ga-comment-overview-dialog
    (submitted)="changeComment()"
    [type]="0"
    [(entity)]="selectedCommentEntity"
    [(open)]="commentDialogIsOpen">
  </ga-comment-overview-dialog>


    <ga-feature feature="account_statistics">
      <div default>
        <mv-card [hasHeader]="false">
          <div body>
            <div class="flex items-center">
              <div class="w-4/12 -my-3" *ngIf="showGraph">
                <p-chart *ngIf="!isLoadingGraph" type="line" [data]="graphData" [options]="graphOptions" [width]="'700px'" [height]="'150px'" [plugins]="[hoverBackgroundColor]"></p-chart>
              </div>
              <div ngClass="{'w-6/12': showGraph}" class="w-full pr-32">
                <ga-totals *ngIf="totals" [totals]="totals" [account]="account" [range]="range"></ga-totals>
              </div>
            </div>
          </div>
        </mv-card>
      </div>

      <div fallback>
        <div class="lg:flex lg:flex-row lg:justify-between">
          <div class="w-full">
            <mv-card [hasHeader]="false">
              <div class="flex items-center sm:flex-col lg:flex-row" body>
                <div class="sm:hidden lg:flex w-3/12 justify-end -my-6 mr-0 -ml-12 -mb-3" *ngIf="showGraph">
                    <p-chart *ngIf="!isLoadingGraph" type="line" [data]="graphData" [options]="graphOptions" [width]="'500px'" [height]="'150px'" [plugins]="[hoverBackgroundColor]"></p-chart>
                </div>

                <div [ngClass]="{'w-9/12': showGraph }" [class.w-full]="!showGraph">
                    <ganal-statistics [statistics]="statistics" [showRealRoi]="true" [showCostOverlay]="true"></ganal-statistics>
                </div>
              </div>
            </mv-card>
          </div>
        </div>
      </div>
    </ga-feature>

    <div class="h-4"></div>

    <mv-card [table]="true">
      <div header>
        <div class="flex flex-row items-center">
          <mv-title [size]="3">Account Overview</mv-title>
          <div class="ml-3">
            <mv-daterangepicker [date]="range" (dates)="dateRangeChanged($event)"></mv-daterangepicker>
          </div>
        </div>
      </div>

      <div body>
        <p-table [value]="accountMetrics" styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
                <tr>
                    <th [width]="1"></th>
                    <th>Name</th>
                    <th pSortableColumn="total_cost" class="text-right">Cost <p-sortIcon field="total_cost"/></th>
                    <th pSortableColumn="total_profit" class="text-right">Profit <p-sortIcon field="total_profit"/></th>
                    <th class="text-right">ROI</th>
                    <th pSortableColumn="total_conversions_tracked" class="text-right">Conv <p-sortIcon field="total_conversions_tracked"/></th>
                    <th pSortableColumn="total_revenue" class="text-right">Revenue <p-sortIcon field="total_revenue"/></th>
                    <th class="text-right">US CPM</th>
                    <th class="text-right">Last Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-account>
                <tr>
                    <td>
                      <div class="flex items-center">
                        <div
                          *ngIf="account.id !== 0"
                          [pTooltip]="account.status === 2 ? 'Active' : 'Inactive'"
                          class="rounded-full h-4 w-4"
                          [class.bg-gray]="account.status !== 4 && account.status !== 2"
                          [class.bg-green]="account.status === 2"
                          [class.bg-red]="account.status === 4">
                        </div>
                        <span>{{ (1 - (account.total_failed_syncs / account.total_succeeded_syncs)) | percent }}</span>
                        <a class="ml-1" *ngIf="!account.is_correct_script_version" [href]="scriptUrl" target="_blank">
                          <i class="block material-symbols-outlined text-red">error</i>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div class="flex justify-between">
                        <div class="flex items-center" *ngIf="account.id === 0">
                            <span class="font-bold">Unknown</span>
                        </div>
                        <div class="flex items-center" *ngIf="account.id !== 0">
                          <a [routerLink]="['/campaign/account/' + account.id]" class="font-bold w-64">{{ account.name }}</a>
                          <a [routerLink]="['/campaign/account/' + account.id]" class="text-primary-light font-bold"><span class="text-right">{{ account.active_product }}</span></a>
                        </div>
                        <div class="flex items-center -m-2">
                          <div pBadge [badgeDisabled]="getComment(account.id).count === 0" [value]="getComment(account.id).count">
                              <span class="ga-index-campaign-view-edit material-symbols-outlined" (click)="openCommentDialog(account.id)">
                                  mode_comment
                              </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="font-mono">{{ (account.total_cost | currency: account.currency:undefined:'1.0-0') ?? '-' }}</td>
                    <td class="font-mono">{{ (account.total_profit | currency: account.currency:undefined:'1.0-0') ?? '-' }}</td>
                    <td class="font-mono">
                      <span class="ga-highlight" [ngClass]="{'ga-highlight-red': ((account.total_profit / account.total_cost) < 0) && account.status !== 4}">
                        {{ ((account.total_profit / account.total_cost) | percent:'1.0') ?? '-%' }}
                      </span>
                    </td>
                    <td class="font-mono">
                      <ga-conv [entity]="account"></ga-conv>
                    <td class="font-mono">{{ (account.total_revenue | currency: account.currency:undefined:'1.0-0') ?? '-' }}</td>
                    <td class="font-mono">
                      <span class="ga-highlight" [ngClass]="{'ga-highlight-red': ((account.us_cpm >= 20) && account.status !== 4), 'ga-highlight-orange': ((account.us_cpm > 16 && account.us_cpm < 20) && account.status !== 4)}">
                        {{ (account.us_cpm | currency: account.currency:undefined:'1.1-1') ?? '-%' }}
                      </span>
                    </td>
                    <td class="font-mono">{{ (account.last_status_change | date: 'd MMM') ?? '-' }}</td>
                </tr>
            </ng-template>
        </p-table>
      </div>

      <div footer>
        <span class="font-bold text-secondary text-xs"></span>
      </div>

    </mv-card>

  </div>
