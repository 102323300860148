import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'mave-overlay-button',
  standalone: true,
  templateUrl: './overlay-button.component.html',
  styleUrls: ['./overlay-button.component.css']
})
export class OverlayButtonComponent implements OnInit {
  @Input() link!: string;
  @Input() icon!: string;
  @Output() action = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  pressButton() {
    this.action.emit();
  }

}
