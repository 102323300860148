import { Pipe, PipeTransform } from '@angular/core';
import { Ranges } from '../ui/daterangepicker/ranges';

@Pipe({
  standalone: true,
  name: 'summaries'
})
export class SummariesPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value == "") {
      return "No ranges"
    }

    return value.split(", ")
      .map(range =>
          Ranges.getMapping()[parseInt(range)].split('_')
          .map(item => item.toLowerCase())
          .map(item => item.substring(0, 1).toUpperCase() + item.substring(1, item.length))
          .join(' ')
        )
      .join(", ");
  }

}
