import { Component, Input, OnInit } from '@angular/core';
import { GraphOptions } from '../../classes/graph/graph-options';
import { GraphBuilder } from '../../classes/graph/graph-builder';
import { GraphPainter } from '../../classes/graph/graph-painter';
import { GraphValueParser } from '../../classes/graph/graph-value-parser';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChartModule } from 'primeng/chart';
import { NgIf } from '@angular/common';
import { IAccount } from 'src/app/account/interfaces/account';
import { IGraphFormatter } from '../../classes/graph/formatters/igraph-formatter';

@Component({
  selector: 'ganal-inline-graph',
  standalone: true,
  imports: [
    NgIf,
    OverlayPanelModule,
    ChartModule,
  ],
  templateUrl: './inline-graph.component.html',
  styleUrls: ['./inline-graph.component.scss']
})
export class InlineGraphComponent implements OnInit {

  @Input() data!: any;
  @Input() formattedValue!: string;
  @Input() painter!: GraphPainter;
  @Input() parser!: GraphValueParser;
  @Input() formatter!: IGraphFormatter;
  @Input() dataKey: string = "";
  @Input() account!: IAccount;

  public smallGraphOptions!: any;
  public bigGraphOptions!: any;
  public graphData: any;

  constructor() { }

  ngOnInit(): void {
    if (this.parser.key() === 'roi') {
      this.smallGraphOptions = GraphOptions.getSmallRoiGraphOptions(this.formatter);
      this.bigGraphOptions = GraphOptions.getBigRoiGraphOptions(this.account, this.formatter);
    }

    if (this.parser.key() === 'fill') {
      this.smallGraphOptions = GraphOptions.getSmallFillGraphOptions(this.formatter);
      this.bigGraphOptions = GraphOptions.getBigFillGraphOptions(this.account, this.formatter);
    }
    
    
  
    this.graphData = new GraphBuilder()
      .push(this.data, this.painter, this.parser)
      .build(GraphBuilder.labelsFromGraphData(this.data));
  }

}
