import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, NgModel, ReactiveFormsModule, UntypedFormArray, Validators } from '@angular/forms';
import { startOfToday, startOfYesterday, subWeeks } from 'date-fns';
import { Dialog, DialogModule } from 'primeng/dialog';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { IUser } from 'src/app/auth/classes/user';
import { DateRange } from 'src/app/lib/classes/daterange';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { DaterangepickerComponent } from 'src/app/lib/ui/daterangepicker/daterangepicker.component';
import { InputComponent } from 'src/app/lib/ui/input/input.component';
import { InputDirective } from 'src/app/lib/ui/input/input.directive';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { Userv4Service } from 'src/app/user/services/userv4.service';
import { Reportsv4Service } from '../../services/reportsv4.service';
import { ValidationComponent } from 'src/app/lib/ui/validation/validation.component';
import { tr } from 'date-fns/locale';
import { IReport, Report } from '../../classes/report';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'ga-create-report-dialog',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    TitleComponent,
    InputDirective,
    DaterangepickerComponent,
    DropdownModule,
    ButtonComponent,
    JsonPipe,
    InputSwitchModule,
    InputComponent,
    CheckboxModule,
    ValidationComponent
  ],
  templateUrl: './create-report-dialog.component.html',
  styleUrl: './create-report-dialog.component.css',
  encapsulation: ViewEncapsulation.None
})
export class CreateReportDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog!: Dialog;

  range: DateRange = new DateRange(subWeeks(startOfToday(), 1), startOfYesterday());

  @Input() open!: boolean;
  @Input() report: IReport = Report.noop(this.range.from, this.range.to);

  @Output() openChange = new EventEmitter<boolean>();
  @Output() reportChange = new EventEmitter<IReport>();
  @Output() submitted = new EventEmitter<void>();
  @Output() generating = new EventEmitter<void>();



  fields = [
    { label: 'Budget', value: 'budget' },
    { label: 'Cost', value: 'total_cost' },
    { label: 'Revenue', value: 'total_revenue' },
    { label: 'Profit', value: 'total_profit' },
    { label: 'Name', value: 'name' },
    { label: 'Creator', value: 'creator__pk' },
    { label: 'Uploader', value: 'uploader__pk' },
    { label: 'Is Serving Ads', value: 'is_serving_ads' },
  ]

  types = [
    { label: '>', value: 'gt' },
    { label: '>=', value: 'gte' },
    { label: '<', value: 'lt' },
    { label: '<=', value: 'lte' },
    { label: '=', value: 'iexact' },
    { label: 'contains', value: 'icontains' },
  ]

  nameTypes = [{ label: 'contains', value: 'icontains' }]
  userTypes = [{ label: '=', value: 'iexact' }]

  userValues: IUser[] = []

  createReportForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    range_from: [this.range.getFrom(), Validators.required],
    range_to: [this.range.getTo(), Validators.required],
    filters: this.fb.array([
      this.fb.group({
        field: ['', Validators.required],
        operator: ['', Validators.required],
        value: ['', Validators.required]
      })
    ]),
    with_zero_cost: [false, Validators.required]
  })

  isCreatingReport = false;

  constructor(private fb: FormBuilder, private userv4: Userv4Service, private reportv4: Reportsv4Service) { }

  ngOnInit(): void {
    this.userv4.all().subscribe((users: Array<IUser>) => {
      this.userValues = users;
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['report'] && changes['report'].currentValue !== undefined) {
      console.log('changing report')
      this.setReport(changes['report'].currentValue)
    }
  }

  ngAfterViewInit(): void {
    this.dialog.visibleChange.subscribe(() => {
      this.openChange.emit(false);
    })
  }

  formatFilterValue(field: string, value: string) {
    switch (field) {
      case 'is_serving_ads':
        return value === '1';
      case 'name':
        return value;
      default:
        return parseInt(value) || '';
    }
  }

  setReport(report: IReport) {
    this.createReportForm = this.fb.group({
      name: [report.name, Validators.required],
      range_from: [report.range_from, Validators.required],
      range_to: [report.range_to, Validators.required],
      filters: this.fb.array(report.filters.map(item =>
        this.fb.group({
          field: [item.field, Validators.required],
          operator: [item.operator, Validators.required],
          value: [this.formatFilterValue(item.field, item.value), Validators.required]
        })
      )),
      with_zero_cost: [false, Validators.required]
    })
  }

  close(event: Event) {
    this.dialog.close(event);
  }

  toggle(isOpen: boolean) {
    this.openChange.emit(isOpen);
  }

  dateRangeChanged(range: DateRange) {
    this.range = range;
    this.createReportForm.get('range_from')?.setValue(range.getFrom());
    this.createReportForm.get('range_to')?.setValue(range.getTo());
  }

  get filters() {
    return this.createReportForm.controls['filters'] as UntypedFormArray
  }

  get name() {
    return this.createReportForm.get('name')
  }

  get range_from() {
    return this.createReportForm.get('range_from')
  }

  get range_to() {
    return this.createReportForm.get('range_to')
  }

  get with_zero_cost() {
    return this.createReportForm.get('with_zero_cost')
  }


  isNormal(value: string) {
    let notNormal = ['creator__pk', 'uploader__pk', 'name', 'is_serving_ads'];

    return !notNormal.includes(value)
  }

  toggleIsActiveSwitch(index: number, event: any) {
    let filters = this.createReportForm.get('filters') as FormArray
    filters.at(index).patchValue(event.checked ? 'true' : 'false');
  }

  addFilter(event: Event) {
    event.preventDefault();
    this.filters.push(
      this.fb.group({
        field: new FormControl('', { validators: [Validators.required] }),
        operator: new FormControl('', { validators: [Validators.required] }),
        value: new FormControl('', { validators: [Validators.required] }),
      })
    );
  }

  removeFilter(event: Event, index: number) {
    event.preventDefault();
    this.filters.removeAt(index)
  }

  autoselect(event: DropdownChangeEvent, index: number) {
    let selected = event.value

    if (selected === 'creator__pk' || selected === 'uploader__pk') {
      this.filters.at(index).get('operator')?.setValue('iexact')
    }

    if (selected === 'name') {
      this.filters.at(index).get('operator')?.setValue('contains')
    }

    if (selected === 'is_serving_ads') {
      this.filters.at(index).get('operator')?.setValue('iexact')
    }
  }

  createReport() {
    if (this.createReportForm.invalid) {
      return this.createReportForm.markAllAsTouched()
    }

    this.reportv4.save(this.createReportForm.value).subscribe(() => {
      this.submitted.emit()
    });

    this.close(new Event("save"))
    this.generating.emit()
  }

}
