import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Dialog, DialogModule } from 'primeng/dialog';
import { CommentOverviewComponent } from '../comment-overview/comment-overview.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { IComment } from '../../classes/comment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Commentv4Service } from '../../services/commentv4.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputDirective } from 'src/app/lib/ui/input/input.directive';

@Component({
  selector: 'ga-comment-overview-dialog',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    CommentOverviewComponent,
    TitleComponent,
    ButtonComponent,
    InputDirective,
    DatePipe,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './comment-overview-dialog.component.html',
  styleUrl: './comment-overview-dialog.component.css'
})
export class CommentOverviewDialogComponent {
  @ViewChild('dialog') dialog!: Dialog;

  @Input() entity!: number;
  @Input() open!: boolean;
  @Input() type!: number;

  @Output() openChange = new EventEmitter<boolean>();
  @Output() entityChange = new EventEmitter<number>();
  @Output() submitted = new EventEmitter<void>();

  text: string = ""
  comments: Array<IComment> = []

  /**
   * 
   * @param commentv4 
   * @param confirmation 
   */
  constructor(private commentv4: Commentv4Service, private confirmation: ConfirmationService) {}

  /**
   * 
   */
  ngAfterViewInit(): void {
    this.dialog.visibleChange.subscribe((isOpen) => {
      this.openChange.emit(false);
    })

    this.comments = []
  }

  load() {
    this.comments = []
    this.commentv4.load(this.type, this.entity).subscribe((comments: Array<IComment>) => {
      this.comments = comments;
      this.text = '';
    })
  }

  /**
   * 
   * @param event 
   */
  close(event: Event) {
    this.dialog.close(event);
  }

  /**
   * 
   * @param isOpen 
   */
  toggle(isOpen: boolean) {
    this.openChange.emit(isOpen);
  }

  /**
   * 
   */
  send() {
    this.commentv4.send(this.type, this.entity, this.text).subscribe(() => this.load())
  }

  /**
   * 
   * @param commentId 
   */
  delete(commentId: number) {
    this.confirmation.confirm({
      header: 'Delete comment',
      icon: 'delete',
      key: 'delete',
      message: 'Do you really want to delete this comment?',
      accept: () => this.commentv4.delete(commentId).subscribe(() =>
        this.commentv4.load(this.type, this.entity).subscribe((comments: Array<IComment>) => {
          this.comments = comments;
        })
      )
    })
  }

}
