<mv-card [table]="true">
    <div header>
        <div class="flex items-center justify-between">
            <mv-title [size]="3">Parentals</mv-title>
            <ga-sync [accountId]="account.id" type="parentals"></ga-sync>
        </div>
    </div>
    <div body>
        <p-table [value]="parentals">
            <ng-template pTemplate="header">
                <tr class="border-b border-gray-light">
                    <th></th>
                    <th class="text-left border-l border-gray" colspan="4" *ngFor="let range of ranges">
                        <span class="text-secondary font-bold capitalize mr-3">{{ range | rangeLabel }}</span>
                        <span class="text-secondary-light font-bold">{{ range.toString() }}</span>
                    </th>
                </tr>

                <tr>
                    <th class="border-r border-gray w-96">Name</th>

                    <ng-container *ngFor="let range of ranges">
                        <th [pSortableColumn]="'roi_range_' + range" class="text-right">~ROI <p-sortIcon [field]="'roi_range_' + range"></p-sortIcon></th>
                        <th [pSortableColumn]="'cost_range_' + range" class="text-right">Cost <p-sortIcon [field]="'cost_range_' + range"></p-sortIcon></th>
                        <th [pSortableColumn]="'conversions_range_' + range" class="text-right">Conv <p-sortIcon [field]="'conversions_range_' + range"></p-sortIcon></th>
                        <th class="text-right border-r border-gray">CTR</th>
                    </ng-container>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-parental>
                <tr>
        
                    <td class="border-r border-gray w-96">
                        <p-inputSwitch
                            styleClass="-my-2 mr-2" 
                            [class.red]="parental.status && hasStatus(parental)"
                            [class.yellow]="!parental.status && hasStatus(parental)"
                            [ngModel]="parental.status && !hasStatus(parental) || !parental.status && hasStatus(parental)" 
                            (ngModelChange)="changeStatus(parental)">
                        </p-inputSwitch>

                        <span class="font-bold">{{ rangeParentals.get(ranges[0].toString())?.get(parental.id)?.name }}</span>
                    </td>
    
                    <ng-container *ngFor="let range of ranges">
                        <td class="font-mono border-l border-gray">
                            <span 
                            gaGrade
                            [roi]="parental['roi_range_' + range]"
                            [conversions]="parental['conversions_tracked_range_' + range]"
                            >
                            <!-- {{ (estimateRoi(range.toString(), (rangeAges.get(range.toString())?.get(age.id)?.total_cost ?? 0), (rangeAges.get(range.toString())?.get(age.id)?.total_conversions ?? 0)) | percent: '1.0-0') ?? '-%' }} -->
                            {{ (parental['roi_range_' + range] | percent: '1.0-0') ?? '-%' }}    
                        </span>
                        </td>
                        <td class="font-mono">
                            {{ (parental['cost_range_' + range] | currency: account.currency:undefined:'1.0') ?? '-' }} <span class="text-gray-dark mx-1">|</span> <span class="text-gray-dark float-right"> {{ (costDistribution(range.toString(), parental['cost_range_' + range]) | percent:'1.0-0') ?? '-%' }}</span>
                        </td>
                        <td class="font-mono">
                            <span class="font-bold">{{ (parental['conversions_range_' + range] | number:'1.0-0') ?? '-' }}</span>
                        </td>
                        <td class="font-mono">
                            {{ (((parental['clicks_range_' + range] ?? 0) / (parental['impressions_range_' + range] ?? 0)) | percent: '1.2') ?? '-%' }}
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </div>
</mv-card>