
<ng-container *ngFor="let account of statusAccounts">
    <div class="mt-6"></div>
    <mv-card [table]="true">
        <div header>
            <div class="flex items-center justify-between">
                <div>
                    <mv-title [size]="3">{{ account.name }}</mv-title>
                </div>
                <div class="flex">
                    <mv-button 
                        (click)="execute(account.id)"
                        *ngIf="selectedAutomations[account.id] !== undefined && selectedAutomations[account.id].length > 0" 
                        variant="primary-transparent"
                        [icon]="'start'" 
                        [label]="'Execute selected (' + selectedAutomations[account.id].length + ')'">
                    </mv-button>
                    <mv-button (click)="retryAll(account.id)" [icon]="'refresh'" pTooltip="Retry all status changes with errors" tooltipPosition="top" variant="positive-transparent" label="Retry all"></mv-button>
                    <mv-button [icon]="'clear_all'" pTooltip="Remove all uncompleted status changes" tooltipPosition="top" variant="danger-transparent" label="Remove all" (click)="deleteAll(account)"></mv-button>
                    <mv-button *ngIf="hasCsv(account.id)" class="mr-3" variant="primary-transparent" [icon]="'copy_all'" [loading]="isCopyingCsv[account.id]" label="Copy Changes" (click)="copy(account.id)"></mv-button>
                    <mv-button *ngIf="hasCsv(account.id)" [icon]="'check'" variant="primary" label="Verify copy changes" [loading]="isVerifyingCsv[account.id]" (click)="verify(account.id)"></mv-button>
                </div>
            </div>
        </div>
        <div body>
            <p-table
                [value]="account.statuses"
                rowGroupMode="subheader" 
                groupRowsBy="account.id"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="w-3"></th>
                        <th>Date</th>
                        <th>User</th>
                        <th>Error</th>  
                        <th>Item</th>
                        <th>Campaign</th>
                        <th>Type</th>
                        <th>Operation</th>
                        <th>Value</th>
                        <th [width]="70">Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-status>
                    <tr class="font-bold">
                        <td class="w-4">
                            <div class="flex" *ngIf="status.is_automation">
                                <p-checkbox 
                                    (onChange)="select(account.id, status.id)"
                                    [binary]="true" 
                                    [ngModel]="selectedAutomations[account.id]?.includes(status.id) ?? false">
                                </p-checkbox>
                                <div class="material-symbols-outlined text-primary-light ml-2">robot</div>
                            </div>
                        </td>
                        <td class="w-1/12">
                            <mave-notify-badge [text]="''" [showCount]="false" [showRedIndicator]="status.is_errored">
                                <div class="pl-3">{{ status.created_at | date: 'd MMM - HH:mm' }}</div>
                            </mave-notify-badge>
                        </td>
                        <td clas="w-1/12">{{ status.author.name }}</td>
                        <td class="font-bold text-sm text-red p-1 w-2/12">{{ status.error }}</td>
                        <td class="w-2/12"><a [routerLink]="['/ad/' + status.account.id + '/account/' + status.campaign_id + '/adgroup']"><span *ngIf="status.campaign.name !== status.description">{{ status.description }}</span></a></td>
                        <td class="w-4/12"><a [routerLink]="['/ad/' + status.account.id + '/account/' + status.campaign_id + '/adgroup']">{{ status.campaign.name }}</a></td>
                        <td class="w-1/12">{{ status.type | type }}</td>
                        <td class="w-1/12">{{ status.operation | operation }}<span *ngIf="status.operation === 3">: <span class="text-primary-light">{{ status.description }}</span></span></td>
                        <td class="w-4/12">
                            {{ status.old_value | statusValue: status.operation:status.account.currency:undefined }} → <span class="font-bold text-primary-light">{{ status.value | statusValue: status.operation:status.account.currency:status.old_value }}</span>
                        </td>
                        <td class="w-88 text-right">
                            <div class="flex -m-1 text-right">
                                <mv-button *ngIf="status.is_errored" class="-mr-3" variant="positive-transparent" icon="refresh" label="Retry" (click)="retry(status.id)"></mv-button>
                                <mv-button variant="danger-transparent" icon="delete" label="Delete" (click)="delete(status.id)"></mv-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="15" class="mv-table-empty">
                            <i class="material-symbols-outlined mv-table-empty__icon">grocery</i>
                            <div class="mv-table-empty__label">No statuses pending</div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </mv-card>
</ng-container>

<div class="mt-4"></div>

<mv-card [table]="true">
    <div header>
        <div class="flex items-center">
            <mv-title [size]="3">Daily statuses</mv-title>

            <form [formGroup]="filterForm">
                <p-dropdown 
                    styleClass="mv-dropdown ml-4"
                    placeholder="Select users"
                    formControlName="user"
                    optionLabel="name" 
                    optionValue="id" 
                    [showClear]="true"
                    [options]="users"
                    >
                </p-dropdown>
                <p-dropdown 
                    styleClass="mv-dropdown ml-4"
                    placeholder="Select account"
                    formControlName="account"
                    [filter]="true" 
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="32"
                    filterBy="name" 
                    optionLabel="name" 
                    optionValue="id" 
                    [showClear]="true"
                    [options]="accounts">
                </p-dropdown>
                <p-calendar 
                    styleClass="mv-calendar ml-4"
                    [selectOtherMonths]="true" 
                    formControlName="date"
                    dateFormat="dd/mm/yy"></p-calendar>
            </form>
        </div>
    </div>
    <div body>
        <p-table
            [value]="dailyStatuses"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th class="w-3"></th>
                    <th>Date</th>
                    <th>User</th>
                    <th>Status</th>
                    <th>Account</th>
                    <th>Item</th>
                    <th>Type</th>
                    <th>Operation</th>
                    <th>Value</th>
                    <th [width]="70">Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-status>
                <tr class="font-bold">
                    <td class="w-4"></td>
                    <td class="w-1/12">{{ status.created_at | date: 'd MMM - HH:mm' }}</td>
                    <td class="w-1/12">{{ status.author.name }}</td>
                    <td class="w-1/12">{{ status.state | state }}</td>
                    <td class="w-1/12">{{ status.account.name }}</td>
                    <td class="w-4/12"><a [routerLink]="['/ad/' + status.account.id + '/account/' + status.campaign_id + '/adgroup']">{{ status.campaign.name }}</a></td>
                    <td class="w-1/12">{{ status.type | type }}</td>
                    <td class="w-1/12">{{ status.operation | operation }}<span *ngIf="status.operation === 3">: <span class="text-primary-light">{{ status.description }}</span></span></td>
                    <td class="w-5/12">
                        {{ status.old_value | statusValue: status.operation:status.account.currency:undefined }} → <span class="font-bold text-primary-light">{{ status.value | statusValue: status.operation:status.account.currency:status.old_value }}</span>
                    </td>
                    <td class="w-88 text-right">
                        <div class="flex -m-1 text-right">
                            <mv-button variant="danger-transparent" icon="delete" label="Delete" (click)="delete(status.id)"></mv-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="groupheader" let-status>
                <tr>
                    <td colspan="14" class="ga-status__groupheader">
                        <span class="font-bold">Account </span> 
                        <span class="text-primary">{{ status.account.name }}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="15" class="mv-table-empty">
                        <i class="material-symbols-outlined mv-table-empty__icon">history</i>
                        <div class="mv-table-empty__label">No statuses executed this day</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</mv-card>