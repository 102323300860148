export interface IReport {
    id: number;
    name: string;
    type: number; // maybe enum?
    range_from?: Date
    range_to?: Date
    filters: IReportFilter[];
    campaigns: IReportCampaign[]
    by: string;
    status: number; // maybe enum?
    generated_at: string;
    report_campaign_count: number;
    with_zero_cost: boolean;
}

export interface IReportPagination {
    count: number;
    next?: string;
    previous?: string;
    results: IReport[];
}

export interface IReportFilter {
    field: string;
    operator: string;
    value: string;
}

export interface IReportCampaign {
    name: string;
    budget: string;
    clicks: number;
    conversions: number;
    conversions_tracked: number;
}

export class Report {

    static noop(from: Date, to: Date): IReport {
        return {
            id: 0,
            name: '',
            type: 0,
            range_from: from,
            range_to: to,
            filters: [
                {field: '', operator: '', value: ''}
            ],
            campaigns: [],
            by: '',
            status: 0,
            generated_at: '',
            report_campaign_count: 0,
            with_zero_cost: false
        }
    }
}