import { Routes } from '@angular/router';
import { HomeViewComponent } from './home-view/home-view.component';
import { RecentCampaignsViewComponent } from './campaign/views/recent-campaigns-view/recent-campaigns-view.component';
import { IndexAdViewComponent } from './ad/views/index-ad-view/index-ad-view.component';
import { IndexCampaignViewComponent } from './campaign/views/index-campaign-view/index-campaign-view.component';
import { NotFoundComponent } from './lib/components/not-found/not-found.component';
import { IndexStatusViewComponent } from './status/views/index-status-view/index-status-view.component';
import { IndexSyncViewComponent } from './sync/views/index-sync-view/index-sync-view.component';
import { ApiKeysComponent } from './admin/views/api-keys/api-keys.component';
import { IndexAccountViewComponent } from './account/views/index-account-view/index-account-view.component';
import { AuthorizeComponent } from './account/views/oauth/authorize/authorize.component';
import { RefreshComponent } from './account/views/oauth/refresh/refresh.component';
import { LoginComponent } from './user/views/login/login.component';
import { UserProfileComponent } from './user/views/user-profile/user-profile.component';
import { featureIsActivated } from './lib/classes/feature';
import { IndexTemplateViewComponent } from './template/views/index-template-view/index-template-view.component';
import { CreateTemplateViewComponent } from './template/views/create-template-view/create-template-view.component';
import { IndexComponent } from './template/views/legacy/index/index.component';
import { CreateComponent } from './template/views/legacy/create/create.component';
import { IndexDailyReportsViewComponent } from './daily-reports/views/index-daily-reports-view/index-daily-reports-view.component';
import { IndexReportViewComponent } from './reports/views/index-report-view/index-report-view.component';
import { ShowReportViewComponent } from './reports/views/show-report-view/show-report-view.component';
import { Error500Component } from './errors/error500/error500.component';
import { Error403Component } from './errors/error403/error403.component';
import { IndexPermissionsViewComponent } from './account/views/index-permissions-view/index-permissions-view.component';


let buildRoutes: Routes = [
  { path: '', title: 'Ganal: Home', component: HomeViewComponent },
  { path: 'campaign/account/:id', title: 'Ganal: Campaigns', component: IndexCampaignViewComponent },
  { path: 'campaign/recent', component: RecentCampaignsViewComponent },
  { path: 'ad/:accountId/account/:campaignId/adgroup', component: IndexAdViewComponent },
  { path: 'sync', component: IndexSyncViewComponent },
  { path: 'admin/api_keys', component: ApiKeysComponent },
  { path: 'account', component: IndexAccountViewComponent },
  { path: 'account/create', component: AuthorizeComponent },
  { path: 'account/refresh', component: RefreshComponent },
  { path: 'account/permissions', component: IndexPermissionsViewComponent },
  { path: 'user/login', component: LoginComponent },
  { path: 'user/profile', component: UserProfileComponent },
  { path: 'status', component: IndexStatusViewComponent },
  { path: 'daily-reports', component: IndexDailyReportsViewComponent },
  { path: 'reports', component: IndexReportViewComponent },
  { path: 'reports/report/:id', component: ShowReportViewComponent },
  { path: 'errors/500', component: Error500Component},
  { path: 'errors/403', component: Error403Component}

  // { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  // { path: 'sync', loadChildren: () => import('./sync/sync.module').then(m => m.SyncModule) },
  // { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
  // { path: 'template', loadChildren: () => import('./template/template.module').then(m => m.TemplateModule) },
  // { path: 'status', loadChildren: () => import('./status/status.module').then(m => m.StatusModule) },
  // { path: 'daily-reports', loadChildren: () => import('./daily-reports/daily-reports.module').then(m => m.DailyReportsModule) },
  // { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  // { path: 'errors', loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule) },
  // { path: '**', component: NotFoundComponent }
];

if (featureIsActivated('new_templates')) {
  buildRoutes.push({ path: 'template', component: IndexTemplateViewComponent })
  buildRoutes.push({ path: 'template/create', component: CreateTemplateViewComponent })
} else {
  buildRoutes.push({ path: 'template', component: IndexComponent })
  buildRoutes.push({ path: 'template/create', component: CreateComponent })
}

buildRoutes.push({ path: '**', component: NotFoundComponent }) // Should be here or it gives 404 for pages you want to show

export const routes = buildRoutes;
