<div class="pt-24 sm:w-100 lg:w-1/4 md:px-0 lg:px-24 mx-auto">
  <mave-card [footer]="false">
    <div header>
      <mave-sub-title text="Login"></mave-sub-title>
    </div>

    <form [formGroup]="loginForm" (submit)="login()">
      <div>
        <mave-textbox label="E-mail" name="email" [control]="email">
          <input type="text" formControlName="email" maveInput>
        </mave-textbox>
      </div>
      <div class="mt-3">

        <mave-textbox label="Password" name="password" [control]="password">
          <input type="password" formControlName="password" maveInput>
        </mave-textbox>

        <span class="text-xs text-red font-bold" *ngIf="isUnauthorized">Your username or password is wrong.</span>
      </div>
      <div class="mt-3">
        <mave-button label="Login" [loading]="isSubmitting" [disabled]="!loginForm.valid || isSubmitting" type="submit">
        </mave-button>
      </div>
    </form>
  </mave-card>
</div>
