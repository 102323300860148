import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ButtonComponent } from '../../../mavejs/form/button/button.component';

@Component({
    selector: 'ganal-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
    imports: [ButtonComponent]
})
export class NotFoundComponent implements OnInit {

  constructor(private location: Location) { }

  ngOnInit(): void {
  }

  back() {
    this.location.historyGo(-2);
  }

}
