import { Component, Input } from '@angular/core';
import { ConversionDirective } from '../../directives/conversion.directive';

@Component({
  selector: 'ga-conv',
  standalone: true,
  imports: [
    ConversionDirective
  ],
  templateUrl: './conv.component.html',
  styleUrl: './conv.component.css'
})
export class ConvComponent {
  @Input() entity: any
  @Input() tracked: string = 'total_conversions_tracked'
  @Input() google: string = 'total_conversions'
}