import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateService } from '../../../services/template.service';
import { ITemplate, ITemplateLegacy } from 'src/app/template/classes/template';
import { Templatev4Service } from 'src/app/template/services/templatev4.service';
import { TemplateSaveComponent } from '../../../forms/template-save/template-save.component';
import { SubTitleComponent } from '../../../../mavejs/text/sub-title/sub-title.component';
import { CardComponent } from '../../../../mavejs/base/card/card.component';

@Component({
    selector: 'ganal-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss'],
    standalone: true,
    imports: [CardComponent, SubTitleComponent, TemplateSaveComponent]
})
export class CreateComponent implements OnInit {

  isSubmitting = false;

  constructor(private router: Router, private templatev4: Templatev4Service) { }

  ngOnInit(): void {
  }

  submitTemplate(template: ITemplate) {
    this.isSubmitting = true;
    this.templatev4.createTemplate(template).subscribe(() => {
      this.isSubmitting = false;
      this.router.navigateByUrl("/template")
    });
  }

}
