import { Component, OnInit, ViewChild } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { ISecret } from '../../interfaces/isecret';
import { SecretService } from '../../services/secret.service';
import { DeleteModalComponent } from 'src/app/mavejs/ui/delete-modal/delete-modal.component';
import { ModalComponent } from 'src/app/mavejs/ui/modal/modal.component';
import { OverlayButtonComponent } from '../../../mavejs/form/overlay-button/overlay-button.component';
import { ActionComponent } from '../../../mavejs/base/action/action.component';
import { ListItemComponent } from '../../../mavejs/ui/list-item/list-item.component';
import { LoaderComponent } from '../../../mavejs/http/loader/loader.component';
import { ListComponent } from '../../../mavejs/ui/list/list.component';
import { SubTitleComponent } from '../../../mavejs/text/sub-title/sub-title.component';
import { CardComponent } from '../../../mavejs/base/card/card.component';
import { DeleteModalComponent as DeleteModalComponent_1 } from '../../../mavejs/ui/delete-modal/delete-modal.component';
import { NgIf, NgFor } from '@angular/common';
import { ButtonComponent } from '../../../mavejs/form/button/button.component';
import { InputDirective } from '../../../mavejs/form/input.directive';
import { FormsModule } from '@angular/forms';
import { TextboxComponent } from '../../../mavejs/form/textbox/textbox.component';
import { ModalComponent as ModalComponent_1 } from '../../../mavejs/ui/modal/modal.component';

@Component({
    selector: 'ganal-api-keys',
    templateUrl: './api-keys.component.html',
    styleUrls: ['./api-keys.component.scss'],
    standalone: true,
    imports: [ModalComponent_1, TextboxComponent, FormsModule, InputDirective, ButtonComponent, NgIf, DeleteModalComponent_1, CardComponent, SubTitleComponent, ListComponent, LoaderComponent, NgFor, ListItemComponent, ActionComponent, OverlayButtonComponent]
})
export class ApiKeysComponent implements OnInit {
  @ViewChild('deleteSecretModal') deleteSecretModal!: DeleteModalComponent;
  @ViewChild('generateSecretModal') generateSecretModal!: ModalComponent;

  currentSecret!: ISecret
  currentSecretForDeletion!: ISecret;
  isLoadingSecrets: boolean = true;

  secrets!: Array<ISecret>;

  secretName: string = "Key";

  constructor(private clipboardApi: ClipboardService, private secret: SecretService) { }

  ngOnInit(): void {
    this.getSecrets();
  }

  getSecrets() {
    this.isLoadingSecrets = true;
    this.secret.getSecrets().subscribe((secrets: Array<ISecret>) => {
      this.secrets = secrets;
      this.isLoadingSecrets = false;
      this.currentSecret = secrets[0];
    });
  }

  copyKey() {
    this.clipboardApi.copyFromContent(this.currentSecret.key);
  }

  generate(name: string) {
    this.secret.generateSecret(name).subscribe((secret: ISecret) => {
      this.currentSecret = secret;

      this.getSecrets();
      this.generateSecretModal.closeModal();
    });
  }

  selectSecret(secret: ISecret) {
    this.currentSecret = secret;
  }

  openDeleteSecretModal(secret: ISecret) {
    this.currentSecretForDeletion = secret;
    this.deleteSecretModal.openModal();
  }

  openSecretGenerationModal() {
    this.generateSecretModal.openModal();
  }

  deleteSecret(secret: ISecret) {
    this.secret.deleteSecret(secret).subscribe(() => {
      this.getSecrets();
      this.deleteSecretModal.closeModal();
    });
  }

}
