import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import { CardComponent } from 'src/app/lib/ui/card/card.component';
import { TitleComponent } from 'src/app/lib/ui/title/title.component';
import { LoaderComponent } from 'src/app/lib/ui/loader/loader.component';
import { NgFor, NgIf } from '@angular/common';
import { ButtonComponent } from 'src/app/lib/ui/button/button.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { FeatureService, IFeature } from 'src/app/feature.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SummaryPipe } from 'src/app/lib/pipes/summary.pipe';
import { User } from 'src/app/template/classes/template';
import { InputDirective } from 'src/app/lib/ui/input/input.directive';
import { Userv4Service } from '../../services/userv4.service';
import { IUser } from 'src/app/auth/classes/user';
import { Authv4Service } from 'src/app/auth/services/authv4.service';

@Component({
  selector: 'ganal-user-profile',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    CardComponent,
    TitleComponent,
    LoaderComponent,
    ButtonComponent,
    InputSwitchModule,
    InputTextModule,
    SummaryPipe,
    InputDirective
  ],
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  emailNotifications = false;
  currentUser!: IUser;
  isloading: boolean = true;
  features!: Array<IFeature>;
  hasBackgrounds = true;

  constructor(
    private userService: UserService,
    private authv4: Authv4Service,
    private userv4: Userv4Service,
    private featurev4: FeatureService
  ) { }

  ngOnInit(): void {

    this.hasBackgrounds = this.authv4.hasBackgrounds();

    this.userv4.self().subscribe((user: IUser) => {
      this.currentUser = user;
      this.emailNotifications = user.optin;
      this.isloading = false;
    })

    this.featurev4.getFeatures().subscribe((features: Array<IFeature>) => {
      this.features = features;
    })
  }

  toggleEmailNotifications(){
    // this.userService.toggleEmailNotifications(this.currentUser).subscribe();
  }

  saveUserProfile(){
    // this.isloading = true;
    // this.userService.saveUserProfile(this.currentUser).subscribe(response => {
    //   this.isloading = false;
    // });
  }

  toggleFeature(feature: IFeature) {
    this.featurev4.toggle(feature).subscribe({
      next: () => {},
      error: () => {
        
      }
    })
  }

  toggleBackgrounds() {
    this.authv4.toggleBackgrounds()
    location.reload();
  }
}
